import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import guest from './middleware/guest'
import auth from './middleware/auth'
import middlewarePipeline from "../router/middlewarePipeline";

import Login from "../views/Login";
import SignUp from "../views/SignUp";
import Authorization from "../views/Authorization";
import CheckEmail from "../views/Authorization/CheckEmail";
import CreateAccount from "../views/CreateAccount";

// import Welcome from "../views/Welcome";
import Profile from "../views/Profile";
// import Venues from "../views/Venues";
import VenuePreview from "../views/Venues/_id";
import VenueReviews from "../views/Venues/_id/Reviews";
import VenueEmployee from "../views/Venues/_id/_employee";
import VenueServices from "../views/Venues/_id/Services";
import VenueStaff from "../views/Venues/_id/Staff";
import VenueSlots from "../views/Venues/_id/Slots";
import VenueBasket from "../views/Venues/_id/Basket";
import VenuePaymentProcess from "../views/Venues/_id/PaymentProcess";
import VenueCreated from "../views/Venues/_id/Finish";
import VenueTimeout from "../views/Venues/_id/Timeout";

import Appointment from "../views/Appointment";
import YourAppointments from "../views/Profile/YourAppointments";
import ProfileSettings from "../views/Profile/ProfileSettings";
import Notifications from "../views/Notifications";
// import ProfileFavourites from "@/views/Profile/ProfileFavourites";
import DataPreferences from "@/views/Profile/DataPreferences";
import NoInternetConnection from "@/views/NoInternetConnection";
import NotFound from "@/views/404";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Welcome'),
        meta: {
            page: -1,
            middleware: [
                guest
            ]
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            page: 2,
            middleware: [
                guest
            ]
        },
    },
    {
        path: '/check-email',
        name: 'CheckEmail',
        component: CheckEmail,
        meta: {
            page: 4,
            middleware: [
                guest
            ]
        },
    },
    {
        path: '/authorization',
        name: 'Authorization',
        component: Authorization,
        meta: {
            page: 8,
            middleware: [
                guest
            ]
        },
    },
    {
        path: '/sign-up',
        name: 'SignUp',
        component: SignUp,
        meta: {
            page: 3,
            middleware: [
                guest
            ]
        },
    },
    {
        path: '/create-account',
        name: 'CreateAccount',
        component: CreateAccount,
        meta: {
            page: 4,
            middleware: [
                guest
            ]
        },
    },

    {
        path: '/venues',
        name: 'Venues',
        component: () => import('../views/Venues'),
        meta: {
            page: 0,
            prev: 'Home',
        },
    },
    {
        path: '/venues/:id',
        name: 'Venue',
        component: VenuePreview,
        meta: {
            page: 2,
            prev: 'Venues',
        }
    },
    {
        path: '/venues/:id/reviews',
        name: 'VenueReviews',
        component: VenueReviews,
        meta: {
            page: 3,
            prev: 'Venue',
        },
    },
    {
        path: '/venues/:id/view/:employee',
        name: 'VenueEmployee',
        component: VenueEmployee,
        meta: {
            page: 3,
            prev: 'Venue',
        },
    },
    {
        path: '/venues/:id/services',
        name: 'VenueServices',
        component: VenueServices,
        meta: {
            page: 4,
            prev: 'Venue',
        },
    },
    {
        path: '/venues/:id/staff',
        name: 'VenueStaff',
        component: VenueStaff,
        meta: {
            page: 5,
            prev: 'VenueServices',
        },
    },
    {
        path: '/venues/:id/slots',
        name: 'VenueSlots',
        component: VenueSlots,
        meta: {
            page: 6,
            prev: 'VenueStaff',
        },
    },
    {
        path: '/venues/:id/basket',
        name: 'VenueBasket',
        component: VenueBasket,
        meta: {
            page: 7,
            prev: 'VenueSlots',
        },
    },
    {
        path: '/venues/:id/payment-process',
        name: 'VenuePayment',
        component: VenuePaymentProcess,
        meta: {
            page: 8,
            prev: 'VenueBasket',
        },
    },
    {
        path: '/venues/:id/created',
        name: 'VenueCreated',
        component: VenueCreated,
        meta: {
            page: 9,
            prev: 'VenueBasket',
        },
    },
    {
        path: '/venues/:id/timeout',
        name: 'VenueTimeout',
        component: VenueTimeout,
        meta: {
            page: 10,
            prev: 'VenueServices',
        },
    },

    {
        path: '/appointment',
        name: 'Appointment',
        component: Appointment,
        meta: {
            page: 0,
            prev: 'Venues',
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            page: 0,
            prev: 'Venues',
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/profile/appointments',
        name: 'YourAppointments',
        component: YourAppointments,
        meta: {
            page: 1,
            prev: 'Profile',
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/profile/settings',
        name: 'ProfileSettings',
        component: ProfileSettings,
        meta: {
            page: 1,
            prev: 'Profile',
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/profile/favourites',
        name: 'ProfileFavourites',
        component: () => import('../views/Profile/ProfileFavourites'),
        meta: {
            page: 1,
            prev: 'Profile',
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/profile/data-preferences',
        name: 'DataPreferences',
        component: DataPreferences,
        meta: {
            page: 1,
            prev: 'Profile',
            middleware: [
                auth
            ]
        },
    },

    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications,
        meta: {
            page: 1,
            prev: 'Profile',
            middleware: [
                auth
            ]
        },
    },

    {
        path: '/no-connection',
        name: 'NoInternetConnection',
        component: NoInternetConnection,
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router
