import notifications from "@/api/notifications";

export default {
  state: {
    isLoading: false,
    notifications: [],
  },
  getters: {
    isLoading: (state) => {
      return state.isLoading
    },
    notifications: (state) => {
      return state.notifications
    },
    hasNotifications: (state) => {
      return !!state.notifications.length
    },
  },
  mutations: {
    toggleLoader(state, value) {
      state.isLoading = value
    },
    buildNotifications: (state, payload) => {
      state.notifications = payload || []
    },
  },
  actions: {
    async fetchNotifications({commit}) {
      commit('toggleLoader', true)
      try {
        const response = await notifications.notifications()
        commit('buildNotifications', response.data.data)
      } catch (e) {
        console.log(e.message)
      } finally {
        commit('toggleLoader', false)
      }
    },
    async readNotification({dispatch}, id) {
      try {
        await notifications.updateDetails(id)
        dispatch('fetchNotifications')
      } catch (e) {
        console.log(e.message)
      }
    },
  }
}
