<template>
  <div class="be-card-employee">
    <div
        v-ripple
        @click="$emit('select', {
          variant_id: variantId,
          employee_id: id,
          employee_name: name,
          employee_position: position
        })"
        class="be-card-employee__inner">
      <div class="be-card-employee__avatar">
        <img v-if="avatar" :src="avatar" :alt="name">
        <span v-else class="letter">{{name | firstLetter}}</span>
      </div>
      <div class="d-flex flex-fill justify-content-between">
        <div class="be-card-employee__info">
          <p class="name">{{name}}</p>
          <span class="position">{{position}}</span>
          <span v-if="rate" class="rate">
            <i class="icon-be-star-full"></i>
            5.0
          </span>
        </div>
        <span v-if="selected" class="be-card-employee__btn btn-selected">
          <i class="icon-be-check"></i>
        </span>
        <span v-else class="be-card-employee__btn btn-add"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeCardEmployee",
  props: {
    id: {
      type: [String, Number]
    },
    variantId: {
      type: [String, Number]
    },
    name: {
      type: String
    },
    position: {
      type: String
    },
    avatar: {
      type: String
    },
    selected: {
      type: Boolean
    },
    rate: {
      type: Number
    }
  },
  filters: {
    firstLetter(name) {
      let first = name.split(' ')[0] ? name.split(' ')[0] : ''
      let second = name.split(' ')[1] ? name.split(' ')[1] : ''
      return `${first[0] ? first[0] : ''}${second[0] ? second[0] : ''}`
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-card-employee {
  &__inner {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    padding: 12px 15px;
    border-bottom: 1px solid $border-color;

    &:hover {
      background: $white-100;
    }
  }

  &__avatar {
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    background: $text-color-light;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .letter {
      font-size: 14px;
      text-transform: uppercase;
      color: $white;
      font-weight: normal;
    }
  }
  &__info {
    padding-left: 12px;
    .name {
      font-size: 16px;
      line-height: 1.3;
      font-weight: 500;
      color: $text-color;
      margin: 0;
    }
    .position {
      font-size: 14px;
      line-height: 1.4;
      color: $text-color-light;
      display: block;
      font-weight: normal;
    }
    .rate {
      font-size: 16px;
      color: $text-color;
      font-weight: normal;
      display: inline-block;
      i {
        font-size: 18px;
        color: $gold;
        margin-right: 4px;
      }
    }
  }
  &__btn {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid $text-color;
    cursor: pointer;

    &.btn-add {
      &:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 2px;
        background: $text-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        content: '';
        position: absolute;
        width: 15px;
        height: 2px;
        background: $text-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        transition: .3s;
      }
    }

    &.btn-selected {
      background: $primary-color;
      border-color: $primary-color;
      color: $white;

      i {
        position: absolute;
        font-size: 22px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

</style>
