<template>
  <div
      class="be-form-group"
      :class="!!error && 'be-form-error'"
  >
    <label
        v-if="label"
        :for="name"
        class="be-form-label"
    >
      {{label}}
      <span v-if="required" class="required">*</span>
    </label>
    <input
        :disabled="disabled"
        :id="name"
        :name="name"
        :type="type"
        :value="value"
        :class="!!error && !!isKeyPress && 'be-form-control--error'"
        class="be-form-control"
        @input="onInput($event.target.value)"
        @blur="onBlur"
        @keypress="!!error && $emit('validate')"
    />
    <span
        v-if="passwordSwitcher"
        :class="type === 'text' ? 'show' : ''"
        class="be-form--password-switch"
        @click="onSwitch"
    ><i class="icon-be-eye"/></span>

    <p v-if="!!error" class="be-form-control--text-error">
      {{ error }}
    </p>
    <p v-if="!!info" class="be-form-control--text">
      {{ info }}
    </p>
    <p v-if="!!success" class="be-form-control--text-success">
      {{ success }} <i class="icon-be-check"/>
    </p>
  </div>
</template>

<script>
export default {
  name: "BeInput",
  props: {
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false
    },
    info: {
      type: String,
      required: false
    },
    success: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    passwordSwitcher: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isKeyPress: false
    };
  },
  methods: {
    onSwitch() {
      this.$emit('switch')
    },
    onInput(value) {
      this.$emit("input", value);
      if (this.isKeyPress) {
        this.isKeyPress = false;
      }
    },
    onBlur() {
      this.isKeyPress = true;
      this.$emit("validate");
    }
  }
};
</script>
