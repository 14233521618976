<template>
  <be-modal :id="confirmPhoneIdModal" :title="`Add Information`" @close="$bvModal.hide(confirmPhoneIdModal)">
    <template #buttons>
      <be-button
        is-full
        variant="primary"
        :is-loading="isLoading"
        @click="save"
        class="mb-3"
      >
        {{ $t('b2c_save') }}
      </be-button>
    </template>

    <div class="be-confirm-phone">
      <div class="be-confirm-phone__header">
        <h3>{{ $t('b2c_add_you_mobile_number') }}</h3>
      </div>
      <div class="be-confirm-phone__body">
        <div class="form">
          <be-input-phone
              :label="$t('b2c_phone')"
              :required="true"
              :value="form.phone"
              :error="errors.phone || errors.phoneIsNotValid"
              @input="handlerPhone"
              @validate="validate('phone')"
          />

          <div class="pb-4">
            <be-link @click="$bvModal.show(idPrivacyModal)" class="fz-14" variant="primary">{{ $t('b2c_privacy_policy') }}</be-link>
          </div>

          <div class="be-form-group">
            <be-checkbox
                :id="'agree-email'"
                :checked="form.isEmail"
                :text="$t('b2c_agree_to_be_contacted_by_email')"
                @input="handlerIsEmail"
            />
          </div>
          <div class="be-form-group">
            <be-checkbox
                :id="'agree-sms'"
                :checked="form.isSms"
                :text="$t('b2c_agree_to_be_contacted_by_sms')"
                @input="handlerIsSms"
            />
          </div>
          <div class="be-form-group pb-3">
            <be-checkbox
                :id="'agree-medical-related-data'"
                :checked="form.isMedicalData"
                :text="$t('b2c_agree_to_medical_related_data')"
                @input="handlerIsMedicalData"
            />
          </div>
        </div>
      </div>
    </div>
  </be-modal>
</template>

<script>
import BeModal from "@/components/BeModals/BeModal";
import BeButton from "@/components/common/BeButton";
import auth from "@/api/auth";
import BeInputPhone from "@/components/common/BeInputPhone";
import BeCheckbox from "@/components/common/BeCheckbox";
import {boolean, string, object} from "yup";
import BeLink from "@/components/common/BeLink";
import user from "@/api/user";
import EventBus from "@/utils/event-bus";
export default {
  name: "BeConfirmPhoneModal",
  components: {BeLink, BeCheckbox, BeInputPhone, BeButton, BeModal},
  props: {
    isShow: {
      type: Boolean
    }
  },
  watch: {
    isShow: {
      handler: function (isShow) {
        if(isShow) {
          this.$bvModal.show(this.confirmPhoneIdModal)
        }
      }
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        phone: '',
        phoneIsNotValid: true,
        isSms: false,
        isEmail: false,
        isMedicalData: false,
      },
      errors: {
        phone: '',
        phoneIsNotValid: '',
      }
    }
  },
  computed: {
    ConfirmationFormSchema() {
      const params = {
        phone: string().required(this.$t("b2c_this_field_is_required")),
        phoneIsNotValid: boolean().oneOf([false], this.$t("b2c_phone_is_not_valid"))
      };

      return object().shape(params);
    },
    idPrivacyModal() {
      return this.$store.getters.privacyPolicy.idModal
    },
    confirmPhoneIdModal() {
      return this.$store.getters.confirmPhoneIdModal
    },
  },
  mounted() {
    const self = this;
    EventBus.$on('repeatRequest', function (payload) {
      if(payload.isRepeatRequest) {
        if(payload.config.url.split('/')[1] === 'update-phone') {
          self.save();
        }
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    loader(value) {
      this.isLoading = value
    },
    validate(field) {
      this.ConfirmationFormSchema.validateAt(field, this.form)
          .then(() => {
            this.errors[field] = "";
          })
          .catch((err) => {
            this.errors[err.path] = err.message;
          });
    },
    handlerPhone({obj, num}) {
      if (obj.valid) {
        this.form.phone = obj.number
      }

      if (String(num).length > 4) {
        this.form.phoneIsNotValid = !obj.valid;
        this.validate("phoneIsNotValid");
      } else {
        this.form.phoneIsNotValid = obj.valid;
        this.validate("phoneIsNotValid");
      }
    },
    handlerIsEmail(value) {
      this.form.isEmail = value
    },
    handlerIsSms(value) {
      this.form.isSms = value
    },
    handlerIsMedicalData(value) {
      this.form.isMedicalData = value
    },

    async updateProfile() {
      const response = await user.profile()
      this.$store.commit('setUserProfile', response.data)
    },
    async confirm() {
      try {
        const data = {
          phone: this.form.phone,
          cust_market_emails: this.form.isEmail ? 1 : 0,
          cust_market_sms: this.form.isSms ? 1 : 0,
          cust_medical_consent: this.form.isMedicalData ? 1 : 0,
        };

        await auth.phoneUpdate(data);
        await this.updateProfile();
        await this.loader(false);
        await this.$bvModal.hide(this.confirmPhoneIdModal);

      } catch (e) {
        await this.loader(false);
        console.log(e)
      }
    },
    save() {
      this.ConfirmationFormSchema.validate(this.form, {abortEarly: false})
          .then(async () => {
            await this.loader(true);
            await this.confirm();
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.errors = {...this.errors, [error.path]: error.message};
            });
          });
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/global/variables";

.be-confirm-phone {
  &__header {
    h3 {
      font-size: 16px;
      margin-bottom: 18px;
      color: $gray-100;
    }
  }
}

</style>
