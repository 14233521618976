<template>
  <div class="be-services-filters">
    <ul class="be-services-filters__nav">
      <li class="item">
        <span
            @click="$emit('open-service')"
            :class="status.services ? 'active' : ''"
            class="be-services-filters__nav-btn">
          <i class="icon-be-filter"/>
          {{ $t('b2c_filter_by_service') }}
        </span>
      </li>
      <li class="item">
        <span
            @click="$emit('open-staff')"
            :class="status.employees ? 'active' : ''"
            class="be-services-filters__nav-btn">
          <i class="icon-be-filter"/>
          {{ $t('b2c_filter_by_staff') }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BeServicesFilters",
  computed: {
    status() {
      return this.$store.getters.filterStatus
    }
  }

}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-services-filters {
  &__nav {
    padding: 0;
    width: 100%;
    margin: 0;
    display: flex;
    list-style: none;
    border: none;

    .item {
      flex: 1;
      border-right: 1px solid $border-color;

      &:last-child {
        border: none;
      }
    }
    &-btn {
      cursor: pointer;
      padding: 6px 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;
      min-height: 60px;
      font-size: 16px;
      color: $text-color-light;
      border: none;
      font-weight: normal;
      margin: 0;
      line-height: 1.5;
      transition: .2s;

      &:hover {
        color: $primary-color;
      }

      i {
        font-size: 26px;
        margin-right: 5px;
      }

      &.active {
        i {
          color: $primary-color;
        }
      }
    }
  }
}

</style>
