<template>
  <div class="be-venue-payment-card">
    <div class="be-venue-payment-card__price">
      <be-basket-summary-string/>
    </div>
    <div class="be-venue-payment-card__sub">{{$t('b2c_amount_due')}}</div>
  </div>
</template>

<script>

import BeBasketSummaryString from "@/components/common/BeBasketSummaryString.vue";

export default {
  name: 'BePaymentSummary',
  components: {BeBasketSummaryString}
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-payment-card {
  background: $white-100;
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid $white-300;
  border-bottom: 1px solid $white-300;
  margin-left: -16px;
  margin-right: -16px;

  &__price {
    font-size: 32px;
    line-height: 1;
    font-weight: bold;
    color: $text-color;
    margin-bottom: 5px;
  }
  &__sub {
    font-size: 16px;
    font-weight: normal;
    color: $text-color;
  }
}
</style>
