<template>
  <div
      ref="mainScroll"
      class="be-app__inner"
      @scroll="handleScroll"
      v-touch:start="touchStartHandler"
      v-touch:moving="touchMovingHandler"
      v-touch:end="touchEndHandler"
  >
    <div
        v-if="isPullRefresh"
        :class="{'transition-top': pullStatus >= 2}"
        class="be-upload-spinner"
        :style="{top: distance + 'px'}"
        >
      <span
          class="be-spinner"
          :class="{'be-spinner--animation': pullStatus >= 2}"
          :style="{transform: `rotate(${distance}deg)`}"
      />
    </div>

    <slot name="header" />

    <div class="be-app__content">
      <div :class="{'pb-0': !hideFooter}" class="be-layout">
        <b-container>
          <div class="be-layout__inner">
            <div :class="{'be-layout-center': isCenterContent}" class="be-layout__content">
              <slot></slot>
            </div>
            <div
                v-if="!hideFooter"
                :class="[
                    {'be-layout__footer-sticky': stickyFooter},
                    {'be-layout__footer-transparent': transparentFooter}
                    ]"
                class="be-layout__footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MainWrapper",
  props: {
    offsetTop: {
      type: Number
    },
    isCenterContent: {
      type: Boolean,
      default: false
    },
    isPullRefresh: {
      type: Boolean,
      default: false
    },
    resetRefresh: {
      type: Boolean
    },
    stickyFooter: {
      type: Boolean,
      default: false
    },
    transparentFooter: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    resetRefresh: {
      handler(val) {
        if(val) {
          this.distance = 0
          this.pullStatus = 3
          this.isBlockTouch = false
        }
      }
    },
    offsetTop(num, oldNum) {
      if(num !== oldNum && num !== this.scrollTop + oldNum) {
        this.handlerScrollTop(num - 125)
      }
    }
  },
  data() {
    return {
      scrollTop: 0,
      isBlockTouch: false,
      correctPullDistance: 160,
      maxPullDistance: 230,
      startPosition: 0,
      endPosition: 0,
      distance: 0,
      pullStatus: 0,
    }
  },
  mounted() {
    this.getNextUser();
    this.handlerSendRef();
    this.handleScroll({target: {scrollTop: 0}});
  },
  methods: {
    touchStartHandler(direction) {
      if(this.isPullRefresh) {
        if(direction) {
          this.startPosition = direction.touches && direction.touches.length > 0 ? direction.touches[0].pageY : 0
        }
      }
    },
    touchMovingHandler(direction) {
      if(this.isPullRefresh) {
        if(!this.isBlockTouch) {
          const scrollTop = this.$refs.mainScroll.scrollTop
          const pageY = direction.touches && direction.touches.length > 0 ? direction.touches[0].pageY : 0

          if(scrollTop < 1) {
            let distance = pageY- this.startPosition

            if(distance > this.maxPullDistance) {
              if(distance !== this.maxPullDistance) {
                this.pullStatus = 1
                this.distance = this.maxPullDistance
              }
            } else{
              this.pullStatus = 0
              this.distance = distance
            }
          }
        }
      }
    },
    touchEndHandler() {
      if(this.isPullRefresh) {
        const scrollTop = this.$refs.mainScroll.scrollTop

        if(scrollTop <= 1 && this.distance >= this.correctPullDistance) {


          if(!this.isBlockTouch) {
            this.pullStatus = 2
            this.distance = this.correctPullDistance
            this.isBlockTouch = true

            console.log('Reload')

            this.$emit('pull-reload')

            setTimeout(() => {
              this.distance = 0
              this.pullStatus = 3
              this.isBlockTouch = false
            }, 3000)
          }

        } else {
          this.distance = 0
          this.pullStatus = 3
        }
      }
    },

    handleScroll(event) {
      this.$store.commit('setScrollTop', event.target.scrollTop)
      this.scrollTop = event.target.scrollTop
    },

    getNextUser() {
      this.$refs.mainScroll.onscroll = () => {
        let scrollTop = parseInt(this.$refs.mainScroll.scrollTop)
        let clientHeight = this.$refs.mainScroll.clientHeight
        let scrollHeight = this.$refs.mainScroll.scrollHeight

        let bottomOfWindow =
            scrollTop + clientHeight === scrollHeight ||
            scrollTop + clientHeight === scrollHeight + 1 ||
            scrollTop + clientHeight === scrollHeight + 2 ||
            scrollTop + clientHeight === scrollHeight - 1 ||
            scrollTop + clientHeight === scrollHeight - 2;

        this.$emit('scroll-load', bottomOfWindow)
      }
    },
    handlerScrollTop(num) {
      this.$refs.mainScroll.scrollTop = num
    },

    handlerSendRef() {
      this.$emit('scroll-ref', this.$refs.mainScroll)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 16px 0 20px;

  .container, &__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__content {
    flex: 1;
    height: calc(100% - constant(safe-area-inset-bottom));
    height: calc(100% - env(safe-area-inset-bottom));

    &.be-layout-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 50px;
    }
  }
  &__footer {
    //box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.12);
    margin-left: -15px;
    margin-right: -15px;
    padding: 12px 15px;
    background: $white;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 12px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 12px);

    &.be-layout__footer-sticky {
      position: sticky;
      bottom: 0;
      z-index: 55;
    }

    &.be-layout__footer-transparent {
      box-shadow: none;
      background: none;
    }

    .be-btn {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
    }
  }
}

.be-upload-spinner {
  position: fixed;
  top: 0;
  left: 50%;
  margin-top: -50px;
  margin-left: -20px;
  z-index: 666;
  border-radius: 50%;
  background: #fff;
  transition: margin .3s;
  box-shadow: 2px 2px 10px rgba(#000, .3);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;


  &.transition-top  {
    transition: top .2s;
  }

  .be-spinner {
    display: inline-block;
    width: 26px;
    height: 26px;
    border: 4px solid;
    border-right: 4px solid transparent;
    border-radius: 50%;

    &.be-spinner--animation {
      transform: rotate(0);
      animation: spinner-border-be .55s linear infinite;

      @keyframes spinner-border-be {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}
</style>
