<template>
  <div class="be-prompt">
    <div v-if="icon" class="be-prompt__icon">
      <img :style="{width: `${iconSize}px`}" :src="icon" alt="icon-prompt"/>
    </div>
    <div class="be-prompt__content">
      <h4 v-if="title" v-html="title" class="title"/>
      <p v-if="subTitle" v-html="subTitle" class="sub-title"/>
      <p v-if="description" v-html="description" class="description"/>
      <p v-if="descriptionLight" v-html="descriptionLight" class="description-light"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "BePrompt",
  props: {
    icon: {
      type: String
    },
    iconSize: {
      type: String,
      default: ''
    },
    title: {
      type: String
    },
    subTitle: {
      type: String
    },
    description: {
      type: String
    },
    descriptionLight: {
      type: String
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__icon {
    margin-bottom: 24px;
    img {
      display: inline-block;
      width: 72px;
      height: auto;
      object-fit: contain;
    }
  }
  &__content {
    padding-bottom: 10px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: $text-color;
      line-height: 1.5;
      margin-bottom: 14px;
    }
    .sub-title {
      font-size: 16px;
      font-weight: 500;
      color: $text-color;
      margin-bottom: 10px;
    }
    .description {
      font-size: 14px;
      color: $text-color;
      font-weight: normal;
      margin-bottom: 10px;
    }
    .description-light {
      font-size: 14px;
      color: $text-color-light;
      font-weight: normal;
      margin-bottom: 10px;
    }
  }
}

</style>
