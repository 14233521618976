<template>
  <div class="be-card-review">
    <div class="be-card-review__header">
      <div class="be-card-review__header-info">
        <span class="name">{{name}}</span>
        <span class="date">{{ $t('b2c_on') }} {{dateTitle(date)}}</span>
      </div>
      <div class="be-card-review__header-rating">
        <span class="rate">
          <i class="icon-be-star-full"/>
          {{rating}}
        </span>
      </div>
    </div>
    <div class="be-card-review__body" v-html="content"/>
    <div class="be-card-review__footer">
      <div class="be-card-review__footer-provide">{{ $t('b2c_provided_by') }}: {{provided}}</div>
      <div class="be-card-review__footer-provide">{{ $t('b2c_treatment') }}: {{treatment}} </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeCardReview",
  props: {
    name: {
      type: String
    },
    date: {
      type: String
    },
    rating: {
      type: String
    },
    content: {
      type: String
    },
    provided: {
      type: String
    },
    treatment: {
      type: String
    },
  },
  methods: {
    dateTitle(date) {
      const monthArray = [
        this.$t("b2c_month_short1"),
        this.$t("b2c_month_short2"),
        this.$t("b2c_month_short3"),
        this.$t("b2c_month_short4"),
        this.$t("b2c_month_short5"),
        this.$t("b2c_month_short6"),
        this.$t("b2c_month_short7"),
        this.$t("b2c_month_short8"),
        this.$t("b2c_month_short9"),
        this.$t("b2c_month_short10"),
        this.$t("b2c_month_short11"),
        this.$t("b2c_month_short12")
      ];
      const currMonth = new Date(date).getMonth();
      return `${ new Date(date).getDate() } ${ monthArray[currMonth] } ${ new Date(date).getFullYear() }`;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-card-review {
  padding-bottom: 15px;
  overflow: hidden;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;

    &-info {
      .name {
        font-size: 16px;
        font-weight: 600;
        color: $text-color;
        display: inline-block;
        margin-right: 5px;
      }
      .date {
        font-size: 14px;
        font-weight: normal;
        color: $text-color-light;
        display: inline-block;
      }
    }
    &-rating {
      .rate {
        font-size: 16px;
        font-weight: 600;
        color: $text-color;
        display: flex;
        align-items: center;

        i {
          position: relative;
          top: -2px;
          font-size: 24px;
          color: $gold;
          margin-right: 4px;
        }
      }
    }
  }
  &__body {
    font-size: 16px;
    font-weight: normal;
    color: $text-color;
    p {
      font-size: 16px;
      font-weight: normal;
      color: $text-color;
      margin-bottom: 8px;
    }
  }
  &__footer {
    padding-top: 8px;
    &-provide {
      font-size: 14px;
      color: $text-color-light;
      font-weight: normal;
      padding-bottom: 2px;
    }
  }
}

</style>
