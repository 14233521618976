<template>
  <div class="be-venue-team">
    <h4 class="be-h4 fw-bold">{{ $t('b2c_team') }}</h4>

    <div class="be-venue-team__scroll">
      <div class="be-venue-team__list">

        <be-card-list-team
          v-for="item in team"
          :key="item.id"
          :id="item.id"
          :first-name="item.first_name"
          :last-name="item.last_name"
          :image="item.img"
        />

      </div>
    </div>
  </div>
</template>

<script>
import BeCardListTeam from "@/components/cards/BeCardListTeam";
export default {
  name: "BeVenueTeam",
  components: {BeCardListTeam},
  props: {
    team: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">

.be-venue-team {
  padding-bottom: 24px;
  &__scroll {
    overflow-x: auto;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__list {
    display: flex;
    padding-bottom: 10px;
  }
}

</style>
