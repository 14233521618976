var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"be-basket-info"},[_c('div',{staticClass:"be-basket-info__header"},[_c('div',{staticClass:"be-basket-info__header-date"},[_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.basket[0].time)+" | "+_vm._s(_vm.basket[0].date))]),(_vm.isTimer && _vm.timer.status)?_c('span',{staticClass:"timer",class:_vm.timer.countdown < 20 ? 'timer-ending' : ''},[_vm._v(_vm._s(_vm.formattedCountdown || '5 min over'))]):_vm._e()]),_c('div',{staticClass:"be-basket-info__header-service"},[_c('h5',{staticClass:"title"},[_c('strong',[_vm._v(_vm._s(_vm.salon))]),_vm._v(", "+_vm._s(_vm.address))])])]),(_vm.isBasket)?_c('div',{staticClass:"be-basket-info__body"},[_vm._l((_vm.basketPackages),function(packageItem){return _c('be-wrapper-basket-package',{key:packageItem.id,attrs:{"title":packageItem.title,"price":_vm._f("priceFormat")(packageItem.total_price,_vm.priceData),"duration":_vm._f("time")(packageItem.total_duration)},on:{"remove":function($event){return _vm.removeSlot({
          id: _vm.venueId,
          package_id: packageItem.id,
          basket_id: packageItem.basket_id,
          token: packageItem.token,
          variant_id: packageItem.variant_id,
          services: packageItem.services,
        })}}},_vm._l((packageItem.services),function(item){return _c('be-card-list-basket',{key:item.variant_id,attrs:{"title":item.service_title,"employee":item.employee_name,"price":_vm._f("priceFormat")(item.custom_price,_vm.priceData),"old-price":item.price_formatted,"time":item.time ? item.time : '',"duration":_vm._f("time")(item.custom_duration),"hide-remove-button":""}})}),1)}),_vm._l((_vm.basket.filter(i => !i.package_id)),function(item){return _c('be-card-list-basket',{key:item.variant_id,attrs:{"title":item.service_title,"employee":item.employee_name,"price":item.price_formatted,"time":item.time ? item.time : '',"duration":_vm._f("time")(item.duration)},on:{"remove":function($event){return _vm.removeSlot({
          id: _vm.venueId,
          basket_id: item.basket_id,
          token: item.token,
          variant_id: item.variant_id,
        })}}})})],2):_vm._e(),(_vm.isBasket)?_c('div',{staticClass:"be-basket-info__total"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('b2c_total_to_pay'))+" ")]),_c('span',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm._f("totalBasket")(_vm.totalBasketPrice,_vm.basket, _vm.priceData))+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }