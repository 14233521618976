<template>
  <ul class="be-star-rating">
    <li :class="rating > 0 ? 'active' : ''">
      <i class="icon-be-star-full"/>
    </li>
    <li :class="rating > 1 ? 'active' : ''">
      <i class="icon-be-star-full"/>
    </li>
    <li :class="rating > 2 ? 'active' : ''">
      <i class="icon-be-star-full"/>
    </li>
    <li :class="rating > 3 ? 'active' : ''">
      <i class="icon-be-star-full"/>
    </li>
    <li :class="rating > 4 ? 'active' : ''">
      <i class="icon-be-star-full"/>
    </li>
  </ul>
</template>

<script>
export default {
  name: "BeStarRating",
  props: {
    rating: {
      type: Number
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-star-rating {
  display: flex;
  padding: 0;
  list-style: none;
  margin-left: -2px;
  margin-right: -1px;
  margin-bottom: 0;
  li {
    display: inline-block;
    font-size: 21px;
    line-height: 1;
    padding: 0;
    color: $border-color;

    i {
      position: relative;
      top: -2px;
      vertical-align: middle;
    }

    &.active {
      color: $gold;
    }
  }
}

</style>
