<template>
  <div class="be-card-list-basket">
    <div class="be-card-list-basket__header">
      <h4 class="title">{{title}}</h4>
      <span class="price">{{price}} <span v-if="oldPrice" class="old-price">{{oldPrice}}</span></span>
    </div>
    <div class="be-card-list-basket__body">
      <span v-if="!employee && !time" class="details">{{duration}}</span>
      <span v-else class="details">{{employee}} <i class="icon-be-clock"></i> {{ time }} ({{duration}})</span>
      <span
          v-if="!hideRemoveButton"
          @click="$emit('remove')"
          class="be-card-list-basket__btn-remove">
        <i class="icon-be-close"/>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeCardListBasket",
  props: {
    title: {
      type: String
    },
    price: {
      type: String
    },
    oldPrice: {
      type: String
    },
    employee: {
      type: String
    },
    time: {
      type: String
    },
    duration: {
      type: String
    },
    hideRemoveButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-card-list-basket {
  padding: 13px 0;
  border-bottom: 1px solid $border-color;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3px;

    .title {
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
      margin: 0;
    }
    .price {
      font-size: 16px;
      color: $text-color;
      font-weight: 500;
      display: inline-block;
      white-space: nowrap;
      margin-left: 12px;
      position: relative;

      .old-price {
        position: absolute;
        bottom: -15px;
        right: 0;
        font-size: 12px;
        font-weight: 400;
        color: $text-color-light;
        text-decoration: line-through;
        white-space: nowrap;
      }
    }
  }
  &__body {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .details {
      font-size: 13px;
      color: $text-color-light;
      font-weight: normal;
      display: inline-block;

      i {
        position: relative;
        top: 1px;
        margin-left: 5px;
        margin-right: 3px;
      }
    }
  }
  &__btn-remove {
    font-size: 24px;
    color: $text-color;
    font-weight: normal;
    line-height: 1;
    display: inline-block;
    cursor: pointer;

    i {
      transition: .3s;
      display: inline-block;
    }

    &:hover {
      color: $red;

      i {
        transform: rotate(180deg);
      }
    }
  }
}

</style>
