<template>
  <div class="be-basket-info">
    <div class="be-basket-info__header">
      <div class="be-basket-info__header-date">
        <span class="date">{{basket[0].time}} | {{basket[0].date}}</span>
        <span
            v-if="isTimer && timer.status"
            :class="timer.countdown < 20 ? 'timer-ending' : ''"
            class="timer"
        >{{ formattedCountdown || '5 min over' }}</span>
      </div>
      <div class="be-basket-info__header-service">
        <h5 class="title"><strong>{{salon}}</strong>, {{address}}</h5>
      </div>
    </div>
    <div v-if="isBasket" class="be-basket-info__body">
      <be-wrapper-basket-package
        v-for="packageItem in basketPackages"
        :key="packageItem.id"
        :title="packageItem.title"
        :price="packageItem.total_price | priceFormat(priceData)"
        :duration="packageItem.total_duration | time"
        @remove="removeSlot({
            id: venueId,
            package_id: packageItem.id,
            basket_id: packageItem.basket_id,
            token: packageItem.token,
            variant_id: packageItem.variant_id,
            services: packageItem.services,
          })"
      >
        <be-card-list-basket
            v-for="item in packageItem.services"
            :key="item.variant_id"
            :title="item.service_title"
            :employee="item.employee_name"
            :price="item.custom_price | priceFormat(priceData)"
            :old-price="item.price_formatted"
            :time="item.time ? item.time : ''"
            :duration="item.custom_duration | time"
            hide-remove-button
        />
      </be-wrapper-basket-package>
      <be-card-list-basket
          v-for="item in basket.filter(i => !i.package_id)"
          :key="item.variant_id"
          :title="item.service_title"
          :employee="item.employee_name"
          :price="item.price_formatted"
          :time="item.time ? item.time : ''"
          :duration="item.duration | time"
          @remove="removeSlot({
            id: venueId,
            basket_id: item.basket_id,
            token: item.token,
            variant_id: item.variant_id,
          })"
      />
    </div>
    <div v-if="isBasket" class="be-basket-info__total">
      <span class="label">
        {{ $t('b2c_total_to_pay') }}
      </span>
      <span class="total">
        {{totalBasketPrice | totalBasket(basket, priceData)}}
      </span>
    </div>
  </div>
</template>

<script>

import BeCardListBasket from "@/components/cards/BeCardListBasket";
import {priceFormatter, timeFormatter} from "@/utils/common";
import BeWrapperBasketPackage from "@/components/cards/BeWrapperBasketPackage";
export default {
  name: "BeBasketInfo",
  components: {BeWrapperBasketPackage, BeCardListBasket},
  props: {
    isTimer: {
      type: Boolean
    },
    isBasket: {
      type: Boolean
    }
  },
  computed: {
    venueId() {
      return this.$store.getters.venueId
    },
    timer() {
      return this.$store.getters.timer
    },
    formattedCountdown() {
      return this.$moment.utc(this.$moment.duration(this.$store.getters.timer.countdown, "seconds").asMilliseconds()).format("m:ss")
    },
    salon() {
      return this.$store.getters.venue ? this.$store.getters.venue.venueInformation.title : ''
    },
    address() {
      return this.$store.getters.venue ? this.$store.getters.venue.venueInformation.address : ''
    },
    basket() {
      return this.$store.getters.savedBasket
    },
    basketPackages() {
      return this.$store.getters.basketPackages
    },
    totalBasketPrice() {
      let total = 0
      this.$store.getters.savedBasket.forEach(i => {
        if (i.type !== 'hide_price') {
          if(i.package_id) {
            total = total + i.custom_price
          } else {
            total = total + i.price
          }
        }
      })
      return total
    },
    priceData() {
      return this.$store.getters.priceData
    },
  },
  filters: {
    priceFormat(amount, formatData) {
      return priceFormatter(amount, formatData)
    },
    totalBasket(price, array, format) {
      const hidePrice = array.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      const bookable = array.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (hidePrice.length > 0 && bookable.length > 0) {
        return `from ${priceFormatter(price, format)}`;
      } else if (hidePrice.length === 0) {
        return priceFormatter(price, format);
      } else {
        return hidePrice[0].price_formatted;
      }
    },
    time(time) {
      return timeFormatter(time)
    }
  },
  watch: {
    basket: {
      handler: function (newBasket) {
        if(newBasket.length === 0) {
          this.$router.push(`/venues/${this.venueId}/services`)
          this.$store.commit('startTimer', false)
        }
      }
    }
  },
  methods: {
    async removeSlot(payload) {
      if(this.basket.length === 1) {
        await this.$router.push(`/venues/${this.venueId}/services`)
        this.$store.commit('startTimer', false)
      }
      await this.$store.dispatch('removeSlot', payload)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-basket-info {
  padding: 12px;
  border: 1px solid $text-color;
  margin-bottom: 26px;
  &__header {
    &-date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 6px;

      .date {
        font-size: 16px;
        font-weight: normal;
        display: inline-block;
        color: $text-color;
      }
      .timer {
        font-size: 16px;
        font-weight: bold;
        display: inline-block;
        color: $text-color;

        &-ending {
          animation: flash 1s infinite ease-in-out;

          @keyframes flash {
            from {
              color: rgba($red, 1);
            }
            to {
              color: rgba($red, .4);
            }
          }
        }
      }
    }
    &-service {
      .title {
        font-size: 14px;
        font-weight: normal;
        color: $text-color;
        margin: 0;
        strong {
          font-weight: 500;
        }
      }
    }
  }
  &__body {
    .be-card-list-basket {
      border-bottom: 1px dashed $border-color;
      .be-card-list-basket__btn-remove {
        font-size: 18px;
      }
    }
  }
  &__total {
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      font-size: 16px;
      font-weight: bold;
      color: $text-color;
    }
    .total {
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
    }
  }
}

</style>
