import {authToken} from "@/utils/common";

export default function guest ({ next, store }){

    if(store.getters.isAuth) {
        return next({
            name: 'Venues'
        })
    } else if(authToken()) {
        return next({
            name: 'Venues'
        })
    }
    return next()
}
