<template>
  <div
      class="be-form-group"
      :class="!!error && 'be-form-error'"
  >
    <label
        v-if="label"
        :for="id"
        class="be-form-label"
    >
      {{label}}
      <span v-if="required" class="required">*</span>
    </label>
    <select
        :disabled="disabled"
        :selected="selected"
        :name="id"
        :id="id"
        :class="!!error && !!isKeyPress && 'be-form-control--error'"
        @change="(e) => $emit('change', e.target.value)"
        class="be-form-control"
    >
      <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
      >
        {{option.title}}
      </option>
    </select>
    <p v-if="!!error" class="be-form-control--text-error">
      {{ error }}
    </p>
    <p v-if="!!info" class="be-form-control--text">
      {{ info }}
    </p>
    <p v-if="!!success" class="be-form-control--text-success">
      {{ success }} <i class="icon-be-check"/>
    </p>
  </div>
</template>

<script>
export default {
  name: "BeSelect",
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    selected: {
      type: [String, Number],
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false
    },
    info: {
      type: String,
      required: false
    },
    success: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      // type: Array,
      default: []
    }
  },
}
</script>

<style scoped>

</style>
