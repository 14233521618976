<template>
  <main-wrapper is-center-content hide-footer>
    <div class="be-venue-created">
      <div class="be-venue-created__header">
        <div class="be-venue-created__header-icon">
          <img :src="IconSuccess" alt="Icon Success">
        </div>
        <h2 class="be-venue-created__header-title">{{ $t('b2c_booking_created') }}</h2>
      </div>
      <div class="be-venue-created__body">
        <be-basket-info/>
        <div class="be-venue-created__body-list">
          <be-wrapper-basket-package
              v-for="packageItem in basketPackages"
              :key="packageItem.id"
              :title="packageItem.title"
              :price="packageItem.total_price | priceFormat(priceData)"
              :duration="packageItem.total_duration | time"
              hide-remove-button
          >
            <be-card-list-basket
                v-for="item in packageItem.services"
                :key="item.variant_id"
                :title="item.service_title"
                :employee="item.employee_name"
                :price="item.custom_price | priceFormat(priceData)"
                :old-price="item.price_formatted"
                :time="item.time ? item.time : ''"
                :duration="item.custom_duration | time"
                hide-remove-button
            />
          </be-wrapper-basket-package>
          <be-card-list-basket
              v-for="item in basket.filter(i => !i.package_id)"
              :key="item.variant_id"
              :title="item.service_title"
              :employee="item.employee_name"
              :price="item.price_formatted"
              :time="item.time ? item.time : ''"
              :duration="item.duration | time"
              hide-remove-button
          />
        </div>
        <div class="be-venue-created__body-total">
          <span v-if="priceData" class="total">
          {{ $t('b2c_total') }}: <strong>{{totalBasketPrice | totalBasket(basket, priceData)}}</strong>
        </span>
        </div>
      </div>
      <div class="be-venue-created__footer">
        <div class="be-venue-created__footer-info">
          <p>
            <i class="icon-be-check"/>
            {{ $t('b2c_you_can_cancel_via_your_account') }}
          </p>
          <p>
            <i class="icon-be-check"/>
            {{ $t('b2c_we_will_send_you_reminder_prior') }}
          </p>
        </div>
        <div class="be-venue-created__footer-btn">
          <be-button @click="viewAppointment" is-full variant="primary">{{ $t('b2c_view_appointments') }}</be-button>
        </div>
      </div>
    </div>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper"
import IconSuccess from "../../../../assets/img/icons/icon-success.svg"
import BeCardListBasket from "@/components/cards/BeCardListBasket";
import {priceFormatter, timeFormatter} from "@/utils/common";
import BeButton from "@/components/common/BeButton";
import BeBasketInfo from "@/components/common/BeBasketInfo";
import BeWrapperBasketPackage from "@/components/cards/BeWrapperBasketPackage";

export default {
  name: "VenueCreated",
  components: {BeWrapperBasketPackage, BeBasketInfo, BeButton, BeCardListBasket, MainWrapper},
  data() {
    return {
      IconSuccess
    }
  },
  filters: {
    priceFormat(amount, formatData) {
      return priceFormatter(amount, formatData)
    },
    totalBasket(price, array, format) {
      const hidePrice = array.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      const bookable = array.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (hidePrice.length > 0 && bookable.length > 0) {
        return `from ${priceFormatter(price, format)}`;
      } else if (hidePrice.length === 0) {
        return priceFormatter(price, format);
      } else {
        return hidePrice[0].price_formatted;
      }
    },
    time(time) {
      return timeFormatter(time)
    }
  },
  computed: {
    basket() {
      return this.$store.getters.basket
    },
    basketPackages() {
      return this.$store.getters.basketPackages
    },
    totalBasketPrice() {
      let total = 0
      this.$store.getters.basket.forEach(i => {
        if (i.type !== 'hide_price') {
          if(i.package_id) {
            total = total + i.custom_price
          } else {
            total = total + i.price
          }
        }
      })
      return total
    },
    priceData() {
      return this.$store.getters.priceData
    }
  },
  methods: {
    viewAppointment() {
      this.$store.commit('cleanBasket', true);
      this.$store.commit('setPaymentMethod', null);
      this.$router.push('/profile/appointments')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-created {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  &__header {
    text-align: center;
    padding-bottom: 24px;
    &-icon {
      margin-bottom: 24px;
      img {
        display: inline-block;
        width: 83px;
        height: 83px;
        object-fit: contain;
      }
    }
    &-title {
      font-size: 24px;
      font-weight: 600;
      color: $text-color-dark;
      margin: 0;
    }
  }
  &__body {
    padding-bottom: 35px;
    &-list {
      padding-bottom: 18px;
    }
    &-total {
      display: flex;
      justify-content: flex-end;
      .total {
        font-size: 16px;
        font-weight: normal;
        color: $text-color-light;

        strong {
          font-weight: 600;
          color: $text-color;
        }
      }
    }
  }
  &__footer {
    &-info {
      padding-bottom: 12px;
      p {
        font-size: 15px;
        font-weight: normal;
        color: $text-color;
        margin-bottom: 6px;
        position: relative;
        padding-left: 26px;

        i {
          position: absolute;
          top: 1px;
          left: 0;
          font-size: 18px;
        }
      }
    }
    &-btn {}
  }
}

</style>
