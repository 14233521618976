<template>
  <main-wrapper>
    <template #header>
      <be-header
          :title="$t('b2c_select_service')"
          :back-to-route="`Venue`"
      />
    </template>

    <div class="be-services">
      <div class="be-services__header">
        <be-services-filters
            @open-service="handlerShowServiceFilter"
            @open-staff="handlerShowStaffFilter"
        />
      </div>
      <div class="be-services__content">
        <be-service-accordion
            v-for="item in services"
            :key="item.idCollapse"
            :id="item.idCollapse"
            :title="item.group_title"
        >
          <template v-if="!item.is_package">
            <be-card-list-service
                v-for="service in item.services"
                :key="service.id"
                :variant="service.pricing_type"
                :variants="service.variants"
                :id="service.id"
                :title="service.title"
                :employees="service.employees"
                :type="service.booking"
                :group-title="service.group_title"
                :is-description="!!service.description"
                @show-details="handlerShowDetails"
            />
          </template>
          <template v-else>
            <be-card-list-package
                v-for="packageItem in item.packages"
                :key="packageItem.id"
                :id="packageItem.id"
                :title="packageItem.title"
                :total-price="packageItem.total | priceFormat(priceData)"
                :total-duration="packageItem.total_duration"
                :services="packageItem.services"
                :is-selected="packageItem.selected"
                @show-details="handlerPackageShowDetails"
            />
          </template>
        </be-service-accordion>
      </div>
    </div>

    <be-modal
        :id="filterModalId"
        :title="filterModalTitle"
    >
      <be-services-filters-control @reset="resetFilter" @apply="applyFilter"/>
      <be-services-filters-list :type="typeFilter"/>
    </be-modal>
    <be-modal
        :id="serviceShowDetailsModalId"
        :title="serviceDetails && serviceDetails.isPackage ? $t('b2c_package_details') : $t('b2c_service_details')"
        :class-body="`pb-0`"
    >
      <be-content-service-details :data="serviceDetails" @hide-details="handlerHideDetails"/>
    </be-modal>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import BeServicesFilters from "@/views/Venues/_id/Services/BeServicesFilters";
import BeServiceAccordion from "@/components/common/BeServiceAccordion";
import BeCardListService from "@/components/cards/BeCardListService";
import BeModal from "@/components/BeModals/BeModal";
import BeContentServiceDetails from "@/views/Venues/_id/BeContentServiceDetails";
import BeServicesFiltersList from "@/views/Venues/_id/Services/BeServicesFiltersList";
import BeServicesFiltersControl from "@/views/Venues/_id/Services/BeServicesFiltersControl";
import BeCardListPackage from "@/components/cards/BeCardListPackage";
import {priceFormatter, timeFormatter} from "@/utils/common";

export default {
  name: "VenueServices",
  components: {
    BeCardListPackage,
    BeServicesFiltersControl,
    BeServicesFiltersList,
    BeContentServiceDetails, BeModal, BeCardListService, BeServiceAccordion, BeServicesFilters, MainWrapper, BeHeader},
  data() {
    return {
      typeFilter: '',
      serviceShowDetailsModalId: 'service-details-modal',
      serviceDetails: null,
      filterModalId: 'filter-modal',
      filterModalTitle: ''
    }
  },
  filters: {
    priceFormat(amount, formatData) {
      return priceFormatter(amount, formatData)
    },
    time(time) {
      return timeFormatter(time)
    }
  },
  computed: {
    services() {
      return this.$store.getters.venueServices
    },
    priceData() {
      return this.$store.getters.priceData
    },
  },
  mounted() {
    if (this.services.length === 0) {
      this.$router.push(`/venues/${this.$route.params.id}`)
    }
  },
  methods: {
    handlerShowDetails(id) {
      const service = this.services
          .filter(item => !item.is_package && item.services
              .find(i => i.id === id))[0].services
          .find(i => i.id === id)
      this.serviceDetails = {
        isPackage: false,
        id: service.id,
        title: service.title,
        description: service.description,
        variants: service.variants,
        image: service.service && service.service.image && service.service.image.length
            ? service.service.image[0] : '',
        info: `${service.variants[0].duration} ${this.$t('b2c_minute_short')} - ${service.variants[service.variants.length - 1].duration} ${this.$t('b2c_minute_short')}`
      }
      this.$bvModal.show(this.serviceShowDetailsModalId)
    },
    handlerHideDetails() {
      this.$bvModal.hide(this.serviceShowDetailsModalId)
    },
    handlerPackageShowDetails(id) {
      const packageItem = this.services
          .filter(item => item.is_package && item.packages
              .find(i => i.id === id))[0].packages
          .find(i => i.id === id)

      this.serviceDetails = {
        isPackage: true,
        id: packageItem.id,
        title: packageItem.title,
        description: packageItem.description,
        services: packageItem.services,
        selectedPackage: packageItem.selected,
        price:  this.$options.filters.priceFormat(packageItem.total, this.priceData),
        duration: this.$options.filters.time(packageItem.total_duration)
      }
      this.$bvModal.show(this.serviceShowDetailsModalId)
    },

    handlerShowServiceFilter() {
      this.filterModalTitle = this.$t('b2c_filter_by_service')
      this.typeFilter = 'services'
      this.$bvModal.show(this.filterModalId)
    },
    handlerShowStaffFilter() {
      this.filterModalTitle = this.$t('b2c_filter_by_staff')
      this.typeFilter = 'employees'
      this.$bvModal.show(this.filterModalId)
    },

    resetFilter() {
      this.$store.dispatch('resetFilter', {type: this.typeFilter})
      this.$bvModal.hide(this.filterModalId)
    },
    applyFilter() {
      this.$store.commit('applyFilter')
      this.$bvModal.hide(this.filterModalId)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-services {
  margin-top: -16px;

  &__header {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    margin-left: -15px;
    margin-right: -15px;
    position: sticky;
    top: 56px;
    z-index: 55;
    background: $white;
  }
}

</style>
