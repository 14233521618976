<template>
  <div>
    <be-card-appointment-placeholder is-status/>
    <be-card-appointment-placeholder is-button/>
    <be-card-appointment-placeholder is-status/>
    <be-card-appointment-placeholder is-button/>
    <be-card-appointment-placeholder is-status/>
    <be-card-appointment-placeholder is-button/>
  </div>
</template>

<script>
import BeCardAppointmentPlaceholder from "@/components/cards/BeCardAppointmentPlaceholder";
export default {
  name: "BePlaceholderAppointmentList.vue",
  components: {BeCardAppointmentPlaceholder}
}
</script>

<style scoped>

</style>
