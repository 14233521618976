<template>
  <div class="be-card-list-team">
    <div class="be-card-list-team__inner">
      <router-link :to="{name: 'VenueEmployee', params: {employee: id}}" v-if="image" class="be-card-list-team__image">
        <img :src="image" :alt="`${firstName} ${lastName}`">
      </router-link>
      <router-link :to="{name: 'VenueEmployee', params: {employee: id}}" v-else class="be-card-list-team__image">
        <span class="letter">{{firstName ? firstName[0] : ''}}{{lastName ? lastName[0] : ''}}</span>
      </router-link>
      <div class="be-card-list-team__info">
        <router-link :to="{name: 'VenueEmployee', params: {employee: id}}" class="name">{{firstName}} <br> {{lastName}}</router-link>
        <be-link :to="{name: 'VenueEmployee', params: {employee: id}}" variant="primary">{{ $t('b2c_bio') }}</be-link>
      </div>
    </div>
  </div>
</template>

<script>
import BeLink from "@/components/common/BeLink";
import {imagePath} from "@/utils/common";
export default {
  name: "BeCardListTeam",
  components: {BeLink},
  props: {
    id: {
      type: [Number, String]
    },
    image: {
      type: String
    },
    firstName: {
      type: String
    },
    lastName: {
      type: String
    },
  },
  methods: {
    imageWrapper(image) {
      return imagePath(`/i/emp/${image}`)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-card-list-team {
  padding: 0 5px;
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75px;
  }

  &__image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    background: $text-color-light;
    text-decoration: none;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .letter {
      font-size: 20px;
      text-transform: uppercase;
      color: $white;
      font-weight: normal;
    }
  }
  &__info {
    text-align: center;
    .name {
      display: block;
      text-decoration: none;
      font-size: 14px;
      font-weight: normal;
      color: $text-color;
      line-height: 1.4;
      min-height: 40px;
      margin-bottom: 5px;
    }
  }
}

</style>
