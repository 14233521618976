<template>
  <div class="be-payment-card">
    <div class="be-payment-card__icon">
      <img :src="paymentBrand(brand).logo" :alt="paymentBrand(brand).alt">
    </div>
    <span class="be-payment-card__number">**** **** **** {{number}}</span>
  </div>
</template>

<script>
import Visa from "@/assets/img/icons/visa.svg";
import MasterCard from "@/assets/img/icons/mastercard.svg";
import PaymentCard from "@/assets/img/icons/card.svg";

export default {
  name: "BePaymentCard",
  props: {
    brand: {
      type: String
    },
    number: {
      type: String
    }
  },
  data() {
    return {
      brands: {
        'visa': {
          alt: 'Visa',
          logo: Visa
        },
        'mastercard': {
          alt: 'MasterCard',
          logo: MasterCard
        },
        'default': {
          alt: 'Payment Card',
          logo: PaymentCard
        }
      }
    }
  },
  methods: {
    paymentBrand(name) {
      return this.brands[name] ? this.brands[name] : this.brands['default']
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-payment-card {
  display: inline-flex;
  align-items: center;

  &__icon {
    border-radius: 4px;
    border: 1px solid $white-300;
    min-width: 36px;
    width: 36px;
    height: 26px;
    margin-right: 7px;
    text-align: center;
    img {
      width: 85%;
      height: auto;
      object-fit: contain;
    }
  }
  &__number {
    font-size: 14px;
    font-weight: 500;
    color: $text-color-light;
  }
}

</style>
