<template>
  <be-modal :id="privacyModal.idModal" :title="$t('b2c_privacy_policy')">
    <div class="be-content" v-html="privacyModal.content"/>
  </be-modal>
</template>

<script>
import BeModal from "@/components/BeModals/BeModal";
export default {
  name: "BePrivacyPolicyModal",
  components: {BeModal},
  computed: {
    privacyModal() {
      return this.$store.getters.privacyPolicy
    }
  }
}
</script>

<style scoped>

</style>
