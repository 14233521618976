import axios from "axios";
import store from "@/store";
import {authToken, langSuffix, langType} from "@/utils/common";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import EventBus from "@/utils/event-bus";

const token = authToken()
const lang = langType()

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

export const httpBooking = axios.create({
  baseURL: process.env.VUE_APP_BOOKING_API_URL
});

http.defaults.headers.common["Source"] = 'b2c';
httpBooking.defaults.headers.common["Source"] = 'b2c';

http.defaults.headers.common["Content-Language"] = langSuffix(lang);
httpBooking.defaults.headers.common["Content-Language"] = langSuffix(lang);

if(token) {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  httpBooking.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

http.interceptors.response.use((response) => {
  return response
}, (error) => {
  if(error.response && error.response.status === 403) {
    if(error.response.data.status === 'expired') {
      localStorage.setItem("access_token", error.response.data.access_token);

      http.defaults.headers.common["Authorization"] = `Bearer ${error.response.data.access_token}`;
      httpBooking.defaults.headers.common["Authorization"] = `Bearer ${error.response.data.access_token}`;

      EventBus.$emit('repeatRequest', {isRepeatRequest: true, config: error.config})
    } else {
      store.dispatch('logout', {type: error.response.data.status})
      GoogleAuth.signOut();
    }
  }
  throw error;
});

http.interceptors.request.use( (config) => {
  return config;
},  (error) => {
  // Do something with request error
  return Promise.reject(error);
})

httpBooking.interceptors.response.use((response) => {
  return response
}, (error) => {
  if(error.response && error.response.status === 403) {
    if(error.response.data.status === 'expired') {
      localStorage.setItem("access_token", error.response.data.access_token);

      http.defaults.headers.common["Authorization"] = `Bearer ${error.response.data.access_token}`;
      httpBooking.defaults.headers.common["Authorization"] = `Bearer ${error.response.data.access_token}`;

      EventBus.$emit('repeatRequest', {isRepeatRequest: true, config: error.config})
    } else {
      store.dispatch('logout', {type: error.response.data.status})
      GoogleAuth.signOut();
    }
  }
  throw error;
});

httpBooking.interceptors.request.use( (config) => {
  return config;
},  (error) => {
  // Do something with request error
  return Promise.reject(error);
})
