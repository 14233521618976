<template>
  <be-modal
    :id="`language-modal`"
    :title="$t('b2c_change_language')"
  >
    <div class="be-language">
      <div class="be-language__header">
        <p class="sub-title">{{ $t('b2c_set_your_location_for_experience') }}</p>
      </div>
      <div class="be-language__body">
        <div v-if="languages.find(i => i.selected)" class="be-language__group">
          <h4 class="be-language__group-title">{{ $t('b2c_chosen_language') }}</h4>
          <be-language-select
            :lang="languages.find(i => i.selected).lang"
            :title="languages.find(i => i.selected).title"
            is-active
          />
        </div>
        <div class="be-language__group">
          <h4 class="be-language__group-title">{{ $t('b2c_languages') }}</h4>
          <div class="be-language__group-list">
            <be-language-select
                v-for="(lang, i) in languages.filter(item => !item.selected)"
                :key="i"
                :lang="lang.lang"
                :title="lang.title"
                :is-loading="isLoading && selectedLang === lang.lang"
                @select="handlerSelect"
            />
          </div>
        </div>
      </div>
    </div>
  </be-modal>
</template>

<script>
import BeModal from "@/components/BeModals/BeModal";
import BeLanguageSelect from "@/components/common/BeLanguageSelect";
import {loadLanguageAsync} from "@/i18n";
import {langSuffix} from "@/utils/common";

const {http, httpBooking} = require("@/axios");

const LANGUAGES = [
  {
    lang: 'en',
    title: 'English',
    selected: true
  },
  {
    lang: 'de',
    title: 'Deutsch',
    selected: false
  },
  {
    lang: 'fr',
    title: 'Français',
    selected: false
  },
  {
    lang: 'pl',
    title: 'Polski',
    selected: false
  },
  {
    lang: 'it',
    title: 'Italiano',
    selected: false
  },
  {
    lang: 'es',
    title: 'Español',
    selected: false
  }
]

export default {
  name: "BeLanguageModal",
  components: {BeLanguageSelect, BeModal},
  data() {
    return {
      isLoading: false,
      selectedLang: ''
    }
  },
  computed: {
    languages() {
      const selectedLang = this.$store.getters.appLanguage

      return LANGUAGES.map(lang => {
        return {
          ...lang,
          selected: selectedLang === lang.lang
        }
      })
    }
  },
  methods: {
    loader(value) {
      this.isLoading = value
    },
    selectLanguage(lang) {
      this.selectedLang = lang
      http.defaults.headers.common['Content-Language'] = langSuffix(lang);
      httpBooking.defaults.headers.common['Content-Language'] = langSuffix(lang);
    },
    async handlerSelect(lang) {
      await this.loader(true);
      await this.selectLanguage(lang);
      await loadLanguageAsync(lang);
      await this.$store.commit('setAppLanguage', {lang: lang});
      await this.loader(false);
      await this.$bvModal.hide('language-modal');
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/global/variables";

.be-language {
  &__header {
    padding-bottom: 16px;
    .sub-title {
      font-size: 14px;
      color: $text-color-light;
      font-weight: normal;
      margin-bottom: 6px;
    }
  }
  &__group {
    padding-bottom: 15px;
    &-title {
      font-size: 16px;
      font-weight: bold;
      color: $text-color-dark;
      padding-bottom: 12px;
      border-bottom: 1px solid $border-color;
      margin-bottom: 14px;
    }
  }
}

</style>
