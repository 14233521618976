<template>
  <div class="be-wrapper-basket-package">
    <span
        v-if="!hideRemoveButton"
        @click="$emit('remove')"
        class="be-wrapper-basket-package__remove">
        <i class="icon-be-close"/>
      </span>
    <div class="be-wrapper-basket-package__header">
      <span class="be-wrapper-basket-package__header-title">{{title}}</span>
      <div class="be-wrapper-basket-package__header-info">
        <span class="duration">{{duration}}</span>
        <span class="price">{{price}}</span>
      </div>
    </div>
    <div class="be-wrapper-basket-package__body">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeWrapperBasketPackage",
  props: {
    title: {
      type: String
    },
    price: {
      type: [String, Number]
    },
    duration: {
      type: [String, Number]
    },
    hideRemoveButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/global/variables";

.be-wrapper-basket-package {
  padding: 10px 10px 10px;
  border-radius: 6px;
  border: 1px dashed $border-color;
  margin-top: 12px;
  position: relative;

  &__remove {
    position: absolute;
    right: 10px;
    bottom: 0px;
    i {
      display: inline-block;
      cursor: pointer;
      font-size: 24px;
      color: $text-color-dark;
      transition: .3s;

      &:hover {
        transform: rotate(180deg);
        color: $red;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;

    &-title {
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
    }
    &-info {
      display: flex;
      align-items: center;

      .duration {
        display: inline-block;
        font-weight: normal;
        font-size: 13px;
        color: $text-color-light;
      }
      .price {
        display: inline-block;
        font-size: 16px;
        color: $text-color;
        margin-left: 10px;
      }
    }
  }
  &__body {
    div {
      &:last-child {
        border: none;
      }
    }
  }
}
</style>
