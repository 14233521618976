var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-wrapper',{attrs:{"id":"nav-scroller","sticky-footer":!_vm.isBasket,"transparent-footer":""},on:{"scroll-ref":_vm.setScrollRef},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('be-header',{attrs:{"is-salon-profile":"","is-opacity":_vm.venueGallery.length > 0,"title":_vm.venue ? _vm.venue.venueInformation.title : '',"is-favorite":_vm.venue ? _vm.venue.isFavorite : false,"back-to-route":_vm.backRoute ? _vm.backRoute : 'Venues'},on:{"to-favorite":function($event){return _vm.addFavorite(_vm.venue.id)},"from-favorite":function($event){return _vm.removeFavorite(_vm.venue.id)}}})]},proxy:true},{key:"footer",fn:function(){return [(!_vm.isBasket)?_c('be-button',{attrs:{"variant":"primary","is-full":""},on:{"click":_vm.bookNow}},[_vm._v(_vm._s(_vm.$t('b2c_book_now'))+" ")]):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"be-venue",attrs:{"no-body":""}},[(!_vm.isLoading && _vm.venueGallery.length > 0)?_c('be-venue-slider',{attrs:{"gallery":_vm.venueGallery}}):_vm._e(),(!_vm.isLoading && _vm.venue)?_c('be-venue-header',{attrs:{"data":_vm.venue,"review-length":_vm.venueReviewsLength},on:{"to-favorite":_vm.addFavorite,"from-favorite":_vm.removeFavorite}}):_c('div',{staticClass:"text-center pt-4"},[_c('b-spinner',{staticClass:"be-spinner"})],1),_c('be-venue-navbar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading && _vm.venue),expression:"!isLoading && venue"},{name:"b-scrollspy",rawName:"v-b-scrollspy:nav-scroller.160",arg:"nav-scroller",modifiers:{"160":true}}],on:{"scroll-to":_vm.scrollIntoView}}),(!_vm.isLoading && _vm.venue)?_c('div',[(_vm.isLoadingContent)?_c('div',{staticClass:"text-center pt-5"},[_c('b-spinner',{staticClass:"be-spinner"})],1):_c('div',[_c('be-venue-services',{ref:"servicesSection",attrs:{"id":"servicesSection","services":_vm.popularServices}}),_c('be-venue-team',{attrs:{"team":_vm.employees}}),_c('be-venue-reviews',{ref:"reviewsSection",attrs:{"data":{
              main: _vm.venue.averageRating,
              length: _vm.venueReviewsLength,
              atmosphere: _vm.venue.reviewByCategories.Atmosphere,
              cleanliness: _vm.venue.reviewByCategories.Cleanliness,
              stylist: _vm.venue.reviewByCategories.Stylist,
              value: _vm.venue.reviewByCategories.Value,
            },"id":"reviewsSection"}}),_c('be-venue-about',{ref:"aboutSection",attrs:{"id":"aboutSection","data":_vm.venue}})],1)]):_vm._e()],1),_c('be-modal-prompts',{attrs:{"id":_vm.idUnauthorizedModal}},[_c('be-prompt-unauthorized-user',{attrs:{"icon":_vm.iconHeart,"title":_vm.$t('b2c_login_to_add_favorites'),"description":_vm.$t('b2c_login_to_add_favorites_description')},on:{"hide-modal":() => _vm.$bvModal.hide(_vm.idUnauthorizedModal)}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }