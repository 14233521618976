import {httpBooking} from "@/axios";

const PAYMENT_DATA = '/payment-data';
const PAYMENT_INDENT = '/save-card';
const PAYMENT_METHOD = '/payment-method';
const NEW_PAYMENT_METHOD = '/pre-payment';

export default {
  getPaymentData: (venueId) => httpBooking.get(`${PAYMENT_DATA}/${venueId}`),
  getPaymentIndent: (venueId) => httpBooking.post(`${PAYMENT_INDENT}/${venueId}`),
  setPaymentMethod: (venueId, data) => new Promise((resolve, reject) => {
    httpBooking.post(`${PAYMENT_DATA}/${venueId}`, data)
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  getPaymentMethod: (venueId, isSaveCard) => new Promise((resolve, reject) => {
    httpBooking.get(`${PAYMENT_METHOD}/${venueId}`, {params: {save_card: isSaveCard ? 1 : 0}})
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  getPaymentNewData: (venueId) => new Promise((resolve, reject) => {
    httpBooking.get(`${NEW_PAYMENT_METHOD}/${venueId}`)
      .then(response => resolve(response))
      .catch(e => reject(e))
  })
};
