<template>
  <div class="be-services-filters">
    <div v-if="type === 'services'" class="be-services-filters__list">
      <div
          v-for="item in services"
          :key="item.id"
          class="be-services-filters__list-item">
        <be-checkbox
          is-filter
          :id="item.id"
          :text="item.title"
          :checked="item.checked"
          @input="(val) => handlerServicesFilter(val, item.title)"
        />
      </div>
    </div>
    <div v-if="type === 'employees'" class="be-services-filters__list">
      <div
          v-for="item in employees"
          :key="item.id"
          class="be-services-filters__list-item">
        <be-checkbox
            is-filter
            :id="item.id"
            :text="`${item.first_name} ${item.last_name}`"
            :sub-title="item.position"
            :checked="item.checked"
            @input="(val) => handlerEmployeesFilter(val, item.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BeCheckbox from "@/components/common/BeCheckbox";
export default {
  name: "BeServicesFiltersList",
  components: {BeCheckbox},
  props: {
    type: {
      type: String
    }
  },
  computed: {
    services() {
      return this.$store.getters.servicesFilters
    },
    employees() {
      return this.$store.getters.employeesFilters
    }
  },
  methods: {
    handlerServicesFilter(value, id) {
      this.$store.commit('filterByServices', {checked: value, value: id})
    },
    handlerEmployeesFilter(value, id) {
      this.$store.commit('filterByEmployees', {checked: value, value: id})
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-services-filters {
  &__list {
    &-item {
      margin-left: -15px;
      margin-right: -15px;
      padding: 15px 15px;
      border-top: 1px solid $border-color;

      &:last-child {
        border-bottom: 1px solid $border-color;
      }

      .be-checkbox_text {
        font-size: 16px;
      }
    }
  }
}

</style>
