<template>
  <b-modal
      :id="id"
      hide-footer
      :title="title"
      :hide-header-close="hideHeaderClose"
      @hide="$emit('hide')"
      :modal-class="`be-modal`"
      :body-class="`be-modal__body ${classBody}`"
  >
    <template #modal-header-close>
      <i class="icon-be-close"></i>
    </template>
    <slot />
    <div class="be-modal__body-btn">
      <slot name="buttons"/>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "BeModal",
  props: {
    id: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    classBody: {
      type: String
    },
    hideHeaderClose: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";
@import "../../assets/scss/global/mixins";
</style>
