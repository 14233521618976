<template>
  <label :for="id" class="be-switcher">
    <input type="checkbox" :checked="checked" @input="(e) => $emit('input', e.target.checked)" :id="id"/>
    <span class="be-switcher__lever"></span>
    <span class="be-switcher__text">{{title}}</span>
  </label>
</template>

<script>
export default {
  name: "BeSwitcher",
  props: {
    id: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    checked: {
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-switcher {
  position: relative;
  display: block;
  margin: 0 0 20px;
  padding-left: 45px;
  input {
    position: absolute;
    list-style-position: outside;
    top: 0;
    opacity: 0;
    cursor: pointer;

    &:checked {
      ~.be-switcher__lever {
        background: $primary-color;

        &:before {
          left: calc(100% - 17px);
        }
      }
    }
  }

  &__lever {
    width: 34px;
    height: 18px;
    border-radius: 36px;
    background: $border-color;
    position: absolute;
    left: 0;
    top: 2px;
    cursor: pointer;
    z-index: 2;
    transition: .2s;

    &:before {
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $white;
      transition: .2s;
    }

    &:hover {
      box-shadow: 0 0 7px $border-color;
    }
  }
  &__text {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    color: $text-color;
    margin: 0;
    line-height: 1.4;
    cursor: pointer;
    transition: .2s;
    user-select: none;

    &:hover {
      color: $text-color-dark;
    }
  }
}

</style>
