<template>
  <main-wrapper :is-center-content="true">
    <div class="be-check-email">
      <div v-if="!resetPasswordSuccessfully" class="be-check-email__inner">
        <be-prompt
            :icon="Envelop"
            :title="$t('b2c_check_your_mail')"
            :description-light="$t('b2c_we_have_sent_the_code_to_your_email')"
        />
        <div class="be-check-email__form">
          <be-captcha :is-error="isErrorCaptcha" @finish="checkCode"/>

          <be-alert
              v-if="isErrorCaptcha || isInvalidCode"
              :type="isErrorCaptcha ? 'error' : 'warning'"
              :title="errorMessageCaptcha"/>

          <div v-if="isInvalidCode" class="be-check-email__form-btn pt-3">
            <be-button variant="primary" @click="$router.push({name: 'Authorization'})" :is-full="true">{{ $t('b2c_request_a_new_code') }}</be-button>
          </div>
        </div>
      </div>
      <div v-else class="be-check-email__inner">
        <be-prompt
            :icon="Success"
            :title="$t('b2c_password_updated')"
            :description-light="$t('b2c_password_updated_description')"
        />
        <be-button variant="primary" @click="$router.push({name: 'Authorization'})" :is-full="true">{{ $t('b2c_login') }}</be-button>
      </div>
    </div>
    <be-modal :id="idResetPassword" :title="$t('b2c_create_new_password')">
      <div class="be-form">
        <form @submit.prevent="resetPassword">
          <p class="be-form__paragraph">{{ $t('b2c_your_new_password_must_be_different') }}</p>
          <be-input
              :type="isShowPassword ? 'text' : 'password'"
              name="password"
              :label="$t('b2c_password')"
              :required="true"
              :password-switcher="true"
              :value="form.password"
              @input="(value) => form.password = value"
              :error="errors.password"
              :info="info.password"
              :success="success.password"
              @switch="() => isShowPassword = !isShowPassword"
              @validate="validate('password')"
          />
          <be-input
              :type="isShowPassword ? 'text' : 'password'"
              name="confirm-password"
              :label="$t('b2c_confirm_password')"
              :required="true"
              :value="form.confirmPassword"
              @input="(value) => form.confirmPassword = value"
              :error="errors.confirmPassword"
              :info="info.confirmPassword"
              :success="success.confirmPassword"
              @validate="validate('confirmPassword')"
          />

          <div class="be-form-group-btn pt-2">
            <be-button
                :type="'submit'"
                :is-full="true"
                variant="primary"
                :is-loading="isLoading"
            >
              {{ $t('b2c_reset_password') }}
            </be-button>
          </div>
        </form>
      </div>
    </be-modal>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BePrompt from "@/components/common/BePrompt";
import Envelop from "../../assets/img/icons/be-envelop.svg"
import Success from "../../assets/img/icons/icon-success.svg"
import BeCaptcha from "@/components/common/BeCaptcha";
import BeButton from "@/components/common/BeButton";
import BeModal from "@/components/BeModals/BeModal";
import {object, ref, string} from "yup";
import BeInput from "@/components/common/BeInput";
import auth from "@/api/auth";
import BeAlert from "@/components/common/BeAlert";

export default {
  name: "CheckEmail",
  components: {BeAlert, BeModal, BeCaptcha, BePrompt, BeInput, BeButton, MainWrapper},
  data() {
    return {
      Envelop,
      Success,
      idResetPassword: 'reset-password-modal',
      isShowPassword: false,
      isLoading: false,
      verifyCode: '',
      resetPasswordSuccessfully: false,
      isErrorCaptcha: false,
      isInvalidCode: false,
      errorMessageCaptcha: '',

      form: {
        password: '',
        confirmPassword: '',
      },
      info: {
        password: 'Must be at least 8 characters.',
        confirmPassword: 'Both passwords must match.',
      },
      success: {
        password: '',
        confirmPassword: '',
      },
      errors: {
        password: '',
        confirmPassword: '',
      },
    }
  },
  computed: {
    PasswordSchema() {
      const params = {
        password: string()
            .required(this.$t("b2c_this_field_is_required"))
            .min(
                8,
                this.$t("b2c_password_must_be_at_least_characters_long", {num: 8})
            ),
        confirmPassword: string()
            .required(this.$t("b2c_this_field_is_required"))
            .oneOf(
                [ref("password"), null],
                this.$t("b2c_both_passwords_must_match")
            )
      };

      return object().shape(params);
    }
  },
  mounted() {
    if(this.resetPasswordSuccessfully) {
      this.$router.push({name: 'Authorization'})
    }
  },
  methods: {
    validate(field) {
      this.PasswordSchema.validateAt(field, this.form)
          .then(() => {
            if (field === 'confirmPassword') {
              this.errors.password = "";
              this.info.password = "";
              this.errors[field] = "";
              this.info[field] = "";
              this.success.password = this.$t('b2c_password_must_be_at_least_characters_long', {num: 8});
              this.success[field] = this.$t('b2c_both_passwords_must_match');
            } else {
              this.errors[field] = "";
              this.info[field] = "";
              this.success[field] = this.$t('b2c_password_must_be_at_least_characters_long', {num: 8});
            }
          })
          .catch((err) => {
            if (err.path === 'confirmPassword') {
              this.errors.password = " ";
              this.success.password = "";
              this.info[err.path] = "";
              this.success[err.path] = "";
              this.errors[err.path] = err.message;
            } else {
              this.errors[err.path] = err.message;
              this.info[field] = "";
              this.success[field] = "";
            }
          });
    },
    async checkCode(code) {
      this.isErrorCaptcha = false
      this.isInvalidCode = false
      try {
        await auth.checkVerifyCode(code)
        await this.$bvModal.show(this.idResetPassword)
        await this.$store.commit('setEmailToResetPassword', '')
        this.verifyCode = code
      } catch (e) {
        if(e.response.status === 400) {
          this.isErrorCaptcha = true
          this.errorMessageCaptcha = this.$t('b2c_enter_the_correct_code')
        } else if(e.response.status === 401) {
          this.isInvalidCode = true
          this.errorMessageCaptcha = this.$t('b2c_code_is_invalid')
        }
        console.log(e.response)
      }
    },
    async resetPassword() {
      this.isLoading = true
      this.PasswordSchema.validate(this.form, {abortEarly: false})
          .then(async () => {
            try {
              await auth.resetPassword({
                code: this.verifyCode,
                password: this.form.password,
                password_confirmation: this.form.confirmPassword
              })
              this.isLoading = false
              this.$bvModal.hide(this.idResetPassword)
              this.resetPasswordSuccessfully = true
            } catch (e) {
              this.isLoading = false
              console.log(e)
            }
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.errors = {...this.errors, [error.path]: error.message};
            });
          });
    }
  }
}
</script>

<style scoped>

</style>
