<template>
  <div class="be-prompt-unauthorized-user">
    <be-prompt
      :icon="icon"
      :title="title"
      :description="description"
    />
    <div class="be-prompt-unauthorized-user__btn">
      <be-button variant="primary" @click="handlerRedirect('/login')">{{ $t('b2c_login') }}</be-button>
      <span class="or">{{ $t('b2c_or') }}</span>
      <be-link @click="handlerRedirect('/sign-up')"><b>{{ $t('b2c_create_an_account') }}</b></be-link>
    </div>
  </div>
</template>

<script>
import BePrompt from "@/components/common/BePrompt";
import BeButton from "@/components/common/BeButton";
import BeLink from "@/components/common/BeLink";
export default {
  name: "BePromptUnauthorizedUser",
  components: {BeLink, BeButton, BePrompt},
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
  },
  methods: {
    handlerRedirect(path) {
      this.$emit('hide-modal')
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-prompt-unauthorized-user {
  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;

    .or {
      display: inline-block;
      font-size: 16px;
      font-weight: normal;
      color: $text-color;
      margin: 12px 0;
    }
  }
}

</style>
