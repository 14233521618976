<template>
  <b-modal
      :id="id"
      centered
      hide-header
      hide-footer
      :no-close-on-backdrop="noCloseOnBackdrop"
      @hide="$emit('hide')"
      modal-class="be-modal-prompts"
      body-class="be-modal-prompts__body"
  >
    <slot />
    <div class="be-modal-prompts__body-btn">
      <slot name="buttons"/>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "BeModalPrompts",
  props: {
    id: {
      type: [String, Number]
    },
    noCloseOnBackdrop: {
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";
@import "../../assets/scss/global/mixins";



</style>
