<template>
  <div class="be-form">
    <form @submit.prevent="sendEmail">
      <p class="be-form__paragraph">{{ $t('b2c_enter_the_email_associated_with_your_account') }}</p>

      <be-input
          type="email"
          name="email"
          :label="$t('b2c_your_email')"
          :required="true"
          :value="form.email"
          @input="(value) => form.email = value"
          :error="errors.email"
          @validate="validate('email')"
      />
      <div class="pt-2">
        <be-button
            :is-full="true"
            :type="'submit'"
            :is-loading="isLoading"
            variant="primary"
        >
          {{ $t('b2c_send_instructions') }}
        </be-button>
      </div>
    </form>
  </div>
</template>

<script>
import BeInput from "@/components/common/BeInput";
import BeButton from "@/components/common/BeButton";
import {object, string} from "yup";
import auth from "@/api/auth";

export default {
  name: "ForgotPasswordForm",
  components: {BeInput, BeButton},
  data() {
    return {
      isLoading: false,
      form: {
        email: ''
      },
      errors: {
        email: ''
      },
    }
  },
  computed: {
    EmailFormSchema() {
      const params = {
        email: string()
            .email(this.$t("b2c_email_must_be_valid"))
            .required(this.$t("b2c_this_field_is_required"))
      };

      return object().shape(params);
    },
    emailToResetPassword() {
      return this.$store.getters.emailToResetPassword
    }
  },
  mounted() {
    if(this.emailToResetPassword) {
      this.form.email = this.emailToResetPassword
    }
  },
  methods: {
    validate(field) {
      this.EmailFormSchema.validateAt(field, this.form)
          .then(() => {
            this.errors[field] = "";
          })
          .catch((err) => {
            this.errors[err.path] = err.message;
          });
    },
    async sendEmail() {
      this.isLoading = true
      this.EmailFormSchema.validate(this.form, {abortEarly: false})
          .then(async () => {
            try {
              await auth.forgotPassword(this.form.email)
              await this.$store.commit('setEmailToResetPassword', this.form.email)
              await this.$router.push({name: 'CheckEmail'})
            } catch (e) {
              this.isLoading = false
              console.log(e)
            }
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.errors = {...this.errors, [error.path]: error.message};
            });
          });
    }
  }
}
</script>

<style scoped>

</style>
