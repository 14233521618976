<template>
  <span>{{totalBasketPrice | totalBasket(basket, priceData)}}</span>
</template>

<script>
import {priceFormatter} from "@/utils/common";

export default {
  name: "BeBasketSummaryString",
  computed: {
    basket() {
      return this.$store.getters.savedBasket
    },
    totalBasketPrice() {
      let total = 0
      this.$store.getters.savedBasket.forEach(i => {
        if (i.type !== 'hide_price') {
          if(i.package_id) {
            total = total + i.custom_price
          } else {
            total = total + i.price
          }
        }
      })
      return total
    },
    priceData() {
      return this.$store.getters.priceData
    }
  },
  filters: {
    totalBasket(price, array, format) {
      const hidePrice = array.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      const bookable = array.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (hidePrice.length > 0 && bookable.length > 0) {
        return `from ${priceFormatter(price, format)}`;
      } else if (hidePrice.length === 0) {
        return priceFormatter(price, format);
      } else {
        return hidePrice[0].price_formatted;
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>
