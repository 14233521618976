<template>
  <div class="be-card-appointment-placeholder">
    <div class="be-card-appointment-placeholder__inner">
      <div class="be-card-appointment-placeholder__salon">
        <span class="salon"></span>
      </div>
      <div class="be-card-appointment-placeholder__service">
        <div class="d-flex justify-content-between">
          <span class="date"></span>
          <div>
            <span class="price"></span>
          </div>
        </div>
        <p class="title"/>
        <span class="title-group"/>
        <span class="time"/>
        <span v-if="isStatus" class="status"/>
      </div>
      <div v-if="isButton" class="be-card-appointment-placeholder__control">
        <span class="button"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeCardAppointmentPlaceholder",
  props: {
    isStatus: {
      type: Boolean
    },
    isButton: {
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";
@import "../../assets/scss/global/mixins";

.be-card-appointment-placeholder {
  padding: 12px;
  border-radius: 4px;
  border: 1px dashed $border-color;
  margin-bottom: 15px;
  &__inner {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px dashed $border-color;

    &:last-child {
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  &__salon {
    padding-bottom: 4px;
    .salon {
      @include placeholder-text(70%);
      @include placeholder-slide-shadow();
    }
  }
  &__service {
    display: flex;
    flex-direction: column;
    .date {
      @include placeholder-text(120px);
      @include placeholder-slide-shadow();
    }
    .price {
      @include placeholder-text(70px);
      @include placeholder-slide-shadow();
    }
    .title {
      @include placeholder-title(190px);
      @include placeholder-slide-shadow();
      margin-bottom: 8px;
    }
    .title-group {
      @include placeholder-text(80px);
      @include placeholder-slide-shadow();
      margin-bottom: 8px;
    }
    .time {
      @include placeholder-title(160px);
      @include placeholder-slide-shadow();
      margin-bottom: 8px;
    }
    .status {
      @include placeholder-text(80px);
      @include placeholder-slide-shadow();
    }
  }
  &__control {
    padding-top: 10px;
    .button {
      @include placeholder-button(100px);
      @include placeholder-slide-shadow();
    }
  }
}

</style>
