var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-wrapper',{attrs:{"sticky-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('be-header',{attrs:{"title":_vm.$t('b2c_select_staff'),"back-to-route":"VenueServices"}})]},proxy:true},{key:"footer",fn:function(){return [(_vm.basket.length !== 1 || (_vm.basket.length === 1 && _vm.basket[0].employee_name))?_c('be-button',{attrs:{"disabled":!_vm.basket.every(i => i.employee_id !== null),"is-full":"","variant":"primary"},on:{"click":_vm.redirectToSlots}},[_vm._v(" "+_vm._s(_vm.$t("b2c_choose_time"))+" ")]):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"be-venue-staff"},[(_vm.basket.length > 1)?_c('div',{staticClass:"be-venue-staff__list"},[_vm._l((_vm.basketPackages),function(packageItem){return _c('be-wrapper-employee-package',{key:packageItem.id,attrs:{"title":packageItem.title},on:{"remove":function($event){return _vm.removePackage(packageItem.id)}}},_vm._l((packageItem.services),function(service){return _c('be-card-unknown-employee',{key:`${service.variant_id}-${packageItem.id}`,attrs:{"id":service.variant_id,"service-title":`${service.service_title} ${service.variant_title ? `(${service.variant_title})` : ''}`,"group-title":service.group_title,"selected":service.employee_name ? service.employee_name : '',"hide-remove-btn":""},on:{"change":function($event){return _vm.showEmployees(
              service.employees,
              service.variant_id,
              service.employee_name ? service.employee_id : -1
              )}}})}),1)}),_vm._l((_vm.basket.filter(i => !i.package_id)),function(service){return _c('be-card-unknown-employee',{key:service.variant_id,attrs:{"id":service.variant_id,"service-title":`${service.service_title} ${service.variant_title ? `(${service.variant_title})` : ''}`,"group-title":service.group_title,"selected":service.employee_name ? service.employee_name : ''},on:{"remove":function($event){return _vm.removeService(service.variant_id)},"change":function($event){return _vm.showEmployees(
              service.employees,
              service.variant_id,
              service.employee_name ? service.employee_id : -1
              )}}})})],2):(_vm.basket.length === 1 && _vm.basket[0].employee_name)?_c('div',{staticClass:"be-venue-staff__list"},_vm._l((_vm.basket),function(service){return _c('be-card-unknown-employee',{key:service.variant_id,attrs:{"id":service.variant_id,"service-title":`${service.service_title} ${service.variant_title ? `(${service.variant_title})` : ''}`,"group-title":service.group_title,"selected":service.employee_name ? service.employee_name : ''},on:{"remove":function($event){return _vm.removeService(service.variant_id)},"change":function($event){return _vm.showEmployees(
              service.employees,
              service.variant_id,
              service.employee_name ? service.employee_id : -1
              )}}})}),1):_c('div',{staticClass:"be-venue-staff__list"},[_c('be-card-employee',{attrs:{"id":0,"variant-id":_vm.basket[0].variant_id,"name":_vm.$t('b2c_first_available_specialist'),"position":_vm.$t('b2c_maximum_availability'),"avatar":_vm.Avatar,"selected":_vm.basket[0].employee_name ? _vm.basket[0].employee_id === 0 : false},on:{"select":_vm.selectEmployee}}),_vm._l((_vm.basket[0].employees),function(employee){return _c('be-card-employee',{key:employee.id,attrs:{"id":employee.id,"variant-id":_vm.basket[0].variant_id,"name":`${employee.first_name} ${employee.last_name}`,"position":employee.position,"avatar":employee.img,"selected":_vm.basket[0].employee_id ? _vm.basket[0].employee_id === employee.id : false},on:{"select":_vm.selectEmployee}})})],2)]),_c('be-modal',{attrs:{"id":_vm.idEmployeesModal,"title":_vm.$t('b2c_select_staff')}},[_c('div',{staticClass:"be-venue-staff__list"},[_c('be-card-employee',{attrs:{"id":0,"variant-id":_vm.selectedVariant,"name":_vm.$t('b2c_first_available_specialist'),"position":_vm.$t('b2c_maximum_availability'),"avatar":_vm.Avatar,"selected":_vm.selectedEmployee === 0},on:{"select":_vm.selectEmployee}}),_vm._l((_vm.selectedEmployees),function(employee){return _c('be-card-employee',{key:employee.id,attrs:{"id":employee.id,"variant-id":_vm.selectedVariant,"name":`${employee.first_name} ${employee.last_name}`,"position":employee.position,"avatar":employee.img,"selected":_vm.selectedEmployee === employee.id},on:{"select":_vm.selectEmployee}})})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }