<template>
  <div v-ripple @click="$emit('select', lang)" class="be-language-btn">
    <div class="be-language-btn__general">
      <div class="be-language-btn__general-icon">
        <img :src="language.icon" :alt="title">
      </div>
      <span class="be-language-btn__general-label">{{title}}</span>
    </div>
    <div class="be-language-btn__tools">
      <b-spinner v-if="isLoading" class="be-spinner" small/>
      <span v-if="isActive" class="be-language-btn__tools-mark">
        <i class="icon-be-check"/>
      </span>
    </div>
  </div>
</template>

<script>
import en from "../../assets/img/lang/en.svg"
import de from "../../assets/img/lang/de.svg"
import es from "../../assets/img/lang/es.svg"
import fr from "../../assets/img/lang/fr.svg"
import pl from "../../assets/img/lang/pl.svg"
import it from "../../assets/img/lang/it.svg"

const LANGUAGES = {
  'en': {icon: en},
  'de': {icon: de},
  'it': {icon: it},
  'fr': {icon: fr},
  'pl': {icon: pl},
  'es': {icon: es}
}

export default {
  name: "BeLanguageSelect",
  props: {
    lang: {
      type: String
    },
    title: {
      type: String
    },
    isActive: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    }
  },
  computed: {
    language() {
      return LANGUAGES[this.lang] ? LANGUAGES[this.lang] : {icon: ''}
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/global/variables";
.be-language-btn {
  display: flex;
  padding: 10px 15px;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: .2s;
  &:hover {
    background: #f1f1f1;
  }

  &__general {
    display: flex;
    align-items: center;
    &-icon {
      width: 34px;
      min-width: 34px;
      height: 34px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid $border-color;
      margin-right: 18px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__tools {
    &-mark {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background: $primary-color;
      color: $white;
      font-size: 22px;
    }
  }
}

</style>
