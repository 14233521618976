<template>
  <main-wrapper id="nav-scroller" :sticky-footer="!isBasket" transparent-footer @scroll-ref="setScrollRef">
    <template #header>
      <be-header
          is-salon-profile
          :is-opacity="venueGallery.length > 0"
          :title="venue ? venue.venueInformation.title : ''"
          :is-favorite="venue ? venue.isFavorite : false"
          @to-favorite="addFavorite(venue.id)"
          @from-favorite="removeFavorite(venue.id)"
          :back-to-route="backRoute ? backRoute : 'Venues'"/>
    </template>
    <template #footer>
      <be-button
          v-if="!isBasket"
          @click="bookNow"
          variant="primary"
          is-full>{{ $t('b2c_book_now') }}
      </be-button>
    </template>
    <div class="be-venue" no-body>
      <be-venue-slider
          v-if="!isLoading && venueGallery.length > 0"
          :gallery="venueGallery"/>
      <be-venue-header
          v-if="!isLoading && venue"
          :data="venue"
          :review-length="venueReviewsLength"
          @to-favorite="addFavorite"
          @from-favorite="removeFavorite" />
      <div v-else class="text-center pt-4">
        <b-spinner class="be-spinner"/>
      </div>

      <be-venue-navbar
          v-show="!isLoading && venue"
          v-b-scrollspy:nav-scroller.160
          @scroll-to="scrollIntoView" />
      <div v-if="!isLoading && venue">
        <div v-if="isLoadingContent" class="text-center pt-5">
          <b-spinner class="be-spinner"/>
        </div>
        <div v-else>
          <be-venue-services
              ref="servicesSection"
              id="servicesSection"
              :services="popularServices" />
          <be-venue-team :team="employees"/>
          <be-venue-reviews
              :data="{
                main: venue.averageRating,
                length: venueReviewsLength,
                atmosphere: venue.reviewByCategories.Atmosphere,
                cleanliness: venue.reviewByCategories.Cleanliness,
                stylist: venue.reviewByCategories.Stylist,
                value: venue.reviewByCategories.Value,
              }"
              ref="reviewsSection"
              id="reviewsSection" />
          <be-venue-about
              ref="aboutSection"
              id="aboutSection"
              :data="venue" />
        </div>
      </div>
    </div>

    <!--  -->
    <be-modal-prompts
        :id="idUnauthorizedModal"
    >
      <be-prompt-unauthorized-user
          :icon="iconHeart"
          :title="$t('b2c_login_to_add_favorites')"
          :description="$t('b2c_login_to_add_favorites_description')"
          @hide-modal="() => $bvModal.hide(idUnauthorizedModal)"
      />
    </be-modal-prompts>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import venues from "@/api/venues";
import venue from "@/api/venue";
import user from "@/api/user";
import BePromptUnauthorizedUser from "@/components/common/BePromptUnauthorizedUser";
import BeModalPrompts from "@/components/BeModals/BeModalPrompts";

import iconHeart from "../../../assets/img/icons/icon-heart.svg"
import BeVenueNavbar from "@/views/Venues/_id/BeVenueNavbar";
import BeVenueHeader from "@/views/Venues/_id/BeVenueHeader";
import BeVenueServices from "@/views/Venues/_id/BeVenueServices";
import BeVenueTeam from "@/views/Venues/_id/BeVenueTeam";
import BeVenueReviews from "@/views/Venues/_id/BeVenueReviews";
import BeVenueAbout from "@/views/Venues/_id/BeVenueAbout";
import BeVenueSlider from "@/views/Venues/_id/BeVenueSlider";
import BeButton from "@/components/common/BeButton";
import EventBus from "@/utils/event-bus";

export default {
  name: "VenuePreview",
  components: {
    BeButton,
    BeVenueSlider,
    BeVenueAbout,
    BeVenueReviews,
    BeVenueTeam,
    BeVenueServices,
    BeVenueHeader, BeVenueNavbar, MainWrapper, BeHeader, BePromptUnauthorizedUser, BeModalPrompts},
  data() {
    return {
      iconHeart,
      idUnauthorizedModal: 'unauthorized-favorite-modal',
      isLoading: false,
      isLoadingContent: false,
      scrollRef: null,
      idFavoriteReserve: null,
      offsetTop: 0
    }
  },
  computed: {
    venue() {
      return this.$store.getters.venue
    },
    venueGallery() {
      return this.$store.getters.venue && this.$store.getters.venue.galleries ? this.$store.getters.venue.galleries : []
    },
    venueReviewsLength() {
      return this.$store.getters.venueReviews ? this.$store.getters.venueReviews.total : 0
    },
    popularServices() {
      let services = this.$store.getters.venueServices
      return services.length > 0 ? services[0].services : []
    },
    employees() {
      return this.$store.getters.venueEmployees
    },
    scrollTop() {
      return this.$store.getters.scrollTop
    },
    backRoute() {
      return this.$store.getters.followingName
    },
    isBasket() {
      return this.$store.getters.basket.length > 0
    }
  },
  mounted() {
    if(!this.venue) {
      this.initializeVenue(this.$route.params.id)
    } else {
      if(this.venue.id !== Number(this.$route.params.id)) {
        this.initializeVenue(this.$route.params.id)
        this.$store.commit('cleanBasket', true)
      }
    }

    // Clean Installed Employee
    this.$store.commit('setEmployeeToBook', null)

    const self = this;
    EventBus.$on('repeatRequest', function (payload) {
      if(payload.isRepeatRequest) {
        if(payload.config.url.split('/')[1] === 'store-favorite') {
          self.addFavorite(self.idFavoriteReserve);
        } else if(payload.config.url.split('/')[1] === 'delete-favorite') {
          self.removeFavorite(self.idFavoriteReserve);
        }
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    // Scroll to section
    setScrollRef(ref) {
      this.scrollRef = ref
    },
    scrollIntoView(event) {
      event.preventDefault()
      const href = event.target.getAttribute('href')
      const el = href ? document.querySelector(href) : null
      if (el) {
        this.scrollRef.scrollTop = el.offsetTop - 115
      }
    },
    loader(value) {
      this.isLoading = value
    },
    loaderContent(value) {
      this.isLoadingContent = value
    },

    bookNow() {
      this.$router.push(`/venues/${this.$route.params.id}/services`)
    },

    // Favorite Methods
    async addFavorite(id) {
      this.idFavoriteReserve = id
      if (!this.$store.getters.isAuth) {
        this.$store.commit('setFollowingPath', this.$route.path);
        this.$bvModal.show(this.idUnauthorizedModal)
      } else {
        this.venue.isFavorite = true
        await user.setFavorite(id)
      }
    },
    async removeFavorite(id) {
      this.idFavoriteReserve = id
      this.venue.isFavorite = false
      await user.deleteFavorite(id)
    },

    // Initialize Venue
    async initializeVenue(id) {
      await this.loader(true);
      await this.$store.dispatch('getVenueProfile', id)
      await this.loader(false);

      await this.loaderContent(true);
      await this.$store.dispatch('getVenueInfo', id)
      await this.$store.dispatch('getVenueReviews', {id})
      await this.$store.dispatch('getVenueServices', id)
      await this.$store.dispatch('getVenueEmployees', id)
      await this.loaderContent(false);
    },

    //TODO: Unnecessary Methods
    // Get Venues profile method
    async getVenueProfile(id) {
      try {
        const response = await venues.venueProfile(id);
        this.$store.commit('setVenue', response.data)
        this.isLoading = false
        this.isLoadingContent = true
      } catch (err) {
        this.isLoading = false
        console.log(err.message)
      }
    },
    // Get Venues info method
    async getVenueInfo(id) {
      try {
        const response = await venue.venueInfo(id);
        this.$store.commit('setVenueInfo', response.data)
      } catch (err) {
        this.isLoadingContent = false
        console.log(err.message)
      }
    },
    // Get Venues services method
    async getVenueServices(id) {
      try {
        const response = await venue.services(id);
        this.$store.commit('setVenueServices', response.data)
      } catch (err) {
        this.isLoadingContent = false
        console.log(err.message)
      }
    },
    // Get Venues employees method
    async getVenueEmployees(id) {
      try {
        const response = await venue.employees(id);
        this.$store.commit('setVenueEmployees', response.data)
        await this.$store.dispatch('getVenueTypesEmployee', id)
        this.isLoadingContent = false
      } catch (err) {
        this.isLoadingContent = false
        console.log(err.message)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.stickiy {
  background: #fff;
  position: sticky;
  top: 65px;
  z-index: 555;

}
</style>
