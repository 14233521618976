<template>
  <router-link
      v-if="to"
      :to="to"
      :class="`${variants[variant]} ${iconLeft ? 'be-link-icon-left' : ''}`"
      class="be-link">
    <i v-if="iconLeft" :class="iconLeft"/>
    <slot></slot>
  </router-link>
  <a
      v-else
      :href="href"
      @click.prevent="handlerClick"
      :class="`${variants[variant]} ${iconLeft ? 'be-link-icon-left' : ''}`"
      class="be-link">
    <i v-if="iconLeft" :class="iconLeft"/>
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "BeLink",
  props: {
    href: {
      type: String
    },
    to: {
      type: [String, Object],
      required: false,
      default: '',
    },
    variant: {
      type: String
    },
    iconLeft: {
      type: String
    }
  },
  data() {
    return {
      variants: {
        'primary': 'be-link--primary',
        'info': 'be-link--info'
      }
    }
  },
  methods: {
    handlerClick() {
      this.$emit('click')
    }
  }
}
</script>
