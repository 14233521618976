<template>
  <div class="be-app__welcome-screen">
    <img :src="IconBrand" alt="Belliata" class="be-app__welcome-screen__icon">
  </div>
</template>

<script>
import IconBrand from "../../assets/img/brand/belliata-icon.svg"

export default {
  name: "BeWelcomeScreen",
  data() {
    return {
      IconBrand
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/global/variables";

.be-app__welcome-screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 999;
  &__icon {
    display: inline-block;
    width: 80px;
    height: auto;
    object-fit: contain;
  }
}

</style>
