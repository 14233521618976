export default {
  state: {
    lang: '',
    privacyPolicy: {
      idModal: 'main-privacy-policy-modal',
      content: ''
    },
    confirmPhoneIdModal: 'update-phone-modal',
    fcm_token: 'ewtewtew',
    device: null,
    scrollTop: 0,
    timer: {
      status: false,
      countdown: 300
    }
  },
  getters: {
    appLanguage: (state) => {
      return state.lang
    },
    confirmPhoneIdModal: (state) => {
      return state.confirmPhoneIdModal
    },
    privacyPolicy: (state) => {
      return state.privacyPolicy
    },
    scrollTop: (state) => {
      return state.scrollTop
    },
    device: (state) => {
      return state.device
    },
    fcmToken: (state) => {
      return state.fcm_token;
    },
    timer: (state) => {
      return state.timer
    }
  },
  mutations: {
    setAppLanguage: (state, payload) => {
      state.lang = payload.lang
    },
    setPrivacyPolicy: (state, payload) => {
      state.privacyPolicy.content = payload
    },
    setScrollTop: (state, value) => {
      state.scrollTop = value
    },
    setDeviceInfo: (state, payload) => {
      state.device = payload
    },
    setFcmToken: (state, value) => {
      state.fcm_token = value;
    },

    startTimer(state, value) {
      state.timer.status = value;

      const stopCountdown = setInterval(() => {
        if (state.timer.status) {
          state.timer.countdown -= 1;
          if (state.timer.countdown < 0) {
            state.timer.countdown = 300;
            clearInterval(stopCountdown);
          }
        } else {
          state.timer.countdown = 300;
          clearInterval(stopCountdown);
        }
      }, 1000);
    },
  },
  actions: {}
}
