export default {
  state: {
    venuesReport: null,
    venues: [],
    search: {
      value: ''
    }
  },
  getters: {
    venues: (state) => {
      return state.venues
    },
    venuesReport: (state) => {
      return state.venuesReport
    },
    search: (state) => {
      return state.search.value
    },
  },
  mutations: {
    setVenues: (state, payload) => {
      state.venues = [...state.venues, ...payload]
    },
    updateVenues: (state, payload) => {
      state.venues = payload
    },
    setVenuesReport: (state, payload) => {
      state.venuesReport = payload
    },

    setSearch: (state, payload) => {
      state.search.value = payload.value
    }
  },
  actions: {

  }
}
