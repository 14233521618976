<template>
  <main-wrapper>
    <template #header>
      <be-header :back-to-route="`Venue`"/>
    </template>
    <div class="be-venue-employee">
      <div v-if="employee" class="be-venue-employee__card">
        <div class="be-venue-employee__card-avatar">
          <img v-if="employee.img" :src="employee.img" :alt="`${employee.first_name} ${employee.last_name}`">
          <span v-else class="letter">
            {{employee.first_name ? employee.first_name[0] : ''}}{{employee.last_name ? employee.last_name[0] : ''}}
          </span>
        </div>
        <div class="be-venue-employee__card-info">
          <span class="name">{{employee.first_name}} {{employee.last_name}}</span>
          <div class="d-flex align-items-center">
            <span class="position">{{employee.position}}</span>
<!--            <span class="rate">-->
<!--              <i class="icon-be-star-full"/>-->
<!--              5.0-->
<!--            </span>-->
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="text-center pt-5">
        <b-spinner class="be-spinner"/>
      </div>
      <div v-else>
        <div v-if="employeeDescription" class="be-venue-employee__description" v-html="employeeDescription"/>
        <div class="be-venue-employee__services">
          <be-service-accordion
              v-for="(item, index) in employeeServices"
              :key="item.idCollapse + index"
              :id="item.idCollapse"
              :title="item.group_title"
          >
            <be-card-list-service
                v-for="service in item.services"
                :key="service.id"
                :variant="service.pricing_type"
                :variants="service.variants"
                :id="service.id"
                :title="service.title"
                :employees="service.employees"
                :type="service.booking"
                :group-title="service.group_title"
                :is-description="!!service.description"
                @show-details="(id) => handlerShowDetails(id, item.services)"
            />
          </be-service-accordion>
        </div>
      </div>
    </div>

    <be-modal
        :id="serviceShowDetailsModalId"
        :title="$t('b2c_service_details')"
        :class-body="`pb-0`"
    >
      <be-content-service-details :data="serviceDetails" @hide-details="handlerHideDetails"/>
    </be-modal>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import BeServiceAccordion from "@/components/common/BeServiceAccordion";
import BeCardListService from "@/components/cards/BeCardListService";
import venue from "@/api/venue";
import BeModal from "@/components/BeModals/BeModal";
import BeContentServiceDetails from "@/views/Venues/_id/BeContentServiceDetails";

export default {
  name: "VenueEmployee",
  components: {BeContentServiceDetails, BeModal, BeCardListService, BeServiceAccordion, MainWrapper, BeHeader},
  data() {
    return {
      isLoading: false,
      serviceShowDetailsModalId: 'service-show-details-employee',
      serviceDetails: null,
      employeeDescription: ''
    }
  },
  computed: {
    employee() {
      const employeeId = this.$route.params.employee
      return this.$store.getters.venueEmployees.find(i => i.id === employeeId)
    },
    employeeServices() {
      let service = this.$store.getters.venueServices.filter(i => !i.is_package)

      service = service.map(i => {
        return {
          ...i,
          services: i.services.filter(k =>
              k.employees.filter(r => r.id === this.$route.params.employee).length !== 0
          )
        }
      })

      service = service.filter(i => i.services.length > 0)

      return service
    }
  },
  mounted() {
    if(!this.employee) {
      this.$router.push({name: 'Venue'})
      return;
    }

    this.initialEmployee()
    this.$store.commit('setEmployeeToBook', {id: this.$route.params.employee})
  },
  methods: {
    async initialEmployee() {
      this.isLoading = true
      const payload = {
        venueId: this.$route.params.id,
        employeeId: this.$route.params.employee
      }
      try {
        const response = await venue.employeeProfile(payload)
        this.employeeDescription = response.data.description
        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.isLoading = false
      }
    },
    handlerShowDetails(id, services) {
      const service = services.find(i => i.id === id)
      this.serviceDetails = {
        id: service.id,
        title: service.title,
        description: service.description,
        variants: service.variants,
        info: `${service.variants[0].duration} ${this.$t('minute_short')} - ${service.variants[service.variants.length - 1].duration} ${this.$t('minute_short')}`
      }
      this.$bvModal.show(this.serviceShowDetailsModalId)
    },
    handlerHideDetails() {
      this.$bvModal.hide(this.serviceShowDetailsModalId)
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-employee {
  &__card {
    display: flex;
    padding-bottom: 16px;

    &-avatar {
      min-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      background: $text-color-light;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .letter {
        font-size: 20px;
        text-transform: uppercase;
        color: $white;
        font-weight: normal;
      }
    }
    &-info {
      padding-left: 12px;
      padding-top: 3px;
      .name {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        color: $text-color;
        margin-bottom: 2px;
      }
      .position {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        color: $text-color-light;
        margin-right: 8px;
      }
      .rate {
        font-size: 16px;
        font-weight: 600;
        color: $text-color;
        display: flex;
        align-items: center;

        i {
          position: relative;
          top: -2px;
          font-size: 24px;
          color: $gold;
          margin-right: 4px;
        }
      }
    }
  }
  &__description {
    padding-bottom: 12px;
    p {
      font-size: 16px;
      font-weight: normal;
      color: $text-color;
      margin-bottom: 8px;
    }
  }
}

</style>
