export default {
  state: {
    payment_method: null,
    payment_variant: ''
  },
  getters: {
    getPaymentVariant: (state) => {
      return state.payment_variant
    },
    getPaymentMethod: (state) => {
      return state.payment_method
    },
  },
  mutations: {
    setPaymentVariant: (state, payload) => {
      state.payment_variant = payload.variant
    },
    setPaymentMethod: (state, payload) => {
      state.payment_method = payload
    },
  },
  actions: {}
}
