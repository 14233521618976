<template>
  <main-wrapper
      is-center-content
      is-pull-refresh
      @pull-reload="checkOnline"
  >
    <be-placeholder-content
        :icon="NotFound"
        :title="'No internet connection'"
    />
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BePlaceholderContent from "@/components/common/BePlaceholderContent";
import NotFound from "@/assets/img/icons/icon-offline.svg";
export default {
  name: "NoInternetConnection",
  components: {BePlaceholderContent, MainWrapper},
  data() {
    return {
      NotFound
    }
  },
  mounted() {
    this.checkOnline();
  },
  methods: {
    checkOnline() {
      console.log(43643)
      if(window.navigator.onLine) {
        this.$router.push({name: 'Home'})
      }
    }
  }
}
</script>

<style scoped>

</style>
