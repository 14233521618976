<template>
  <label
      :for="id"
      :class="`${!!error && 'be-checkbox--error'} ${isFilter ? 'be-checkbox-filter' : ''}`"
      class="be-checkbox"
  >
    <input
        :id="id"
        type="checkbox"
        :checked="checked"
        @input="$emit('input', $event.target.checked)"
    />
    <i class="be-checkbox__icon">
      <i class="icon-be-check"></i>
    </i>
    <span v-if="text" class="be-checkbox__text">{{text}}</span>
    <span v-if="isFilter && subTitle" class="be-checkbox__subtitle">{{subTitle}}</span>
  </label>
</template>

<script>
export default {
  name: "BeCheckbox",
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    checked: {
      type: Boolean
    },
    text: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    isFilter: {
      type: Boolean,
      default: false
    }
  }
}
</script>
