<template>
  <main-wrapper hide-footer>
    <template #header>
      <be-header
          :title="$t('b2c_data_preferences')"
          :back-to-route="'Profile'"
          :is-loading="isLoading"
      />
    </template>

    <div class="be-list-preferences">
      <be-switcher
          :title="$t('b2c_receive_marketing_email')"
          v-model="data.notif_email"
          @input="updateData"
          :checked="data.notif_email"/>
      <be-switcher
          v-if="profile.is_customer"
          :title="$t('b2c_receive_transactional_email')"
          v-model="data.notif_trans_email"
          @input="updateData"
          :checked="data.notif_trans_email"/>
      <be-switcher
          :title="$t('b2c_receive_marketing_sms')"
          v-model="data.notif_sms"
          @input="updateData"
          :checked="data.notif_sms"/>
      <be-switcher
          v-if="profile.is_customer"
          :title="$t('b2c_receive_transactional_sms')"
          v-model="data.notif_trans_sms"
          @input="updateData"
          :checked="data.notif_trans_sms"/>
      <be-switcher
          v-if="profile.is_customer"
          :title="$t('b2c_receive_transactional_push')"
          v-model="data.notif_trans_push"
          @input="updateData"
          :checked="data.notif_trans_push"/>
      <be-switcher
          v-if="profile.is_customer"
          :title="$t('b2c_receive_satisfaction_email')"
          v-model="data.nps_survey_email"
          @input="updateData"
          :checked="data.nps_survey_email"/>
      <be-switcher
          v-if="profile.is_customer"
          :title="$t('b2c_receive_satisfaction_sms')"
          v-model="data.nps_survey_sms"
          @input="updateData"
          :checked="data.nps_survey_sms"/>
      <be-switcher
          :title="$t('b2c_receive_survey_push')"
          v-model="data.nps_survey_push"
          @input="updateData"
          :checked="data.nps_survey_push"/>
      <be-switcher
          :title="$t('b2c_medical_data_consent')"
          v-model="data.medical_consent"
          @input="updateData"
          :checked="data.medical_consent"/>
    </div>
    <div class="pt-2">
      <be-link @click="$bvModal.show(idPrivacyModal)" variant="primary">{{ $t('b2c_privacy_policy') }}</be-link>
    </div>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import BeSwitcher from "@/components/common/BeSwitcher";
import BeLink from "@/components/common/BeLink";
import user from "@/api/user";
import EventBus from "@/utils/event-bus";

export default {
  name: "ProfilePreferences",
  components: {BeLink, BeSwitcher, MainWrapper, BeHeader},
  data() {
    return {
      isLoading: false,
      data: {
        notif_email: false,
        notif_sms: false,
        notif_trans_email: false,
        notif_trans_sms: false,
        notif_trans_push: false,
        medical_consent: false,
        nps_survey_email: false,
        nps_survey_sms: false,
        nps_survey_push: false
      }
    }
  },
  computed: {
    profile() {
      return this.$store.getters.userProfile
    },
    idPrivacyModal() {
      return this.$store.getters.privacyPolicy.idModal
    }
  },
  mounted() {
    this.setDefaultValues(this.profile)

    const self = this;
    EventBus.$on('repeatRequest', function (payload) {
      if(payload.isRepeatRequest) {
        self.updateData();
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    setDefaultValues(data) {
      this.data.notif_email = data.cust_market_emails > 0
      this.data.notif_sms = data.cust_market_sms > 0
      this.data.notif_trans_email = data.notif_trans_email ? data.notif_trans_email > 0 : false
      this.data.notif_trans_sms = data.notif_trans_sms ? data.notif_trans_sms > 0 : false
      this.data.notif_trans_push = data.notif_trans_push ? data.notif_trans_push > 0 : false
      this.data.medical_consent = data.cust_medical_consent > 0
      this.data.nps_survey_email = data.nps_survey_email ? data.nps_survey_email > 0 : false
      this.data.nps_survey_sms = data.nps_survey_sms ? data.nps_survey_sms > 0 : false
      this.data.nps_survey_push = data.nps_survey_push ? data.nps_survey_push > 0 : false
    },
    handlerPrivacyModal() {
      this.$bvModal.show(this.idPrivacyModal);
    },
    async updateData() {
      this.isLoading = true
      try {
        const response = await user.updateDataPreferences(this.data)
        this.$store.commit('updateDataPreferences', response.data)
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
