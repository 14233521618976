import Vue from 'vue'
import Vuex from 'vuex'
import app from "@/store/modules/app";
import user from "@/store/modules/user";
import venues from "@/store/modules/venues";
import venue from "@/store/modules/venue";
import payments from "@/store/modules/payments";
import notifications from "@/store/modules/notifications";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    user,
    payments,
    venues,
    venue,
    notifications
  }
})
