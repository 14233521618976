<template>
  <main-wrapper @scroll-load="loadToScroll">
    <template #header>
      <be-header :title="$t('b2c_reviews')" :is-loading="isLoading"/>
    </template>
    <div v-if="reviews" class="be-venue-reviews">
      <div class="d-flex">
        <div class="be-venue-reviews__total">
          <div class="be-venue-reviews__total-rating">
            <i :class="!Number(ratings.main) ? 'gray-star' : ''" class="icon-be-star-full"/>
            <span class="rating">{{ratings.main}}</span>
          </div>
          <div class="be-venue-reviews__total-counter">
            <span>
              {{reviews.total > 1 ? `${reviews.total} ${$t('b2c_reviews_lowercase')}` : `${reviews.total} ${$t('b2c_review_lowercase')}`}}
            </span>
          </div>
        </div>
      </div>
      <div class="be-venue-reviews__list">
        <div class="be-venue-reviews__list-item">
          <span class="label">{{ $t('b2c_atmosphere') }}:</span>
          <be-star-rating :rating="ratings.atmosphere"/>
        </div>
        <div class="be-venue-reviews__list-item">
          <span class="label">{{ $t('b2c_staff') }}:</span>
          <be-star-rating :rating="ratings.stylist"/>
        </div>
        <div class="be-venue-reviews__list-item">
          <span class="label">{{ $t('b2c_cleanliness') }}:</span>
          <be-star-rating :rating="ratings.cleanliness"/>
        </div>
        <div class="be-venue-reviews__list-item">
          <span class="label">{{ $t('b2c_value') }}:</span>
          <be-star-rating :rating="ratings.value"/>
        </div>
      </div>

      <div class="be-venue-reviews__filter pb-2">
        <be-select
          :label="$t('b2c_filter_reviews')"
          :id="'filter-reviews'"
          :selected="selectedService"
          :options="services"
          @change="handlerFilterByService"
        />
      </div>
      <div class="be-venue-reviews__filter pb-4">
        <select
            name="rating"
            class="be-form-control outline-none"
            :selected="selectedRating"
            @change="(e) => handlerFilterByRating(e.target.value)"
        >
          <option value="" disabled selected>{{ $t('b2c_filter_by_rating') }}</option>
          <option value="3">{{ $t('b2c_3_stars_or_more') }}</option>
          <option value="4">{{ $t('b2c_4_stars_or_more') }}</option>
          <option value="5">{{ $t('b2c_only_5_stars') }}</option>
          <option value="">{{ $t('b2c_show_all') }}</option>
        </select>
      </div>
      <be-alert
          position="left"
          type="warning"
          :title="$t('b2c_verifield_reviews')"
          :description="$t('b2c_written_by_our_customers')"
      />

      <div class="be-venue-reviews__items pt-4">
        <be-card-review
          v-for="item in reviews.data"
          :key="item.id"
          :name="item.full_name"
          :date="item.date_added"
          :rating="item.overall_rate"
          :content="item.review_text"
          :provided="item.employee_full_name"
          :treatment="item.service_title"
        />
      </div>
      <div v-if="reviews && reviews.lastPage > reviews.currentPage" class="text-center pt-2" :style="{height: '50px'}">
        <b-spinner class="be-spinner" v-if="isLoadingMore"/>
      </div>
    </div>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import BeStarRating from "@/components/common/BeStarRating";
import BeSelect from "@/components/common/BeSelect";
import BeAlert from "@/components/common/BeAlert";
import BeCardReview from "@/components/cards/BeCardReview";
export default {
  name: "VenueReviews",
  components: {BeCardReview, BeAlert, BeSelect, MainWrapper, BeHeader, BeStarRating},
  data() {
    return {
      isLoading: false,
      isLoadingMore: false,
      selectedRating: 0,
      selectedService: ''
    }
  },
  computed: {
    services() {
      let services = []
      this.$store.getters.venueServices.forEach(i => {
        if(!i.is_package) {
          services = [...services, ...i.services]
        }
      })

      services = services.map(i => {
        return {
          id: i.id,
          title: i.title,
          value: i.id
        }
      })

      return [{title: 'All services', value: ''}, ...services]
    },
    reviews() {
      return this.$store.getters.venueReviews
    },
    ratings() {
      const venue = this.$store.getters.venue

      return {
        main: venue.averageRating,
        atmosphere: venue.reviewByCategories.Atmosphere,
        cleanliness: venue.reviewByCategories.Cleanliness,
        stylist: venue.reviewByCategories.Stylist,
        value: venue.reviewByCategories.Value,
      }
    }
  },
  mounted() {
    if(!this.reviews) {
      this.$router.push({name: 'Venue'})
    }

    this.filterReviews()
  },
  methods: {
    loader(value) {
      this.isLoading = value
    },
    loaderMore(value) {
      this.isLoadingMore = value
    },
    handlerFilterByService(value) {
      this.selectedService = value
      this.filterReviews()
    },
    handlerFilterByRating(value) {
      this.selectedRating = value
      this.filterReviews()
    },

    async loadToScroll(isLoad) {
      if(this.reviews.lastPage > this.reviews.currentPage) {
        if(isLoad && !this.isLoadingMore) {
          await this.loaderMore(true)
          await this.$store.dispatch('getVenueReviews', {
            id: this.$route.params.id,
            page: this.reviews.currentPage + 1,
            service: this.selectedService,
            rating: this.selectedRating,
          })
          await this.loaderMore(false)
        }
      }
    },

    async filterReviews() {
      await this.loader(true)
      await this.$store.dispatch('getVenueReviews', {
        id: this.$route.params.id,
        page: 1,
        service: this.selectedService,
        rating: this.selectedRating,
      })
      await this.loader(false)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-reviews {
  padding-bottom: 24px;
  &__total {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
    &-rating {
      display: flex;
      align-items: center;

      i {
        display: inline-block;
        color: $gold;
        font-size: 30px;
        margin-right: 10px;

        &.gray-star {
          color: $border-color;
        }
      }

      .rating {
        font-size: 32px;
        line-height: 1;
        font-weight: 500;
        color: $text-color-dark;
        display: inline-block;
      }
    }
    &-counter {
      font-size: 14px;
      color: $text-color-light;
      font-weight: normal;
    }
  }
  &__list {
    padding-bottom: 8px;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .label {
        font-size: 16px;
        font-weight: 500;
        color: $text-color;
      }
    }
  }
}

</style>
