<template>
  <div class="be-venue-services">
    <h4 class="be-h4 fw-bold">{{ $t('b2c_popular_services') }}</h4>
    <div v-if="services.length > 0"  class="be-venue-services__list">
      <be-card-list-service
        v-for="service in services"
        :key="service.id"
        :variant="service.pricing_type"
        :variants="service.variants"
        :id="service.id"
        :title="service.title"
        :type="service.booking"
        :employees="service.employees"
        :group-title="service.group_title"
        :is-description="!!service.description"
        @show-details="handlerShowDetails"
      />
    </div>
    <be-placeholder-content
      v-else
      :title="$t('b2c_no_services_available')"
    />

    <be-link
        v-if="services.length > 0"
        :to="`/venues/${$route.params.id}/services`"
        variant="primary">{{ $t('b2c_all_services') }}</be-link>

    <be-modal
        :id="serviceShowDetailsModalId"
        :title="$t('b2c_service_details')"
        :class-body="`pb-0`"
    >
      <be-content-service-details :data="serviceDetails" @hide-details="handlerHideDetails"/>
    </be-modal>
  </div>
</template>

<script>
import BeLink from "@/components/common/BeLink";
import BeCardListService from "@/components/cards/BeCardListService";
import BeModal from "@/components/BeModals/BeModal";
import BeContentServiceDetails from "@/views/Venues/_id/BeContentServiceDetails";
import BePlaceholderContent from "@/components/common/BePlaceholderContent";
export default {
  name: "BeVenueServices",
  components: {BePlaceholderContent, BeCardListService, BeLink, BeModal, BeContentServiceDetails},
  props: {
    services: {
      type: Array
    }
  },
  data() {
    return {
      serviceDetails: null,
      serviceShowDetailsModalId: 'venue-service-details-modal',
    }
  },
  methods: {
    handlerShowDetails(id) {
      const service = this.services.find(i => i.id === id)
      this.serviceDetails = {
        id: service.id,
        title: service.title,
        description: service.description,
        image: service.service && service.service.image && service.service.image.length
          ? service.service.image[0] : '',
        variants: service.variants,
        info: `${service.variants[0].duration} ${this.$t('minute_short')} - ${service.variants[service.variants.length - 1].duration} ${this.$t('minute_short')}`
      }
      this.$bvModal.show(this.serviceShowDetailsModalId)
    },
    handlerHideDetails() {
      this.$bvModal.hide(this.serviceShowDetailsModalId)
    },
  }
}
</script>

<style scoped lang="scss">

.be-venue-services {
  padding-top: 12px;
  padding-bottom: 24px;

  &__list {
    padding-bottom: 12px;
  }
}

</style>
