<template>
  <div class="be-horizontal-calendar">
    <div class="be-horizontal-calendar__header">
      <span
          :class="property.addedWeek === 0 ? 'disabled' : ''"
          class="be-horizontal-calendar__header-arrow arrow-left"
          @click="prevWeek">
        <i class="icon-be-arrow-line-left"/>
      </span>
      <div @click="$emit('click-date')" class="be-horizontal-calendar__header-title">
        <span class="date">{{dateTitle(selectedDate)}} <i class="icon-be-arrow-right"/></span>
      </div>
      <span
          class="be-horizontal-calendar__header-arrow arrow-right"
          @click="nextWeek">
        <i class="icon-be-arrow-line-right"/>
      </span>
    </div>
    <div class="be-horizontal-calendar__body">
      <div class="be-horizontal-calendar__carousel">
        <div
            v-for="(day, index) in days"
            :key="index"
            class="be-horizontal-calendar__carousel-item">
          <div
              :class="
                day.isActive && day.isToday
                  ? 'selected'
                  : day.isToday
                  ? 'today'
                  : day.isActive
                  ? 'selected'
                  : day.disabled
                  ? 'disabled'
                  : ''
              "
              @click="selectDate(day.dateFormat)"
              class="be-horizontal-calendar__cell">
            <span class="day">
              {{
                day.day === 0
                    ? $t(`b2c_weekday_short_7`)
                    : $t(`b2c_weekday_short_${day.day}`)
              }}
            </span>
            <span class="number">{{ day.number }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeHorizontalCalendar",
  props: {
    selectedDate: {
      type: String
    },
    date: {
      type: String
    },
    minDate: {
      type: String
    }
  },
  data() {
    return {
      startOfWeek: 1,
      today: this.$moment(),
      month: this.$moment().format("MMM, YYYY"),
      property: {
        addedWeek: 0
      },
      days: []
    };
  },
  watch: {
    selectedDate: {
      handler(newSelectedDate) {
        if (newSelectedDate) {
          this.setDate(newSelectedDate);
          if (this.$moment(newSelectedDate).week() >= this.$moment(this.minDate).week()) {
            this.property.addedWeek =
                this.$moment(newSelectedDate).week() - this.$moment(this.minDate).week();
            this.getDays(
                this.$moment(newSelectedDate).week() - this.$moment(this.minDate).week()
            );
          }
        }
      }
    }
  },
  mounted() {
    this.getDays(this.$moment(this.selectedDate).week() - this.$moment(this.minDate).week());
  },
  methods: {
    getDays(week) {
      this.property.addedWeek = week;

      let weekStart = "";

      if (week || week > 0) {
        weekStart = this.$moment(this.minDate)
            .clone()
            .startOf("week")
            .add(week, "week");
      } else {
        weekStart = this.$moment(this.minDate).clone().startOf("week");
      }

      let days = [];

      let amount = Number(this.startOfWeek) === 6 ? 0 : 1;
      let begin = weekStart.isoWeekday(Number(this.startOfWeek));

      for (let i = 0; i < 7; i++) {
        begin.add(amount, "d");
      }

      for (let i = 0; i < 7; i++) {
        days.push({
          number: this.$moment(weekStart).format("D"),
          day: Number(this.$moment(weekStart).format("d")),
          dateFormat: this.$moment(weekStart).format(),
          isToday:
              this.$moment().format("YYYY-MM-DD") ===
              this.$moment(weekStart).format("YYYY-MM-DD"),
          isActive: this.date === this.$moment(weekStart).format("YYYY-MM-DD"),
          disabled: this.today.format() > this.$moment(weekStart).format()
        });
        begin.add(1, "d");
      }

      this.days = days;
    },
    prevWeek() {
      if (this.property.addedWeek > 0) {
        this.getDays(this.property.addedWeek - 1);
      }
    },
    nextWeek() {
      this.getDays(this.property.addedWeek + 1);
    },
    setDate(date) {
      this.days = this.days.map((item) => {
        return {
          ...item,
          isActive: item.dateFormat === this.$moment(date).format()
        };
      });
    },
    selectDate(date) {
      this.$emit("change", this.$moment(date).format('YYYY-MM-DD'));
    },
    dateTitle(date) {
      const monthArray = [
        this.$t("b2c_month_short1"),
        this.$t("b2c_month_short2"),
        this.$t("b2c_month_short3"),
        this.$t("b2c_month_short4"),
        this.$t("b2c_month_short5"),
        this.$t("b2c_month_short6"),
        this.$t("b2c_month_short7"),
        this.$t("b2c_month_short8"),
        this.$t("b2c_month_short9"),
        this.$t("b2c_month_short10"),
        this.$t("b2c_month_short11"),
        this.$t("b2c_month_short12")
      ];
      const currMonth = new Date(date).getMonth();
      return `${ new Date(date).getDate() } ${ monthArray[currMonth] } ${ new Date(date).getFullYear() }`;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-horizontal-calendar {
  margin-bottom: 28px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    &-arrow {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s;
      font-size: 18px;
      color: $text-color;
      cursor: pointer;

      &:active {
        background: $white-200;
      }

      &.disabled {
        color: $border-color;

        &:active {
          background: none;
        }
      }
    }
    &-title {
      .date {
        font-size: 16px;
        color: $text-color;
        font-weight: normal;
        cursor: pointer;
        i {
          position: relative;
          top: 1px;
          margin-left: 10px;
          display: inline-block;
          transform: rotate(90deg);
        }
      }
    }
  }

  &__body {
    max-width: 460px;
    width: 100%;
  }

  &__carousel {
    display: flex;
    justify-content: space-between;
  }

  &__cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;

    .day {
      font-size: 10px;
      font-weight: normal;
      color: $text-color;
      display: inline-block;
      margin-bottom: 2px;
    }

    .number {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($border-color, 0.45);
      color: $text-color;
      font-size: 16px;
      font-weight: normal;
    }

    &:active {
      .number {
        background: rgba($border-color, 0.65);
      }
    }

    &.disabled {
      pointer-events: none;
      .day {
        color: $border-color;
      }

      .number {
        background: rgba($border-color, 0.45);
        color: rgba($text-color-light, 0.45);
      }
    }

    &.today {
      .number {
        background: rgba($primary-color, .4);
        color: $text-color;
      }
    }

    &.selected {
      .number {
        background: $primary-color;
        color: $white;
      }
    }
  }

}

</style>
