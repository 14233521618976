<template>
  <div class="be-venue-slider">
    <swiper
        class="swiper be-venue-slider__swiper"
        :options="swiperOption"
    >
      <swiper-slide
          v-for="(item, index) in gallery"
          :key="index"
      >
        <div class="be-venue-slider__swiper-item">
          <div class="img-wrapper">
            <img
                :style="{transform: `translateY(${scrollTop/3}px)`}"
                :src="item"
                alt="Belliata Gallery">
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css'

export default {
  name: "BeVenueSlider",
  components: {Swiper, SwiperSlide},
  props: {
    gallery: {
      type: Array
    }
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    scrollTop() {
      return this.$store.getters.scrollTop
    },
  }
}
</script>

<style scoped lang="scss">

.be-venue-slider {
  margin-top: -72px;
  margin-left: -18px;
  margin-right: -18px;
  margin-bottom: 14px;
  &__swiper {
    &-item {
      .img-wrapper {
        width: 100%;
        padding-top: 80%;
        position: relative;
        overflow: hidden;
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

</style>
