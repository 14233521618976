<script>
export default {
  name: "BeCardNotification",
  props: {
    type: String,
    title: String,
    body: Object,
  }
}
</script>

<template>
  <div v-ripple class="be-notification" @click="$emit('click')">
    <div class="be-notification__icon">
      <span class="be-notification__icon-wrapper" :class="`be-notification__icon-wrapper_${type}`">
        <i v-if="type === 'appointment_reminder'" class="icon-be-notification"></i>
        <i v-if="type === 'new_appointment'" class="icon-be-new-appointment"></i>
        <i v-if="type === 'cancel_appointment'" class="icon-be-cancel-appointment"></i>
        <i v-if="type === 'no_show_appointment'" class="icon-be-empty-appointment"></i>
        <i v-if="type === 'edited_appointment'" class="icon-be-create-appointment"></i>
        <i v-if="type === 'new_review'" class="icon-be-star2"></i>
      </span>
    </div>
    <div class="be-notification__content">
      <div class="be-notification__title">
        <div>{{title}}</div>
        <span>{{body.date}}</span>
      </div>
      <div class="be-notification__caption">{{body.employee_full_name}}</div>
      <div v-if="body.service_group && body.service_title" class="be-notification__caption">
        {{body.service_group}} | {{body.service_title}}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-notification {
  border: 1px solid $border-color;
  border-radius: $border-radius-primary;
  padding: 12px;
  display: flex;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background: rgba($border-color, .3);
  }

  &__icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;

    &_new_appointment {
      background: rgba($text-color, .06);
      color: $primary-color;
    }
    &_cancel_appointment {
      background: rgba($text-color, .06);
      color: $error-color;
    }
    &_no_show_appointment {
      background: rgba($text-color, .06);
      color: $error-color;
    }
    &_appointment_reminder {
      background: rgba($text-color, .06);
      color: $orange;
    }
    &_edited_appointment {
      background: rgba($text-color, .06);
      color: $info-color;
    }
    &_new_review {
      background: rgba($text-color, .06);
      color: $review-color;
    }
  }

  &__content {
    flex: 1;
    padding-left: 12px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;

    span {
      color: $text-color-light;
      font-weight: normal;
      font-size: 10px;
      line-height: 22px;
      white-space: nowrap;
    }
  }
  &__caption {
    font-size: 13px;
    color: $text-color-light;
  }
}
</style>
