<template>
    <div class="be-service-accordion">
      <div
          v-ripple
          :class="visible ? 'not-collapsed' : 'collapsed'"
          :aria-expanded="visible"
          class="be-service-accordion__header"
          @click="visible = !visible"
      >
        <span class="be-service-accordion__header-title">{{title}}</span>
        <i class="icon-be-arrow-right"/>
      </div>
      <b-collapse :id="`${id}`" v-model="visible">
        <div class="be-service-accordion__body">
          <slot></slot>
        </div>
      </b-collapse>
    </div>
</template>

<script>
export default {
  name: "BeServiceAccordion",
  props: {
    id: {
      type: [String, Number]
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      visible: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-service-accordion {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6F9F9;
    margin-left: -15px;
    margin-right: -15px;
    padding: 22px 15px;
    border-bottom: 1px dotted $text-color;
    overflow: hidden;

    &-title {
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
      display: inline-block;
      padding-right: 20px;
    }
    i {
      display: inline-block;
      font-size: 22px;
      color: $text-color;
      transform: rotate(90deg);
      transition: .2s;
    }

    &.not-collapsed {
      i {
        transform: rotate(-90deg);
      }
    }
  }
  &__body {
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px;
  }
}

</style>
