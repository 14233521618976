<template>
  <div class="be-form">
    <form @submit.prevent="() => $emit('submit')">
      <be-input
          type="email"
          name="email"
          :label="$t('b2c_email')"
          :required="true"
          :value="data.form.email"
          @input="(value) => $emit('input-email', value)"
          :error="data.errors.email"
          @validate="() => $emit('validate-email')"
      />

      <div class="be-form-group-btn pt-2">
        <be-button
            :type="'submit'"
            :is-full="true"
            :is-outline="true"
            variant="primary"
            :is-loading="data.isLoading"
        >
          {{$t('b2c_send_instructions')}}
        </be-button>
      </div>
    </form>
  </div>
</template>

<script>
import BeInput from "@/components/common/BeInput";
import BeButton from "@/components/common/BeButton";

export default {
  name: "ProfileSettingsFormEmail",
  components: {BeInput, BeButton},
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
