<template>
  <main-wrapper transparent-footer>
    <template #header>
      <be-header :back-to-route="'Login'"/>
    </template>
    <template #footer>
      <div class="text-center">
        <p>
          <be-link class="fw-600" variant="primary" @click="$bvModal.show(idForgotPassword)">{{ $t('b2c_forgot_password') }}</be-link>
        </p>
        <p>{{ $t('b2c_first_time_here') }} <be-link to="/sign-up" variant="primary" class="fw-600">{{ $t('b2c_join_now') }}</be-link></p>
      </div>
    </template>

    <be-basket-info v-if="isBasket" is-basket is-timer/>

    <form @submit.prevent="login">
      <div class="be-auth-form">
        <be-input
            type="email"
            name="email"
            :label="$t('b2c_your_email')"
            :required="true"
            :value="form.email"
            @input="(value) => form.email = value"
            :error="errors.email"
            @validate="validate('email')"
        />
        <be-input
            type="password"
            name="password"
            :required="true"
            :label="$t('b2c_password')"
            :value="form.password"
            @input="(value) => form.password = value"
            :error="errors.password"
            @validate="validate('password')"
        />
        <div class="be-auth-form__submit">
          <be-button
              :is-full="true"
              :is-loading="isLoading"
              :type="'submit'"
              variant="primary"
          >
            {{$t('b2c_login')}}
          </be-button>
          <span class="or-text">{{ $t('b2c_or') }}</span>
          <be-google-auth-button>{{ $t('b2c_login_with_google') }}</be-google-auth-button>
          <be-apple-auth-button v-if="device && device.platform === 'ios'">{{ $t('b2c_login_with_apple') }}</be-apple-auth-button>
        </div>
      </div>
    </form>

    <be-modal :id="idForgotPassword" :title="$t('b2c_forgot_password')">
      <forgot-password-form/>
    </be-modal>
  </main-wrapper>
</template>

<script>
import BeLink from "../../components/common/BeLink";
import BeButton from "../../components/common/BeButton";
import BeBasketInfo from "../../components/common/BeBasketInfo";
import BeInput from "../../components/common/BeInput";
import MainWrapper from "@/components/common/MainWrapper";
import {object, string} from "yup";
import auth from "@/api/auth";
import {mapMutations} from "vuex";
import {http, httpBooking} from "@/axios";
import BeHeader from "@/components/BeHeader";
import BeModal from "@/components/BeModals/BeModal";
import ForgotPasswordForm from "@/views/Authorization/ForgotPasswordForm";
import BeGoogleAuthButton from "@/components/common/BeGoogleAuthButton";
import BeAppleAuthButton from "@/components/common/BeAppleAuthButton";
import user from "@/api/user";

export default {
  name: "AuthorizationView",
  components: {
    BeAppleAuthButton, BeBasketInfo,
    BeGoogleAuthButton, ForgotPasswordForm, BeModal, MainWrapper, BeInput, BeLink, BeButton, BeHeader},
  data() {
    return {
      isLoading: false,
      idForgotPassword: 'forgot-password-modal',
      form: {
        email: '',
        password: ''
      },
      errors: {
        email: '',
        password: ''
      },
    }
  },
  computed: {
    isBasket() {
      return this.$store.getters.savedBasket.length > 0
    },
    device() {
      return this.$store.getters.device
    },
    fcmToken() {
      return this.$store.getters.fcmToken
    },
    confirmPhoneIdModal() {
      return this.$store.getters.confirmPhoneIdModal
    },
    RegistrationFormSchema() {
      const params = {
        email: string()
            .email(this.$t("b2c_email_must_be_valid"))
            .required(this.$t("b2c_this_field_is_required")),
        password: string()
            .required(this.$t("b2c_this_field_is_required"))
            .min(8, this.$t("b2c_password_must_be_at_least_characters_long", { num: 8 }))
      };

      return object().shape(params);
    },
    emailToResetPassword() {
      return this.$store.getters.emailToResetPassword
    },
  },
  mounted() {
    if(this.emailToResetPassword) {
      this.$bvModal.show(this.idForgotPassword)
    }
  },
  methods: {
    ...mapMutations({
      authorizeUser: 'authorizeUser'
    }),
    async userProfile() {
      const response = await user.profile()
      this.$store.commit('setUserProfile', response.data)

      this.isLoading = false

      const isProfilePhone = !!response.data.phone

      if(!isProfilePhone) {
        this.$bvModal.show(this.confirmPhoneIdModal);
      }
    },
    redirect(route) {
      this.$router.push({name: route})
    },
    validate(field) {
      this.RegistrationFormSchema.validateAt(field, this.form)
          .then(() => {
            this.errors[field] = "";
          })
          .catch((err) => {
            this.errors[err.path] = err.message;
          });
    },
    login() {
      this.isLoading = true
      this.errors.email = ''
      this.RegistrationFormSchema.validate(this.form, {abortEarly: false})
          .then(async () => {
            try {
              const response = await auth.login({
                ...this.form,
                app_details: {
                  fcm_token: this.fcmToken,
                  platform: this.device ? this.device.platform : '',
                  model: this.device ? this.device.model : ''
                }
              })

              localStorage.setItem("access_token", response.data.access_token);
              localStorage.setItem(
                  "expires_in",
                  Math.round(+new Date() / 1000 + response.data.expires_in)
              );
              http.defaults.headers.common = {
                ...http.defaults.headers.common,
                Authorization: `Bearer ${response.data.access_token}`,
              };
              httpBooking.defaults.headers.common = {
                ...httpBooking.defaults.headers.common,
                Authorization: `Bearer ${response.data.access_token}`
              };

              await this.authorizeUser();
              await this.userProfile();
              this.$store.dispatch('fetchNotifications');

              const followingPath = this.$store.getters.followingPath

              if(followingPath) {
                await this.$router.push(followingPath)
              } else {
                await this.redirect('Venues');
              }

            } catch (e) {
              this.isLoading = false
              if(e.response.status === 401) {
                this.errors.email = e.response.data.error
              }
              if(e.response.status === 500) {
                alert(`Error, something went wrong :( Status: ${e.response.status}`);
              }
            }
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.errors = {...this.errors, [error.path]: error.message};
            });
          });
    }
  }
}
</script>

<style scoped>

</style>
