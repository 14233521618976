<template>
  <main-wrapper sticky-footer>
    <template #header>
      <be-header
          :title="$t('b2c_select_staff')"
          back-to-route="VenueServices"
      />
    </template>
    <template #footer>
      <be-button
          v-if="basket.length !== 1 || (basket.length === 1 && basket[0].employee_name)"
          :disabled="!basket.every(i => i.employee_id !== null)"
          @click="redirectToSlots"
          is-full
          variant="primary">
        {{$t("b2c_choose_time")}}
      </be-button>
    </template>

    <div class="be-venue-staff">
      <div v-if="basket.length > 1" class="be-venue-staff__list">
        <be-wrapper-employee-package
            v-for="packageItem in basketPackages"
            :key="packageItem.id"
            :title="packageItem.title"
            @remove="removePackage(packageItem.id)"
        >
          <be-card-unknown-employee
              v-for="service in packageItem.services"
              :key="`${service.variant_id}-${packageItem.id}`"
              :id="service.variant_id"
              :service-title="`${service.service_title} ${service.variant_title ? `(${service.variant_title})` : ''}`"
              :group-title="service.group_title"
              :selected="service.employee_name ? service.employee_name : ''"
              hide-remove-btn
              @change="showEmployees(
                service.employees,
                service.variant_id,
                service.employee_name ? service.employee_id : -1
                )"
          />
        </be-wrapper-employee-package>
        <be-card-unknown-employee
            v-for="service in basket.filter(i => !i.package_id)"
            :key="service.variant_id"
            :id="service.variant_id"
            :service-title="`${service.service_title} ${service.variant_title ? `(${service.variant_title})` : ''}`"
            :group-title="service.group_title"
            :selected="service.employee_name ? service.employee_name : ''"
            @remove="removeService(service.variant_id)"
            @change="showEmployees(
                service.employees,
                service.variant_id,
                service.employee_name ? service.employee_id : -1
                )"
        />
      </div>
      <div v-else-if="basket.length === 1 && basket[0].employee_name" class="be-venue-staff__list">
        <be-card-unknown-employee
            v-for="service in basket"
            :key="service.variant_id"
            :id="service.variant_id"
            :service-title="`${service.service_title} ${service.variant_title ? `(${service.variant_title})` : ''}`"
            :group-title="service.group_title"
            :selected="service.employee_name ? service.employee_name : ''"
            @remove="removeService(service.variant_id)"
            @change="showEmployees(
                service.employees,
                service.variant_id,
                service.employee_name ? service.employee_id : -1
                )"
        />
      </div>
      <div v-else class="be-venue-staff__list">
        <be-card-employee
            :id="0"
            :variant-id="basket[0].variant_id"
            :name="$t('b2c_first_available_specialist')"
            :position="$t('b2c_maximum_availability')"
            :avatar="Avatar"
            :selected="basket[0].employee_name ? basket[0].employee_id === 0 : false"
            @select="selectEmployee"
        />
        <be-card-employee
          v-for="employee in basket[0].employees"
          :key="employee.id"
          :id="employee.id"
          :variant-id="basket[0].variant_id"
          :name="`${employee.first_name} ${employee.last_name}`"
          :position="employee.position"
          :avatar="employee.img"
          :selected="basket[0].employee_id ? basket[0].employee_id === employee.id : false"
          @select="selectEmployee"
        />
      </div>
    </div>

    <be-modal
      :id="idEmployeesModal"
      :title="$t('b2c_select_staff')"
    >
      <div class="be-venue-staff__list">
        <be-card-employee
            :id="0"
            :variant-id="selectedVariant"
            :name="$t('b2c_first_available_specialist')"
            :position="$t('b2c_maximum_availability')"
            :avatar="Avatar"
            :selected="selectedEmployee === 0"
            @select="selectEmployee"
        />
        <be-card-employee
            v-for="employee in selectedEmployees"
            :key="employee.id"
            :id="employee.id"
            :variant-id="selectedVariant"
            :name="`${employee.first_name} ${employee.last_name}`"
            :position="employee.position"
            :avatar="employee.img"
            :selected="selectedEmployee === employee.id"
            @select="selectEmployee"
        />
      </div>
    </be-modal>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import BeCardEmployee from "@/components/cards/BeCardEmployee";
import BeCardUnknownEmployee from "@/components/cards/BeCardUnknownEmployee";
import BeModal from "@/components/BeModals/BeModal";
import Avatar from "../../../../assets/img/icons/icon-avatar.svg"
import BeButton from "@/components/common/BeButton";
import BeWrapperEmployeePackage from "@/components/cards/BeWrapperEmployeePackage";
export default {
  name: "VenueStaff",
  components: {BeWrapperEmployeePackage, BeButton, BeModal, BeCardUnknownEmployee, BeCardEmployee, MainWrapper, BeHeader},
  data() {
    return {
      Avatar,
      idEmployeesModal: 'Employees-modal',
      selectedVariant: 0,
      selectedEmployee: 0,
      selectedEmployees: []
    }
  },
  watch: {
    basket: {
      handler: function (newBasket) {
        if(newBasket.length === 0) {
          this.$router.push(`/venues/${this.$route.params.id}/services`)
        }
      }
    }
  },
  computed: {
    basket() {
      return this.$store.getters.basket
    },
    basketPackages() {
      return this.$store.getters.basketPackages
    }
  },
  mounted() {
    if(this.basket.length === 0) {
      this.$router.push(`/venues/${this.$route.params.id}`)
    }

    // Clean Installed Employee
    this.$store.commit('setEmployeeToBook', null)
  },
  methods: {
    showEmployees(payload, id, employeeId) {
      this.selectedVariant = id
      this.selectedEmployee = employeeId
      this.selectedEmployees = payload
      this.$bvModal.show(this.idEmployeesModal)
    },
    selectEmployee(payload) {
      this.selectedEmployee = payload.employee_id
      this.$store.commit('selectEmployee', payload)

      if(this.basket.length > 1) {
        this.$bvModal.hide(this.idEmployeesModal)
      } else {
        this.redirectToSlots()
      }

    },
    removeService(id) {
      this.$store.commit('removeService', id)
    },
    removePackage(packageId) {
      this.$store.commit('removePackage', { id: packageId })
    },
    redirectToSlots() {
      this.$router.push(`/venues/${this.$route.params.id}/slots`)
    }
  }
}
</script>

<style scoped lang="scss">

.be-venue-staff {
  margin-top: -16px;
}

</style>
