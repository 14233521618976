import {http, httpBooking} from "@/axios";

const PRIVACY_POLICY_RESOURCE = '/get-privacy-policy';

const USER_PROFILE_RESOURCE = '/get-details';
const UPDATE_USER_DETAILS_RESOURCE = '/update-details';
const UPDATE_EMAIL_RESOURCE = '/update-email';
const UPDATE_PASSWORD_RESOURCE = '/update-password';
const UPDATE_DATA_PREFERENCES_RESOURCE = '/customer-data-preferences';
const REMOVE_PROFILE_RESOURCE = '/account/delete/request';

const FAVORITE_RESOURCE = '/get-favorites';
const SET_FAVORITE_RESOURCE = '/store-favorite';
const DELETE_FAVORITE_RESOURCE = '/delete-favorite';
const APPOINTMENTS_RESOURCE = '/get-appointments';
const CANCEL_APPOINTMENT_RESOURCE = '/cancel-appointment';
const LAST_VISIT_RESOURCE = '/last-visits';

export default {
    privacyPolicy: () => http.get(PRIVACY_POLICY_RESOURCE),

    profile: () => http.get(USER_PROFILE_RESOURCE),
    updateDetails: (data) => new Promise((resolve, reject) => {
        http.put(UPDATE_USER_DETAILS_RESOURCE, data)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    updateEmail: (data) => new Promise((resolve, reject) => {
        http.post(UPDATE_EMAIL_RESOURCE, data)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    updatePassword: (data) => new Promise((resolve, reject) => {
        http.post(UPDATE_PASSWORD_RESOURCE, data)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    updateDataPreferences: (data) => new Promise((resolve, reject) => {
        http.post(UPDATE_DATA_PREFERENCES_RESOURCE, data)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    removeProfile: () => http.post(REMOVE_PROFILE_RESOURCE, null),

    favorites: () => http.get(FAVORITE_RESOURCE),
    setFavorite: (id) => new Promise((resolve, reject) => {
        http.post(SET_FAVORITE_RESOURCE, {venue_id: id})
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    deleteFavorite: (id) => http.delete(`${DELETE_FAVORITE_RESOURCE}/${id}`),

    futureAppointments: (page) => new Promise((resolve, reject) => {
        http.get(`${APPOINTMENTS_RESOURCE}?moment=future&page=${page}`)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    pastAppointments: (page) => new Promise((resolve, reject) => {
        http.get(`${APPOINTMENTS_RESOURCE}?moment=past&page=${page}`)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    cancelAppointment: (payload) => new Promise((resolve, reject) => {
        httpBooking.post(`${CANCEL_APPOINTMENT_RESOURCE}/${payload.venueId}`, {id: payload.id})
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),

    lastVisit: () => http.get(`${LAST_VISIT_RESOURCE}`),

    UPDATE_USER_DETAILS_RESOURCE,
    UPDATE_EMAIL_RESOURCE,
    UPDATE_PASSWORD_RESOURCE,
    REMOVE_PROFILE_RESOURCE
};
