<template>
  <main-wrapper transparent-footer>
    <template #header>
      <be-header :back-to-route="'Home'" :is-back="!isBasket"/>
    </template>
    <template #footer>
      <div class="text-center">
        <p>{{ $t('b2c_first_time_here') }} <be-link to="/sign-up" variant="primary" class="fw-600">{{ $t('b2c_join_now') }}</be-link></p>
      </div>
    </template>

    <h1 class="be-h1">{{ $t('b2c_login') }}</h1>
    <be-basket-info v-if="isBasket" is-basket is-timer/>
    <div class="btn-group-vertical">
      <be-button
          :is-full="true"
          variant="primary"
          @click="$router.push({ path: '/authorization' });"
      >
        {{ $t('b2c_login_with_email') }}
      </be-button>
      <be-google-auth-button>{{ $t('b2c_login_with_google') }}</be-google-auth-button>
      <be-apple-auth-button v-if="device && device.platform === 'ios'">{{ $t('b2c_login_with_apple') }}</be-apple-auth-button>
    </div>
  </main-wrapper>
</template>

<script>
import BeLink from "../../components/common/BeLink";
import BeButton from "../../components/common/BeButton";
import BeHeader from "../../components/BeHeader";
import MainWrapper from "@/components/common/MainWrapper";
import BeGoogleAuthButton from "@/components/common/BeGoogleAuthButton";
import BeAppleAuthButton from "@/components/common/BeAppleAuthButton";
import BeBasketInfo from "@/components/common/BeBasketInfo";

export default {
  name: "LoginView",
  components: {BeBasketInfo, BeAppleAuthButton, BeGoogleAuthButton, MainWrapper, BeLink, BeButton, BeHeader},
  computed: {
    isBasket() {
      return this.$store.getters.savedBasket.length > 0
    },
    device() {
      return this.$store.getters.device
    }
  }
}
</script>
