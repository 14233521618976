<template>
  <main-wrapper hide-footer>
    <template #header>
      <be-header
          :title="$t('b2c_your_details')"
          :back-to-route="'Profile'"
      />
    </template>
    <template>
      <div class="be-profile-settings-form">
        <profile-settings-from-details
            :data="{isLoading: profile.isLoading, form: profile.form, errors: profile.errors}"
            @input-first-name="(val) => profile.form.firstName = val"
            @input-last-name="(val) => profile.form.lastName = val"
            @validate-first-name="validateDetails('firstName')"
            @validate-last-name="validateDetails('lastName')"
            @input-phone="handlerPhone"
            @submit="updateProfileDetails"
        />
      </div>
      <div class="be-profile-settings-form">
        <p class="be-profile-settings-form__general">{{ $t('b2c_your_new_email_must_be_different_email') }}</p>
        <profile-settings-form-email
            :data="{isLoading: email.isLoading, form: email.form, errors: email.errors}"
            @input-email="(val) => email.form.email = val"
            @validate-email="validateEmail('email')"
            @submit="updateEmail"
        />
      </div>
      <div class="be-profile-settings-form">
        <p class="be-profile-settings-form__general">{{ $t('b2c_your_new_password_must_be_different_password') }}</p>
        <profile-settings-form-password
            :data="{isLoading: password.isLoading, form: password.form, errors: password.errors}"
            @input-password="(val) => password.form.password = val"
            @input-new-password="(val) => password.form.newPassword = val"
            @input-confirm-password="(val) => password.form.confirmPassword = val"
            @validate-password="validatePassword('password')"
            @validate-new-password="validatePassword('newPassword')"
            @validate-confirm-password="validatePassword('confirmPassword')"
            @submit="updatePassword"
        />
      </div>
      <div v-if="device && device.platform === 'ios'" class="be-profile-settings-form">
        <template v-if="!user.deletion_request">
          <p class="be-profile-settings-form__general">{{ $t('b2c_delete_account_description') }}</p>
          <be-button
              variant="error"
              @click="toggleModalRemoveConfirmation"
          >{{ $t('b2c_delete_account_button') }}</be-button>
        </template>
        <template v-else>
          <h6 class="mb-3">{{ $t('b2c_account_delete_request_sent') }}</h6>
          <p class="be-profile-settings-form__general">{{ $t('b2c_your_account_will_be_delete_in_24_hours') }}</p>
          <p class="be-profile-settings-form__general">{{ $t('b2c_on_submission_of_compeleted_delete_account_send_email_to') }}
            <a href="mailto:support@zolmi.com" class="be-link be-link--primary">support@zolmi.com</a></p>
        </template>
      </div>

      <be-modal-prompts :id="idModalPrompts" @hide="clearRequestsMessage">
        <be-prompt
            :icon="request.status === 'warning' ? iconWarning : iconSuccess"
            :sub-title="request.message ? request.message : $t('b2c_your_details_have_been_updated')"
        />
        <template #buttons>
          <be-button @click="handlerHideModal" variant="primary">{{$t('b2c_ok')}}</be-button>
        </template>
      </be-modal-prompts>
      <be-modal-prompts :id="idModalRemoveConfirmation">
        <be-prompt
            :icon="iconWarning"
            :title="$t('b2c_are_you_sure')"
            :sub-title="$t('b2c_delete_this_account')"
        />
        <template #buttons>
          <be-button @click="toggleModalRemoveConfirmation(false)" variant="primary">{{$t('b2c_no')}}</be-button>
          <be-button @click="removeAccount" :is-loading="isRemoving" variant="error">{{$t('b2c_yes')}}</be-button>
        </template>
      </be-modal-prompts>
    </template>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import ProfileSettingsFromDetails from "@/views/Profile/ProfileSettingsFromDetails";
import {boolean, object, ref, string} from "yup";
import user from "@/api/user";
import ProfileSettingsFormEmail from "@/views/Profile/ProfileSettingsFormEmail";
import ProfileSettingsFormPassword from "@/views/Profile/ProfileSettingsFormPassword";
import BeModalPrompts from "@/components/BeModals/BeModalPrompts";
import BePrompt from "@/components/common/BePrompt";
import iconSuccess from "../../assets/img/icons/icon-success.svg"
import iconWarning from "../../assets/img/icons/icon-error.svg"
import BeButton from "@/components/common/BeButton";
import EventBus from "@/utils/event-bus";

export default {
  name: "profileSettings",
  components: {
    BeButton,
    BePrompt,
    BeModalPrompts,
    ProfileSettingsFormPassword, ProfileSettingsFormEmail, ProfileSettingsFromDetails, MainWrapper, BeHeader
  },
  data() {
    return {
      iconSuccess,
      iconWarning,
      idModalPrompts: 'modal-profiles-prompts',
      idModalRemoveConfirmation: 'modal-remove-profile-confirmation',
      isRemoving: false,
      profile: {
        isLoading: false,
        form: {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          phoneIsNotValid: true
        },
        errors: {
          firstName: '',
          lastName: '',
          phone: '',
          phoneIsNotValid: '',
        }
      },
      email: {
        isLoading: false,
        form: {
          email: ''
        },
        errors: {
          email: ''
        }
      },
      password: {
        isLoading: false,
        form: {
          password: '',
          newPassword: '',
          confirmPassword: '',
        },
        errors: {
          password: '',
          newPassword: '',
          confirmPassword: '',
        }
      },
      request: {
        status: '',
        message: ''
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.userProfile
    },
    device() {
      return this.$store.getters.device
    },
    ProfileDetailsSchema() {
      const params = {
        firstName: string().required(this.$t("tb2c_his_field_is_required")),
        lastName: string().required(this.$t("b2c_this_field_is_required")),
        phone: string().required(this.$t("b2c_this_field_is_required")),
        phoneIsNotValid: boolean().oneOf([false], this.$t("b2c_phone_is_not_valid"))
      };

      return object().shape(params);
    },
    EmailSchema() {
      const params = {
        email: string()
            .email(this.$t("b2c_email_must_be_valid"))
            .required(this.$t("b2c_this_field_is_required"))
      };

      return object().shape(params);
    },
    PasswordSchema() {
      const params = {
        password: string()
            .required(this.$t("b2c_this_field_is_required"))
            .min(
                8,
                this.$t("b2c_password_must_be_at_least_characters_long", {num: 8})
            ),
        newPassword: string()
            .required(this.$t("b2c_this_field_is_required"))
            .min(
                8,
                this.$t("b2c_password_must_be_at_least_characters_long", {num: 8})
            ),
        confirmPassword: string()
            .required(this.$t("b2c_this_field_is_required"))
            .oneOf(
                [ref("newPassword"), null],
                this.$t("b2c_both_passwords_must_match")
            )
      };

      return object().shape(params);
    },
  },
  mounted() {
    this.profile.form = {
      ...this.form,
      firstName: this.user.first_name,
      lastName: this.user.last_name,
      phone: this.user.phone
    }
    this.email.form.email = this.user.email

    const self = this;
    EventBus.$on('repeatRequest', function (payload) {
      if(payload.isRepeatRequest) {
        switch (payload.config.url) {
          case user.UPDATE_USER_DETAILS_RESOURCE:
            self.updateProfileDetails();
            break;

          case user.UPDATE_EMAIL_RESOURCE:
            self.updateEmail();
            break;

          case user.UPDATE_PASSWORD_RESOURCE:
            self.updatePassword();
            break;

          case user.REMOVE_PROFILE_RESOURCE:
            self.removeAccount();
            break;
        }
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    removeLoader(value) {
      this.isRemoving = value
    },
    clearRequestsMessage() {
      this.request.status = ''
      this.request.message = ''
    },
    setRequestMessage(status, message) {
      this.request.status = status
      this.request.message = message
      this.$bvModal.show(this.idModalPrompts)
    },
    handlerHideModal() {
      this.clearRequestsMessage()
      this.$bvModal.hide(this.idModalPrompts)
    },
    validateDetails(field) {
      this.ProfileDetailsSchema.validateAt(field, this.profile.form)
          .then(() => {
            this.profile.errors[field] = "";
          })
          .catch((err) => {
            this.profile.errors[err.path] = err.message;
          });
    },
    validateEmail(field) {
      this.EmailSchema.validateAt(field, this.email.form)
          .then(() => {
            this.email.errors[field] = "";
          })
          .catch((err) => {
            this.email.errors[err.path] = err.message;
          });
    },
    validatePassword(field) {
      this.PasswordSchema.validateAt(field, this.password.form)
          .then(() => {
            if (field === 'confirmPassword') {
              this.password.errors.newPassword = "";
              this.password.errors[field] = "";
            } else {
              this.password.errors[field] = "";
            }
          })
          .catch((err) => {
            if (err.path === 'confirmPassword') {
              this.password.errors.newPassword = " ";
              this.password.errors[err.path] = err.message;
            } else {
              this.password.errors[err.path] = err.message;
            }
          });
    },
    toggleModalRemoveConfirmation(value = true) {
      value ? this.$bvModal.show(this.idModalRemoveConfirmation) : this.$bvModal.hide(this.idModalRemoveConfirmation)
    },

    handlerPhone({obj, num}) {
      if (obj.valid) {
        this.profile.form.phone = obj.number
      }

      if (String(num).length > 4) {
        this.profile.form.phoneIsNotValid = !obj.valid;
        this.validateDetails("phoneIsNotValid");
      } else {
        this.profile.form.phoneIsNotValid = obj.valid;
        this.validateDetails("phoneIsNotValid");
      }
    },
    updateProfileDetails() {
      let data = {
        first_name: this.profile.form.firstName,
        last_name: this.profile.form.lastName,
        phone: this.profile.form.phone,
      }

      this.ProfileDetailsSchema.validate(this.profile.form, {abortEarly: false})
          .then(async () => {
            this.profile.isLoading = true
            try {
              const response = await user.updateDetails(data)
              await this.$store.commit('setUserProfile', response.data.data)
              this.$bvModal.show(this.idModalPrompts);
              this.profile.isLoading = false

            } catch (e) {
              this.profile.isLoading = false
              console.log(e.message)
            }
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.profile.errors = {...this.profile.errors, [error.path]: error.message};
            });
          });
    },
    updateEmail() {
      this.EmailSchema.validate(this.email.form, {abortEarly: false})
          .then(async () => {
            this.email.isLoading = true
            try {
              await user.updateEmail({email: this.email.form.email})
              await this.$bvModal.show(this.idModalPrompts);
              this.email.isLoading = false
            } catch (e) {
              this.email.isLoading = false
              this.setRequestMessage('warning', this.$t(e.response.data.email[0]))
            }
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.email.errors = {...this.email.errors, [error.path]: error.message};
            });
          });
    },
    updatePassword() {
      let data = {
        current_password: this.password.form.password,
        password: this.password.form.newPassword,
        password_confirmation: this.password.form.confirmPassword,
      }

      this.PasswordSchema.validate(this.password.form, {abortEarly: false})
          .then(async () => {
            this.password.isLoading = true
            try {
              await user.updatePassword(data)
              await this.$bvModal.show(this.idModalPrompts);
              this.password.form = {
                password: '',
                newPassword: '',
                confirmPassword: ''
              }

              this.password.isLoading = false

            } catch (e) {
              this.password.isLoading = false
              console.log(e.message)
            }
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.password.errors = {...this.password.errors, [error.path]: error.message};
            });
          });
    },
    async removeAccount() {
      await this.removeLoader(true);
      const response = await user.removeProfile();

      await this.$store.commit('updateDeletionRequest', {date: new Date()})
      await this.toggleModalRemoveConfirmation(false);
      if(response.data.error) {
        await this.setRequestMessage('warning', response.data.message)
      } else {
        this.setRequestMessage('success', response.data.message)
      }
      await this.removeLoader(false);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-profile-settings-form {
  border-bottom: 1px solid $border-color;
  padding-bottom: 24px;
  margin-bottom: 20px;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;

  &:last-child {
    border: none;
  }

  &__general {
    font-size: 16px;
    font-weight: normal;
    color: $text-color-light;
    margin-bottom: 18px;
    line-height: 1.4;
  }
}

</style>
