<template>
  <div class="be-form">
    <form @submit.prevent="() => $emit('submit')">
      <be-input
          type="text"
          name="text"
          :label="$t('b2c_first_name')"
          :required="true"
          :value="data.form.firstName"
          @input="(value) => $emit('input-first-name', value)"
          :error="data.errors.firstName"
          @validate="() => $emit('validate-first-name')"
      />
      <be-input
          type="text"
          name="text"
          :label="$t('b2c_last_name')"
          :required="true"
          :value="data.form.lastName"
          @input="(value) => $emit('input-last-name', value)"
          :error="data.errors.lastName"
          @validate="() => $emit('validate-last-name')"
      />
      <be-input-phone
          :label="$t('b2c_phone')"
          :required="true"
          :value="data.form.phone"
          :error="data.errors.phone || data.errors.phoneIsNotValid"
          @input="(payload) => $emit('input-phone', payload)"
          @validate="() => $emit('validate-phone')"
      />

      <div class="be-form-group-btn pt-2">
        <be-button
            :type="'submit'"
            :is-full="true"
            :is-outline="true"
            variant="primary"
            :is-loading="data.isLoading"
        >
          {{$t('b2c_save')}}
        </be-button>
      </div>
    </form>
  </div>
</template>

<script>
import BeInput from "@/components/common/BeInput";
import BeInputPhone from "@/components/common/BeInputPhone";
import BeButton from "@/components/common/BeButton";

export default {
  name: "ProfileSettingsFromDetails",
  components: {BeInput, BeInputPhone, BeButton},
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style scoped lang="scss">

</style>
