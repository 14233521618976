<template>
  <main-wrapper  hide-footer>
    <template #header>
      <be-header
          :title="$t('b2c_welcome_back')"
          :isBack="false"
          :is-loading="isLoading && isLoaded"
          class="pt"
      >
        <template #tools>
          <be-notifications-widget class="ml-4"/>
        </template>
      </be-header>
    </template>

    <div class="be-appointment">
      <div class="be-appointment__group">
        <h4 class="be-h4">{{ $t('b2c_upcoming_appointment') }}</h4>

        <be-card-appointment-placeholder v-if="isLoading && !isLoaded" is-status/>
        <div v-else>
          <be-card-appointment
              v-if="futureAppointments.services.length > 0 || futureAppointments.packages.length > 0"
              :appointments="futureAppointments.services"
              :packages="futureAppointments.packages"
              @cancel="handlerCancelAppointment"
              :payment-details="futureAppointments.appointmentInfo.payment_details"
              :deposit="futureAppointments.appointmentInfo.deposit"
              :refund="futureAppointments.appointmentInfo.refund"
              :retained="futureAppointments.appointmentInfo.retained"
              :receipt-link="futureAppointments.appointmentInfo.receiptLink"
              type="future"
          />
          <div v-else class="be-text-placeholder text-center mb-5">{{ $t('b2c_you_have_no_upcoming_appointment') }}</div>
        </div>
      </div>

      <div class="be-appointment__group">
        <h4 class="be-h4">{{ $t('b2c_past_appointment') }}</h4>

        <be-card-appointment-placeholder v-if="isLoading && !isLoaded" is-button/>
        <div v-else>
          <be-card-appointment
              v-if="pastAppointments.services.length > 0 || pastAppointments.packages.length > 0"
              :appointments="pastAppointments.services"
              :packages="pastAppointments.packages"
              :payment-details="pastAppointments.appointmentInfo.payment_details"
              :deposit="pastAppointments.appointmentInfo.deposit"
              :refund="pastAppointments.appointmentInfo.refund"
              :retained="pastAppointments.appointmentInfo.retained"
              :receipt-link="pastAppointments.appointmentInfo.receiptLink"
              is-re-book
              type="past"
          />
          <div v-else class="be-text-placeholder text-center mb-5">{{ $t('b2c_you_have_no_past_appointment') }}</div>
        </div>
      </div>
    </div>

    <be-modal-prompts
        :id="idModalConfirmCancel"
    >
      <be-prompt
          :icon="iconError"
          :title="$t('b2c_are_you_sure_you_want_to_cancel_the_booking')"
      />
      <template #buttons>
        <be-button @click="$bvModal.hide(idModalConfirmCancel)" variant="primary">{{ $t('b2c_no') }}</be-button>
        <be-button
            @click="confirmAppointmentCancellation"
            :is-loading="isCancelingAppointment"
            variant="error">{{ $t('b2c_yes') }}</be-button>
      </template>
    </be-modal-prompts>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import user from "@/api/user";
import BeCardAppointment from "@/components/cards/BeCardAppointment";
import BeModalPrompts from "@/components/BeModals/BeModalPrompts";
import BePrompt from "@/components/common/BePrompt";
import BeButton from "@/components/common/BeButton";
import iconError from "../../assets/img/icons/icon-error.svg"
import BeCardAppointmentPlaceholder from "@/components/cards/BeCardAppointmentPlaceholder";
import EventBus from "@/utils/event-bus";
import BeNotificationsWidget from "@/widgets/BeNotificationsWidget.vue";

export default {
  name: "AppointmentView",
  components: {
    BeNotificationsWidget,
    BeCardAppointmentPlaceholder,
    BeButton, BePrompt, BeModalPrompts, BeCardAppointment, MainWrapper, BeHeader},
  data() {
    return {
      iconError,
      isLoading: false,
      idModalConfirmCancel: 'cancel-confirm-modal-second',
      isCancelingAppointment: false,
      appointmentCancellationData: null
    }
  },
  computed: {
    isLoaded() {
      return this.$store.getters.lastVisitIsLoaded
    },
    futureAppointments() {
      const data = this.$store.getters.lastVisit.future
      return this.buildAppointment(data)
    },
    pastAppointments() {
      const data = this.$store.getters.lastVisit.past
      return this.buildAppointment(data)
    },
  },
  created() {
    this.initAppointments()
  },
  mounted() {
    const self = this;
    EventBus.$on('repeatRequest', function (payload) {
      if(payload.isRepeatRequest) {
        self.initAppointments();
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    undoLoader(value) {
      this.isCancelingAppointment = value
    },
    buildAppointment(data) {
      const packages = [...new Set(data.map(i => i.package_id))].map(packageId => {
        if(packageId) {
          const services = data.filter(i => i.package_id === packageId);
          const service = services[0];

          return {
            id: packageId,
            title: service.package_title,
            total_price: service.total_price,
            total_duration: service.total_duration,
            payment_details: service.charge_info,
            deposit: service.deposit,
            refund: service.refund,
            retained: service.retained,
            receiptLink: service.receiptLink,
            services: [...services]
          }
        }
      }).filter(i => i)
      const services = data.filter(i => !i.package_id)

      let appointmentInfo = null

      if(services.length) {
        appointmentInfo = {
          payment_details: services[0].charge_info ? services[0].charge_info.payment_details : null,
          deposit: services[0].deposit,
          refund: services[0].refund,
          retained: services[0].retained,
          receiptLink: services[0].receiptLink,
        }
      } else if(packages.length) {
        appointmentInfo = {
          payment_details: packages[0].charge_info ? packages[0].charge_info.payment_details : null,
          deposit: packages[0].deposit,
          refund: packages[0].refund,
          retained: packages[0].retained,
          receiptLink: packages[0].receiptLink,
        }
      }

      return {packages, services, appointmentInfo}
    },
    handlerCancelAppointment(payload) {
      this.$bvModal.show(this.idModalConfirmCancel)
      this.appointmentCancellationData = payload
    },
    async initAppointments() {
      this.isLoading = true
      await this.getAppointments()
    },
    async getAppointments() {
      const response = await user.lastVisit()
      this.$store.commit('setLastVisit', response.data)
      this.isLoading = false
    },
    async confirmAppointmentCancellation() {
      await this.undoLoader(true)
      await this.$store.dispatch('cancelAppointment', this.appointmentCancellationData)
      await this.initAppointments()
      await this.$bvModal.hide(this.idModalConfirmCancel)
      await this.undoLoader(false)
    }
  }
}
</script>

<style scoped lang="scss">
.pt {
  padding-top: .45em;
}
.ml-4 {
  margin-left: .8em;
}
.be-appointment {
  &__group {
    margin-bottom: 15px;
  }
}

</style>
