<template>
  <div class="be-placeholder-content">
    <div v-if="icon" class="be-placeholder-content__icon">
      <img :src="icon" alt="Placeholder icon"/>
    </div>
    <div v-if="title" class="be-placeholder-content__title">
      <h4 v-html="title"></h4>
    </div>
    <div v-if="titleDark" class="be-placeholder-content__title-dark">
      <h4 v-html="titleDark"></h4>
    </div>
    <div v-if="description" class="be-placeholder-content__description">
      <p v-html="description"></p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BePlaceholderContent",
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    },
    titleDark: {
      type: String
    },
    description: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-placeholder-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 35px 0;

  &__icon {
    padding-bottom: 20px;
    img {
      display: inline-block;
      width: 44px;
      height: auto;
      object-fit: contain;
    }
  }
  &__title {
    h4 {
      font-size: 18px;
      font-weight: 500;
      color: $text-color-light;
      margin: 0;
    }
  }
  &__title-dark {
    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5;
      color: $text-color;
      margin: 0;
    }
  }
  &__description {
    padding-top: 12px;
    p {
      font-size: 16px;
      font-weight: normal;
      color: $text-color-light;
      margin: 0;
    }
  }
}

</style>
