<template>
  <div
      class="be-form-group"
      :class="!!error && 'be-form-error'"
  >
    <label
        v-if="label"
        for="phone"
        class="be-form-label"
    >
      {{label}}
      <span v-if="required" class="required">*</span>
    </label>
    <vue-tel-input
        id="phone"
        name="phone"
        :disabled="disabled"
        :required="required"
        :value="value"
        :input-options="{ placeholder: placeholder }"
        class="be-form-control"
        :class="!!error && !!isKeyPress && 'be-form-control--error'"
        @input="onInput"
        @blur="onBlur"
        @keypress="!!error && $emit('validate')"
    />
    <p v-if="!!error" class="be-form-control--text-error">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BeInputPhone",
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    passwordSwitcher: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isKeyPress: false
    };
  },
  methods: {
    onInput(_number, obj) {
      this.$emit("input", {obj, num: _number});

      if (this.isKeyPress) {
        this.isKeyPress = false;
      }
    },
    onBlur() {
      this.isKeyPress = true;
      this.$emit("validate");
    }
  }
};
</script>
