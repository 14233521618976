import {removeAuthData} from "@/utils/common";
import user from "@/api/user";
import router from "@/router";

export default {
  state: {
    activeAppointmentId: 0,
    followingRoute: {
      path: '',
      name: ''
    },
    emailToResetPassword: '',
    user: {
      loggedIn: false,
      data: null,
      favorites: []
    },
    futureAppointments: {
      page: 1,
      lastPage: 0,
      total: 0,
      data: []
    },
    pastAppointments: {
      page: 1,
      lastPage: 0,
      total: 0,
      data: []
    },

    lastVisit: {
      future: [],
      last: []
    },
    lastVisitIsLoaded: false
  },
  getters: {
    activeAppointmentId: (state) => {
      return state.activeAppointmentId;
    },
    isAuth: (state) => {
      return state.user.loggedIn
    },
    userProfile: (state) => {
      return state.user.data
    },
    favoritesSalon: (state) => {
      return state.user.favorites
    },
    followingPath: (state) => {
      return state.followingRoute.path
    },
    followingName: (state) => {
      return state.followingRoute.name
    },
    emailToResetPassword: (state) => {
      return state.emailToResetPassword
    },

    futureAppointments: (state) => {
      return state.futureAppointments
    },
    pastAppointments: (state) => {
      return state.pastAppointments
    },
    lastVisit: (state) => {
      return state.lastVisit
    },
    lastVisitIsLoaded: (state) => {
      return state.lastVisitIsLoaded
    }
  },
  mutations: {
    setActiveAppointmentId: (state, id) => {
      state.activeAppointmentId = id

      setTimeout(() => {
        state.activeAppointmentId = 0
      }, 20000)
    },

    authorizeUser: (state) => {
      state.user.loggedIn = true
    },
    logOutUser: (state) => {
      state.user.loggedIn = false
    },

    setUserProfile: (state, payload) => {
      state.user.data = {...state.user.data, ...payload}
    },
    clearUserProfile: (state) => {
      state.user.data = null
    },
    updateDataPreferences: (state, payload) => {
      state.user.data = {...state.user.data, ...{
          cust_market_emails: payload.cust_market_emails ? 1 : 0,
          cust_market_sms: payload.cust_market_sms ? 1 : 0,
          cust_medical_consent: payload.cust_medical_consent ? 1 : 0,
          is_customer: payload.is_customer,
          notif_trans_email: payload.notif_trans_email ? 1 : 0,
          notif_trans_sms: payload.notif_trans_sms ? 1 : 0,
          nps_survey_email: payload.nps_survey_email ? 1 : 0
        }}
    },
    setFavoritesSalon: (state, payload) => {
        state.user.favorites = payload
    },
    removeFavoriteSalon: (state, id) => {
      state.user.favorites = state.user.favorites.filter(i => i.id !== id)
    },
    setFollowingPath: (state, path) => {
      state.followingRoute.path = path
    },
    setFollowingName: (state, name) => {
      state.followingRoute.name = name
    },
    setEmailToResetPassword: (state, email) => {
      state.emailToResetPassword = email
    },

    setFutureAppointments: (state, payload) => {
      const appointments =  payload.data.map(i => ({...i, id: i.data[0].appt_group_id}))

      if(payload.page > 1) {
        state.futureAppointments = {
          ...payload,
          data: [...state.futureAppointments.data, ...appointments]
        }
      } else {
        state.futureAppointments = {
          ...payload,
          data: appointments
        }
      }
    },
    setPastAppointments: (state, payload) => {
      const appointments =  payload.data.map(i => ({...i, id: i.data[0].appt_group_id}))

      if(payload.page > 1) {
        state.pastAppointments = {
          ...payload,
          data: [...state.pastAppointments.data, ...appointments]
        }
      } else {
        state.pastAppointments = {
          ...payload,
          data: appointments
        }
      }
    },
    cancelAppointment: (state, payload) => {
      state.futureAppointments.data = state.futureAppointments.data.map(i => {
        return {
          ...i,
          data: i.data.map(k => {
            return {
              ...k,
              status: payload.id === k.appt_group_id ? payload.status : k.status,
              cancel_possibility: payload.id === k.appt_group_id ? false : k.cancel_possibility
            }
          })
        }
      })
    },

    updateDeletionRequest: (state, {date}) => {
      state.user.data.deletion_request = date
    },

    setLastVisit: (state, payload) => {
      state.lastVisitIsLoaded = true
      state.lastVisit = payload
    }
  },
  actions: {
    async userProfile({commit}) {
      try {
        const response = await user.profile()
        commit('setUserProfile', response.data[0])
      } catch (e) {
        console.log(e.message)
      }
    },
    async logout({commit}, payload) {
      await commit('logOutUser');
      await commit('clearUserProfile');
      await commit('setFollowingPath', '');
      await commit('setPaymentMethod', null);
      await removeAuthData();

      if(payload && payload.type === 'Token is Invalid') {
        await router.push('/authorization');
      }
    },
    async cancelAppointment({commit}, payload) {
      try {
        const response = await user.cancelAppointment(payload)

        commit('cancelAppointment', {
          id: payload.id,
          status: response.data.status
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
