<template>
  <div class="be-venue-about">
    <div class="be-venue-about__section">
      <h4 class="be-h4 fw-bold">{{ $t('b2c_about') }}</h4>
      <div class="be-paragraph" v-html="data.about"/>
    </div>
    <div class="be-venue-about__section">
      <h4 class="be-h4 fw-bold">{{ $t('b2c_opening_hours') }}</h4>
      <ul class="be-venue-about__schedule-table">
        <li
            v-for="(item, index) in week"
            :key="item + index"
            :class="data.workingHours[index].status > 0 ? 'open' : ''"
            class="be-venue-about__schedule-table__item">
          <span class="day">{{data.workingHours[index].weekday}}</span>

          <span v-if="data.workingHours[index].status > 0" class="time">
                      {{data.workingHours[index].start}} - {{data.workingHours[index].end}}
                    </span>
          <span v-else class="closed">{{ $t('b2c_closed') }}</span>
        </li>
      </ul>
    </div>
    <div v-if="data.location.lat && data.location.lng" class="be-venue-about__section">
      <div id="addressVenue" class="address-anchor"></div>
      <h4 class="be-h4 fw-bold">{{ $t('b2c_information') }}</h4>
      <leaflet :options="options" :markers="markers"></leaflet>
    </div>
    <div v-if="data.venueInformation.address" class="be-venue-about__section">
      <h4 class="be-h4 fw-bold">{{data.venueInformation.title}}</h4>
      <address class="be-paragraph">{{data.venueInformation.address}}</address>
    </div>
    <div v-if="data.venueInformation.parking" class="be-venue-about__section">
      <h4 class="be-h4 fw-bold">{{ $t('b2c_parking') }}</h4>
      <p class="be-paragraph">{{$t(data.venueInformation.parking)}}</p>
    </div>
    <div v-if="data.venueInformation.refreshments" class="be-venue-about__section">
      <h4 class="be-h4 fw-bold">{{ $t('b2c_refreshments') }}</h4>
      <p class="be-paragraph">{{$t(data.venueInformation.refreshments)}}</p>
    </div>
    <div v-if="data.venueInformation.payments_methods.length > 0" class="be-venue-about__section">
      <h4 class="be-h4 fw-bold">{{ $t('b2c_payment_methods') }}</h4>
      <p class="be-paragraph">
        <span
            v-for="(item, index) in data.venueInformation.payments_methods"
            :key="item">
          {{data.venueInformation.payments_methods.length - 1 === index ? `${item}` : `${item}, `}}
        </span>
      </p>
    </div>
    <div v-if="data.venueInformation.wifi_access" class="be-venue-about__section">
      <h4 class="be-h4 fw-bold">{{ $t('b2c_wifi_accesss') }}</h4>
      <p class="be-paragraph">{{$t(data.venueInformation.wifi_access)}}</p>
    </div>
    <div v-if="data.venueInformation.spoken_languages.length > 0" class="be-venue-about__section">
      <h4 class="be-h4 fw-bold">{{ $t('b2c_languages_spoken') }}</h4>
      <p class="be-paragraph">
        <span
            v-for="(item, index) in data.venueInformation.spoken_languages"
            :key="item">
          {{data.venueInformation.spoken_languages.length - 1 === index ? `${item}` : `${item}, `}}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import Leaflet from 'easy-vue-leaflet';

export default {
  name: "BeVenueAbout",
  components: {Leaflet},
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      week: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      options : {
        view : {
          lat: this.data.location.lat,
          lng: this.data.location.lng,
          zoom: 14,
        }
      },
      markers: [
        {
          id: 1,
          position: {
            lat : this.data.location.lat,
            lng : this.data.location.lng
          },
          popup: {
            content: this.data.venueInformation.title,
            show: false
          }
        }
      ],
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://unpkg.com/leaflet@1.6.0/dist/leaflet.css');
@import "../../../assets/scss/global/variables";

.be-venue-about {
  &__section {
    padding-bottom: 20px;
  }

  #leaflet {
    width: 100%;
    height: 250px;
    margin-bottom: 12px;
  }

  .address-anchor {
    position: relative;
    top: -125px;
  }

  &__schedule-table {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 8px;
      padding-right: 24px;
      position: relative;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $text-color;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $border-color;
      }

      &.open {
        &:before {
          background: $primary-color;
        }
      }
    }
  }
  &__map {
    margin-left: -15px;
    margin-right: -15px;
    background: #eee;
    height: 250px;
    margin-bottom: 12px;
  }
}

</style>
