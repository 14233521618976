<template>
  <main-wrapper hide-footer>
    <template #header>
      <be-header
          :title="$t('b2c_your_appointments')"
          :back-to-route="'Profile'"
          :is-loading="isLoading && (futureAppointments.data.length > 0 || pastAppointments.data.length > 0)"
      />
    </template>

    <div class="be-your-appointments">
      <b-tabs
          nav-wrapper-class="be-your-appointments__header"
          content-class="be-your-appointments__body"
      >
        <b-tab :title="$t('b2c_future_appointments')" active>
          <be-placeholder-appointment-list
              v-if="isLoading && futureAppointments.data.length === 0 && pastAppointments.data.length === 0"
          />
          <div v-else>
            <be-placeholder-content
                :icon="iconCalendar"
                :title="$t('b2c_no_future_appointments')"
                v-if="futureAppointments.data.length === 0"
            />
            <template v-else>
              <be-card-appointment
                  v-for="item in futureAppointments.data"
                  :key="item.id"
                  :appointments="item.data"
                  :payment-details="item.chargeInfo && item.chargeInfo.payment_details"
                  :packages="item.packages"
                  :deposit="item.deposit"
                  :refund="item.refund"
                  :retained="item.retained"
                  :receipt-link="item.receiptLink"
                  @cancel="handlerCancelAppointment"
                  :is-active="item.id === activeAppointmentId"
                  type="future"
              />
            </template>

            <div v-if="futureAppointments.lastPage > futureAppointments.page" class="text-center pt-2">
              <b-spinner class="be-spinner" v-if="isLoadingMore"/>
              <be-link
                  v-else
                  variant="primary"
                  @click="handlerMoreFutureAppointments(futureAppointments.page + 1)">{{ $t('b2c_more') }}</be-link>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('b2c_past_appointments')">
          <be-placeholder-appointment-list
              v-if="isLoading && futureAppointments.data.length === 0 && pastAppointments.data.length === 0"
          />
          <div v-else>
            <be-placeholder-content
                :icon="iconCalendar"
                :title="$t('b2c_no_past_appointments')"
                v-if="pastAppointments.data.length === 0"
            />
            <template v-else>
              <be-card-appointment
                  v-for="item in pastAppointments.data"
                  :key="item.id"
                  :appointments="item.data"
                  :packages="item.packages"
                  :deposit="item.deposit"
                  :receipt-link="item.receiptLink"
                  :is-re-book="item.can_rebook"
                  @show-message="cancelRebook"
                  type="past"
              />
            </template>

            <div v-if="pastAppointments.lastPage > pastAppointments.page" class="text-center pt-2">
              <b-spinner class="be-spinner" v-if="isLoadingMore"/>
              <be-link
                  v-else
                  variant="primary"
                  @click="handlerMorePastAppointments(pastAppointments.page + 1)">{{ $t('b2c_more') }}</be-link>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <be-modal-prompts
        :id="idModalArchivePackage"
    >
      <be-prompt
          :icon="iconWarning"
          :title="$t('b2c_this_package_has_been_removed_or_changed')"
      />
      <template #buttons>
        <div class="d-flex flex-column">
          <be-button
              @click="redirectToVenue"
              is-full
              is-outline
              variant="primary">{{$t('b2c_show_other_packages')}}</be-button>
        </div>

      </template>
    </be-modal-prompts>
    <be-modal-prompts
      :id="idModalConfirmCancel"
    >
      <be-prompt
        :icon="iconError"
        :title="$t('b2c_are_you_sure_you_want_to_cancel_the_booking')"
      />
      <template #buttons>
        <be-button @click="$bvModal.hide(idModalConfirmCancel)" variant="primary">{{ $t('b2c_no') }}</be-button>
        <be-button
            @click="confirmAppointmentCancellation"
            :is-loading="isCancelingAppointment"
            variant="error">{{ $t('b2c_yes') }}</be-button>
      </template>
    </be-modal-prompts>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import BePlaceholderContent from "@/components/common/BePlaceholderContent";
import iconCalendar from "../../assets/img/icons/icon-calendar-date.svg";
import BeCardAppointment from "@/components/cards/BeCardAppointment";
import user from "@/api/user";
import BeLink from "@/components/common/BeLink";
import BeModalPrompts from "@/components/BeModals/BeModalPrompts";
import BeButton from "@/components/common/BeButton";
import iconError from "../../assets/img/icons/icon-error.svg"
import iconWarning from "../../assets/img/icons/icon-warning.svg"
import BePrompt from "@/components/common/BePrompt";
import BePlaceholderAppointmentList from "@/components/common/BePlaceholderAppointmentList";
import EventBus from "@/utils/event-bus";

export default {
  name: "YourAppointments",
  components: {
    BePlaceholderAppointmentList,
    BePrompt,
    BeButton, BeModalPrompts, BeLink, BeCardAppointment, BePlaceholderContent, BeHeader, MainWrapper},
  data() {
    return {
      iconCalendar,
      iconError,
      iconWarning,
      isLoading: false,
      isLoadingMore: false,
      idModalConfirmCancel: 'cancel-confirm-modal',
      idModalArchivePackage: 'archive-package-message-modal',
      isCancelingAppointment: false,
      appointmentCancellationData: null,
      venueId: null
    }
  },
  watch: {
    $route: {
      handler: function (newVal, oldVal) {
        if(newVal.fullPath !== oldVal.fullPath) {
          this.initQueryParams();
        }
      }
    }
  },
  computed: {
    futureAppointments() {
      const data = this.$store.getters.futureAppointments

      const appointments = data.data.map(appointment => {
        return {
          ...appointment,
          packages: [...new Set(appointment.data.map(i => i.package_id))].map(packageId => {
            if(packageId) {
              return {
                id: packageId,
                title: appointment.data.filter(i => i.package_id === packageId)[0].package_title,
                total_price: appointment.data.filter(i => i.package_id === packageId)[0].total_price,
                total_duration: appointment.data.filter(i => i.package_id === packageId)[0].total_duration,
                services: [...appointment.data.filter(i => i.package_id === packageId)]
              }
            }
          }).filter(i => i)
        }
      });

      return {...data, data: appointments}
    },
    pastAppointments() {
      const data = this.$store.getters.pastAppointments

      const appointments = data.data.map(appointment => {
        return {
          ...appointment,
          packages: [...new Set(appointment.data.map(i => i.package_id))].map(packageId => {
            if(packageId) {
              return {
                id: packageId,
                title: appointment.data.filter(i => i.package_id === packageId)[0].package_title,
                total_price: appointment.data.filter(i => i.package_id === packageId)[0].total_price,
                total_duration: appointment.data.filter(i => i.package_id === packageId)[0].total_duration,
                services: [...appointment.data.filter(i => i.package_id === packageId)]
              }
            }
          }).filter(i => i)
        }
      })

      return {...data, data: appointments}
    },
    activeAppointmentId() {
      return this.$store.getters.activeAppointmentId;
    },
  },
  created() {
    this.initAppointments(1);
  },
  mounted() {
    this.initQueryParams();
    const self = this;
    EventBus.$on('repeatRequest', function (payload) {
      const params = new URLSearchParams(payload.config.url);

      if(payload.config.url.split('/')[1] === 'cancel-appointment') {
        self.confirmAppointmentCancellation();
      } else {
        if(params.get('page') > 1) {
          if(params.get('moment') === 'past') {
            self.handlerMorePastAppointments(params.get('page'))
          } else {
            self.handlerMoreFutureAppointments(params.get('page'))
          }
        } else {
          self.initAppointments(1);
        }
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    loading(value) {
      this.isLoading = value
    },
    undoLoader(value) {
      this.isCancelingAppointment = value
    },
    handlerCancelAppointment(payload) {
      this.$bvModal.show(this.idModalConfirmCancel)
      this.appointmentCancellationData = payload
    },
    cancelRebook(id) {
      this.venueId = id
      this.$bvModal.show(this.idModalArchivePackage)
    },
    redirectToVenue() {
      this.$bvModal.hide(this.idModalArchivePackage)
      this.$router.push(`/venues/${this.venueId}/services`)
    },
    initQueryParams() {
      const query = this.$route.query

      if(query.type && query.type === 'appointment' && query.id) {
        this.$store.commit('setActiveAppointmentId', Number(query.id))
      }
    },

    async initAppointments(page) {
      await this.loading(true)
      await this.getFutureAppointments(page)
      await this.getPastAppointments(page)
      await this.loading(false)
    },
    async getFutureAppointments(page) {
      try {
        const response = await user.futureAppointments(page)

        this.$store.commit('setFutureAppointments', {
          type: 'future',
          page: response.data.current_page,
          lastPage: response.data.last_page,
          total: response.data.total,
          data: response.data.data,
        })
        this.isLoadingMore = false
      } catch (e) {
        console.log(e)
        this.isLoadingMore = false
      }
    },
    async getPastAppointments(page) {
      try {
        const response = await user.pastAppointments(page)

        this.$store.commit('setPastAppointments', {
          type: 'past',
          page: response.data.current_page,
          lastPage: response.data.last_page,
          total: response.data.total,
          data: response.data.data,
        })
        this.isLoadingMore = false
      } catch (e) {
        this.isLoadingMore = false
        console.log(e)
      }
    },

    async handlerMoreFutureAppointments(page) {
      this.isLoadingMore = true
      await this.getFutureAppointments(page)
    },
    async handlerMorePastAppointments(page) {
      this.isLoadingMore = true
      await this.getPastAppointments(page)
    },

    async confirmAppointmentCancellation() {
      await this.undoLoader(true)
      await this.$store.dispatch('cancelAppointment', this.appointmentCancellationData)
      await this.$bvModal.hide(this.idModalConfirmCancel)
      await this.initAppointments(1);
      await this.undoLoader(false)
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/global/variables";

.be-your-appointments {
  margin-top: -16px;

  .item-slider {
    background: #000;
    color: #fff;
    padding: 40px;
    text-align: center;
    width: 100%;
  }

  &__header {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    margin-left: -15px;
    margin-right: -15px;
    position: sticky;
    top: 56px;
    z-index: 55;
    background: $white;
  }
  &__body {
    margin-left: -15px;
    margin-right: -15px;
    padding: 16px 15px;
  }
}
</style>
