<template>
  <div class="be-captcha">
    <div
        :class="isError && !isTyping ? 'be-captcha__cell-error' : ''"
        class="be-captcha__cell"
        v-for="(item,index) in captcha"
        :key="index"
    >
      <input
          v-model="item.num"
          :id="'captcha'+index"
          @input="inputFinish(index)"
          @focus="adjust(index)"
          @keydown="inputDirection(index)"
          class="be-captcha__input"
          type="tel"
          maxlength="1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BeCaptcha",
  props: {
    isError: {
      type: Boolean
    }
  },
  data() {
    return {
      isTyping: false,
      captcha: [
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
        { num: "" },
      ],
    }
  },
  methods: {
    adjust(index) {
      let dom = document.getElementById("captcha" + this.activeInput);
      if (index !== this.activeInput && dom) {
        dom.focus();
      }
    },
    inputDirection(index) {
      this.isTyping = true

      let val = this.captcha[index].num;

      if (event.keyCode === 8 && val === "") {
        let dom = document.getElementById("captcha" + (index - 1));
        this.activeInput = index - 1;
        if (dom) dom.focus();
      }
      if (event.keyCode !== 8 && val !== "") {
        let dom = document.getElementById("captcha" + (index + 1));
        this.activeInput = index + 1;
        if (dom) dom.focus();
      }
    },
    inputFinish(index) {
      let val = this.captcha[index].num;
      this.activeInput = val ? index + 1 : index - 1;
      let dom = document.getElementById("captcha" + this.activeInput);
      if (dom) dom.focus();
      if (index === this.captcha.length - 1) {
        let code = this.captcha.map((x) => x.num).join("");
        if (code.length === 6) {
          this.isTyping = false
          this.$emit("finish", code);
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-captcha {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 25px;

  &__cell {
    margin: 0 4px;

    &:nth-child(3) {
      &:after {
        content: '-';
        margin: 0 0 0 8px;
        font-size: 16px;
        color: $text-color;
        font-weight: 500;
        line-height: 42px;
      }
    }
  }
  &__input {
    outline: none;
    border: 1px solid $border-form-color;
    border-radius: $border-radius-primary;
    height: 42px;
    width: 30px;
    text-align: center;
    padding: 9px 0;
    transition: .3s;

    &:focus {
      border-color: $primary-color;
      box-shadow: 0 0 10px rgba($primary-color, .7);
    }
  }

  .be-captcha__cell-error {
    .be-captcha__input {
      border-color: $error-color;
    }
  }
}

</style>
