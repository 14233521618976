<template>
  <main-wrapper>
    <template #header>
      <be-header
          :title="userName"
          back-to-route="VenueBasket"
      >
        <template #subtitle>
          {{$t('b2c_pay')}} {{venueTitle}} | <span
            v-if="timer.status"
            :class="timer.countdown < 20 ? 'timer-ending' : ''"
            class="timer"
        >{{ formattedCountdown || '5 min over' }}</span>
        </template>
      </be-header>
    </template>

    <template #footer>
      <div v-if="paymentMethod" class="be-venue-payment-footer">
        <be-button @click="$bvModal.show(idModalConfirmPaymentMethod)" variant="primary" is-full>{{$t('b2c_confirm_payment')}}</be-button>
      </div>
      <div v-else class="be-venue-payment-footer">
        <be-button @click="createPaymentIndent" variant="primary" is-full>{{$t('b2c_add_card')}}</be-button>
      </div>
    </template>
    <div class="be-venue-payment">
      <be-payment-summary/>
      <be-payment-method
          v-if="!isLoadingPM"
          :is-loading="isCreatingPM"
          @create="createPaymentIndent"
      />
      <div v-else class="text-center pt-5">
        <b-spinner class="be-spinner"/>
      </div>
    </div>

    <be-modal-prompts no-close-on-backdrop :id="idModalSavePaymentCard">
      <be-prompt
          :icon="iconWarning"
          :title="$t('b2c_save_my_credit_card_for_future_bookings')"
      />
      <template #buttons>
        <be-button @click="createPaymentMethod(false)" variant="error">{{$t('b2c_no')}}</be-button>
        <be-button @click="createPaymentMethod(true)" variant="primary">{{$t('b2c_yes')}}</be-button>
      </template>
    </be-modal-prompts>
    <be-modal-prompts :id="idModalConfirmPaymentMethod">
      <be-prompt
          :icon="iconWarning"
          :title="$t('b2c_are_you_sure_you_want_to_make_a_payment')"
      />
      <template #buttons>
        <be-button @click="$bvModal.hide(idModalConfirmPaymentMethod)" variant="error">{{$t('b2c_no')}}</be-button>
        <be-button @click="sendPaymentMethod" :is-loading="isLoadingCPM" variant="primary">{{$t('b2c_yes')}}</be-button>
      </template>
    </be-modal-prompts>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper.vue";
import BeHeader from "@/components/BeHeader/index.vue";
import payment from "@/api/payment";
import {PaymentSheetEventsEnum, Stripe} from "@capacitor-community/stripe";
import BePaymentMethod from "@/views/Venues/_id/PaymentProcess/BePaymentMethod.vue";
import BePaymentSummary from "@/views/Venues/_id/PaymentProcess/BePaymentSummary.vue";
import BeButton from "@/components/common/BeButton.vue";
import BePrompt from "@/components/common/BePrompt.vue";
import BeModalPrompts from "@/components/BeModals/BeModalPrompts.vue";
import iconWarning from "@/assets/img/icons/icon-warning.svg";

export default {
  name: "PaymentProcessView",
  components: {BeModalPrompts, BePrompt, BeButton, BePaymentSummary, BePaymentMethod, BeHeader, MainWrapper},
  data() {
    return {
      iconWarning,
      isLoadingPM: false,
      isLoadingCPM: false,
      isCreatingPM: false,
      idModalSavePaymentCard: 'Modal-Save-Payment-Card',
      idModalConfirmPaymentMethod: 'Modal-Confirm-payment-method',
      gratuity: {
        list: [
          {
            id: "proposed_1",
            type: "percent",
            value: 18
          },
          {
            id: "proposed_2",
            type: "percent",
            value: 20
          },
          {
            id: "proposed_3",
            type: "percent",
            value: 25
          }
        ],
        selected: {
          status: null,
          id: null,
          type: "percent",
          value: 0
        },
        custom: {
          isPercent: false,
          value: ""
        }
      }
    }
  },
  computed: {
    timer() {
      return this.$store.getters.timer
    },
    formattedCountdown() {
      return this.$moment.utc(this.$moment.duration(this.$store.getters.timer.countdown, "seconds").asMilliseconds()).format("m:ss")
    },
    venueId() {
      return this.$store.getters.venueId
    },
    userName() {
      return this.$store.getters.userProfile
          ? `${this.$store.getters.userProfile.first_name} ${this.$store.getters.userProfile.last_name}`
          : '';
    },
    venueTitle() {
      return this.$store.getters.venue ? this.$store.getters.venue.venueInformation.title : '';
    },
    basket() {
      return this.$store.getters.savedBasket
    },
    totalBasketPrice() {
      let total = 0
      this.$store.getters.savedBasket.forEach(i => {
        if (i.type !== 'hide_price') {
          if(i.package_id) {
            total = total + i.custom_price
          } else {
            total = total + i.price
          }
        }
      })
      return total
    },
    paymentMethod() {
      return this.$store.getters.getPaymentMethod;
    },
    basketToken() {
      return this.$store.getters.savedBasket.length > 0 ? this.$store.getters.savedBasket[0].token : ''
    },
    bookingNote() {
      return this.$store.getters.bookingNote
    },
    paymentVariant() {
      return this.$store.getters.getPaymentVariant
    }
  },
  mounted() {
    if(this.basket.length === 0) {
      this.$router.push(`/venues/${this.$route.params.id}/services`)
      this.$store.commit('startTimer', false)
      return;
    }

    this.checkPaymentMethod();
  },
  methods: {
    loaderPM(value) {
      this.isLoadingPM = value;
    },
    loaderCreatePM(value) {
      this.isCreatingPM = value;
    },
    loaderCPM(value) {
      this.isLoadingCPM = value;
    },
    async checkPaymentMethod() {
      this.loaderPM(true);
      try {
        const {data} = await payment.getPaymentData(this.venueId);
        if(data.payment_method) {
          await this.$store.commit('setPaymentMethod', data.payment_method);
        }

      } catch (e) {
        console.log(e);
      } finally {
        this.loaderPM(false);
      }
    },
    async createPaymentIndent() {
      this.loaderCreatePM(true);
      try {
        const {data} = await payment.getPaymentIndent(this.venueId);

        await this.loaderCreatePM(false);

        await Stripe.initialize({
          publishableKey: process.env.VUE_APP_STRIPE_API_PUBLISH_KEY,
          stripeAccount: data.account_id,
        });

        await Stripe.createPaymentSheet({
          setupIntentClientSecret: data.client_secret,
          merchantDisplayName: this.venueTitle
        });

        const result = await Stripe.presentPaymentSheet();

        if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
          this.$bvModal.show(this.idModalSavePaymentCard);
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loaderCreatePM(false);
      }
    },
    async createPaymentMethod(value) {
      this.$bvModal.hide(this.idModalSavePaymentCard);
      this.loaderPM(true);
      try {
        const {data} = await payment.getPaymentMethod(this.venueId, value);
        if(data) {
          await this.$store.commit('setPaymentMethod', data);
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loaderPM(false);
      }
    },
    async sendPaymentMethod() {
      this.loaderCPM(true);
      try {
        await payment.setPaymentMethod(this.venueId, {
          id: this.paymentMethod.id,
          token: this.basketToken,
          type: this.gratuity.selected.type,
          value: this.gratuity.selected.value,
          payment_variant: this.paymentVariant,
          note: this.bookingNote
        });

        await this.loaderCPM(false);
        await this.$router.push(`/venues/${this.$route.params.id}/created`);
        await this.$store.commit('startTimer', false);

      } catch (e) {
        console.log(e);
      } finally {
        this.$bvModal.hide(this.idModalConfirmPaymentMethod);
        this.loaderCPM(false);
      }
    },
    async prePayment() {
      try {
        const {data} = await payment.getPaymentNewData(this.venueId)

        await Stripe.initialize({
          publishableKey: process.env.VUE_APP_STRIPE_API_PUBLISH_KEY,
          stripeAccount: data.account_id,
        });

        await Stripe.createPaymentSheet({
          paymentIntentClientSecret: data.paymentIntentClientSecret,
          customerId: data.customerId,
          customerEphemeralKeySecret: data.customerEphemeralKeySecret,
        });

        const result = await Stripe.presentPaymentSheet();

        alert(JSON.stringify(result));
        if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
          this.$bvModal.show(this.idModalSavePaymentCard);
        }

      } catch (e) {
        console.log(e)
      }
    }
  }
}

</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.timer {
  &-ending {
    animation: flash 1s infinite ease-in-out;

    @keyframes flash {
      from {
        color: rgba($red, 1);
      }
      to {
        color: rgba($red, .4);
      }
    }
  }
}

</style>
