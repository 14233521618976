import {http, httpBooking} from "@/axios";

const VENUE_INFO_RESOURCE = '/venue';
const SERVICES_RESOURCE = '/pricings';
const EMPLOYEES_RESOURCE = '/employees';
const TYPE_EMPLOYEES_RESOURCE = '/employee-types';
const EMPLOYEE_PROFILE_RESOURCE = '/employee-profile';
const GET_REVIEWS_RESOURCE = '/get-reviews';
const AVAILABLE_SLOTS_RESOURCE = '/available-slots';
const NEXT_AVAILABLE_SLOTS_RESOURCE = '/next-available-slots';
const PRE_SAVE_APPOINTMENT_RESOURCE = '/appointment/pre-save';
const CHECK_CUSTOMER_RESOURCE = '/check-customer';
const SAVE_APPOINTMENT_RESOURCE = '/appointment/save';
const REMOVE_SLOT_RESOURCE = '/appointment/release-slot';

export default {
  venueInfo: (id) => httpBooking.get(`${VENUE_INFO_RESOURCE}/${id}`),
  services: (id) => new Promise((resolve, reject) => {
    httpBooking.post(`${SERVICES_RESOURCE}/${id}`)
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  employees: (id) => new Promise((resolve, reject) => {
    httpBooking.post(`${EMPLOYEES_RESOURCE}`, {venue_id: id})
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  typesEmployee: (id) => new Promise((resolve, reject) => {
    httpBooking.post(`${TYPE_EMPLOYEES_RESOURCE}`, {venue_id: id})
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  employeeProfile: (payload) => new Promise((resolve, reject) => {
    http.get(`${EMPLOYEE_PROFILE_RESOURCE}/${payload.venueId}/${payload.employeeId}`)
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  getReviews: ({id, page = 1, service = 0, rating = 0}) => new Promise((resolve, reject) => {
    http.get(`${GET_REVIEWS_RESOURCE}/${id}`, {params: {
        page: page,
        pricing_id: service,
        rating: rating
      }})
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  availableSlots: (payload) => new Promise((resolve, reject) => {
    httpBooking.post(`${AVAILABLE_SLOTS_RESOURCE}/${payload.id}`, {date: payload.date, services: payload.services})
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  nextAvailableSlots: (payload) => new Promise((resolve, reject) => {
    httpBooking.post(`${NEXT_AVAILABLE_SLOTS_RESOURCE}/${payload.id}`, {date: payload.date, services: payload.services})
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  preSaveAppointment: (payload) => new Promise((resolve, reject) => {
    httpBooking.post(`${PRE_SAVE_APPOINTMENT_RESOURCE}/${payload.id}`, {date: payload.date, services: payload.services})
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  checkCustomer: (payload) => new Promise((resolve, reject) => {
    http.post(`${CHECK_CUSTOMER_RESOURCE}/${payload.id}`)
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  saveAppointment: (payload) => new Promise((resolve, reject) => {
    httpBooking.post(`${SAVE_APPOINTMENT_RESOURCE}/${payload.id}`, {note: payload.note, services: payload.services})
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  removeSlot: (payload) => new Promise((resolve, reject) => {
    httpBooking.post(`${REMOVE_SLOT_RESOURCE}/${payload.id}`, {basket_id: payload.basket_id, token: payload.token})
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),

};
