import { render, staticRenderFns } from "./BeWrapperEmployeePackage.vue?vue&type=template&id=117d7d11&scoped=true"
import script from "./BeWrapperEmployeePackage.vue?vue&type=script&lang=js"
export * from "./BeWrapperEmployeePackage.vue?vue&type=script&lang=js"
import style0 from "./BeWrapperEmployeePackage.vue?vue&type=style&index=0&id=117d7d11&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "117d7d11",
  null
  
)

export default component.exports