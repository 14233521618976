import {http} from "@/axios";

const REGISTRATION_RESOURCE = '/register';
const PHONE_UPDATE_RESOURCE = '/update-phone';
const LOGIN_RESOURCE = '/login';
const LOGOUT_RESOURCE = '/logout';
const GOOGLE_AUTH_RESOURCE = '/google-auth';
const APPLE_AUTH_RESOURCE = '/apple-auth';
const FORGOT_PASSWORD_RESOURCE = '/forgot-password';
const CHECK_VERIFY_CODE_RESOURCE = '/verify-code';
const RESET_PASSWORD_RESOURCE = '/reset-password';

export default {
    registration: (data) => new Promise((resolve, reject) => {
        http.post(REGISTRATION_RESOURCE, data)
            .then(response => resolve(response))
            .catch(e => reject(e))
    }),
    phoneUpdate: (data) => new Promise((resolve, reject) => {
        http.post(PHONE_UPDATE_RESOURCE, data)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    login: (data) => new Promise((resolve, reject) => {
        http.post(LOGIN_RESOURCE, data)
            .then(response => resolve(response))
            .catch(e => reject(e))
    }),
    logout: (fcmToken) => new Promise((resolve, reject) => {
        http.post(LOGOUT_RESOURCE, {fcm_token: fcmToken})
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    googleAuth: (data) => new Promise((resolve, reject) => {
        http.post(GOOGLE_AUTH_RESOURCE, data)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    appleAuth: (data) => new Promise((resolve, reject) => {
        http.post(APPLE_AUTH_RESOURCE, data)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    forgotPassword: (email) => new Promise((resolve, reject) => {
        http.post(FORGOT_PASSWORD_RESOURCE, {email})
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    checkVerifyCode: (code) => new Promise((resolve, reject) => {
        http.post(CHECK_VERIFY_CODE_RESOURCE, {code})
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
    resetPassword: (data) => new Promise((resolve, reject) => {
        http.post(RESET_PASSWORD_RESOURCE, data)
          .then(response => resolve(response))
          .catch(e => reject(e))
    }),
};
