<template>
  <b-nav class="be-venue-navbar">
    <b-nav-item href="#servicesSection" @click="(e) => $emit('scroll-to', e)">{{ $t('b2c_services') }}</b-nav-item>
    <b-nav-item href="#reviewsSection" @click="(e) => $emit('scroll-to', e)">{{ $t('b2c_reviews') }}</b-nav-item>
    <b-nav-item href="#aboutSection" @click="(e) => $emit('scroll-to', e)">{{ $t('b2c_about') }}</b-nav-item>
  </b-nav>
</template>

<script>

export default {
  name: "BeVenueNavbar"
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/global/variables";

.be-venue-navbar {
  position: sticky;
  top: 54px;
  z-index: 55;
  display: flex;
  justify-content: space-between;
  padding: 0 0 12px;
  margin-left: -15px;
  margin-right: -15px;
  list-style: none;
  background: $white;
  &:before {
    content: '';
    position: absolute;
    left: 15px;
    bottom: 11px;
    width: calc(100% - 30px);
    height: 1px;
    background: $border-color;
  }
  .nav-item {
    padding: 10px;
    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }

    .nav-link {
      font-size: 16px;
      color: $text-color-light;
      font-weight: normal;
      text-decoration: none;
      position: relative;
      padding: 0;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -11px;
        z-index: 2;
        background: $primary-color;
        height: 1px;
        width: 0;
        transition: .3s;
      }

      &:hover {
        color: $primary-color;
      }

      &.active {
        color: $primary-color;
        &:before {
          width: 100%;
        }
      }
    }
  }
}

</style>
