<template>
  <div
      :style="{
        backgroundColor: isOpacity ? `rgba(${scrollTop}, ${scrollTop}, ${scrollTop}, ${scrollTop/300})` : '#ffffff'
      }"
      :class="{'be-app__header_dark_transparent': isOpacity && scrollTop < 70}"
      class="be-app__header">
    <header
        :style="{mixBlendMode: isOpacity && scrollTop > 56 ? 'exclusion' : 'normal'}"
        :class="{'be-header-opacity': isOpacity, 'be-header-profile-title': isSalonProfile}"
        class="be-header">
      <b-container class="be-header-container">
        <div
            class="be-header-row">
          <div class="be-header-row__left">
            <button
                v-if="isBack"
                type="button"
                :style="{
                  color: !isOpacity ? '#162637' : scrollTop > 30 ? `rgba(255, 255, 255, ${scrollTop/100})` : '#ffffff'
                }"
                class="be-header__btn-back"
                @click="handlerBack"
            >
              <i class="icon-be-arrow-left"/>
            </button>
            <div class="">
              <span
                  v-if="title"
                  :class="{'be-header__title-show': scrollTop > (isOpacity ? 320 : 100)}"
                  class="be-header__title"
              >{{title}}</span>
              <div class="be-header__subtitle">
                <slot name="subtitle"></slot>
              </div>
            </div>

          </div>
          <div class="be-header-row__right">
            <b-spinner v-if="isLoading" class="be-spinner" small/>
            <span
                v-if="isSalonProfile"
                @click="!isFavorite ? $emit('to-favorite') : $emit('from-favorite')"
                :class="{'active': isFavorite, 'be-btn-favorite-show': scrollTop > (isOpacity ? 320 : 100)}"
                class="be-btn-favorite"
            >
              <i class="icon-be-heart"/>
              <i class="icon-be-heart-fill"/>
            </span>

            <slot name="tools"></slot>
          </div>
        </div>
      </b-container>
    </header>
  </div>
</template>

<script>
export default {
  name: "BcHeader",
  props: {
    title: {
      type: String
    },
    isSalonProfile: {
      type: Boolean,
      default: false
    },
    isFavorite: {
      type: Boolean,
      default: false
    },
    backToRoute: {
      type: String
    },
    backToRouteParam: {
      type: Object,
      default: null
    },
    isBack: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean
    },
    isOpacity: {
      type: Boolean
    }
  },
  data() {
    return {
      sliderHeight: 0,
      isShowBack: false,
      isShowHeader: true,
      pathToBack: '/',
    }
  },
  watch: {
    $route(to, from) {
      this.routerCheck(to.path, from.path)
    }
  },
  computed: {
    scrollTop() {
      return this.$store.getters.scrollTop
    },
    isAuth() {
      return this.$store.getters.isAuth
    }
  },
  mounted() {
    this.routerCheck(this.$route.path, '/')
  },
  methods: {
    routerCheck(path, from) {
      switch (path) {
        case "/":
          this.isShowBack = false;
          break;

        case "/venues":
          this.isShowBack = true;
          this.isShowHeader = !this.isAuth;
          this.pathToBack = this.$route.meta.prev ? this.$route.meta.prev : from
          break;

        case "/appointment":
          this.isShowBack = false;
          this.isShowHeader = false;
          this.pathToBack = this.$route.meta.prev ? this.$route.meta.prev : from
          break;

        case "/profile":
          this.isShowBack = false;
          this.isShowHeader = false;
          this.pathToBack = this.$route.meta.prev ? this.$route.meta.prev : from
          break;

        default:
          this.pathToBack = this.$route.meta.prev ? this.$route.meta.prev : from
          this.isShowBack = true;
          this.isShowHeader = true;
      }
    },
    handlerBack() {
      if(this.backToRoute) {
        this.$router.push({name: this.backToRoute, params: {...this.backToRouteParam}});
      } else {
        this.$router.go(-1);
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";
@import "../../assets/scss/global/mixins";
@import "../../assets/scss/global/utils";

.be-header {
  position: relative;
  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    position: relative;
    z-index: 5;

    &__left {
      display: flex;
      align-items: center;
    }
    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .be-btn-favorite {
        width: 28px;
        min-width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 1px;
        cursor: pointer;
        transition: .2s;
        i {
          font-size: 28px;
          color: $text-color-dark;
          transition: .2s;

          &.icon-be-heart-fill {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(.2);
            opacity: 0;
            z-index: 5;
          }
        }

        &:hover{
          i {
            text-shadow: 0 0 3px rgba($text-color-dark, .7);
          }
        }

        &.active {
          i {
            &.icon-be-heart-fill {
              transform: translate(-50%, -50%) scale(1);
              opacity: 1;

              animation: heart .4s;

              @keyframes heart {
                60% {
                  transform: translate(-50%, -50%) scale(1.4);
                }
                100% {
                  transform: translate(-50%, -50%) scale(1);
                }
              }
            }
          }
        }
      }
    }
  }

  &__btn-back {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: $text-color-dark;

    margin-right: 16px;
    i {
      font-size: 22px;
      position: relative;
      transition: .2s;
    }
  }
  &__title {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    color: $text-color-dark;
    padding-right: 20px;
  }
  &__subtitle {
    font-size: 12px;
    color: $text-color-light;
  }
  .be-logo {
    img {
      width: 130px;
      height: auto;
      object-fit: contain;
    }
  }

  &.be-header-opacity {
    .be-header__btn-back {
      position: relative;
      z-index: 4;
      //color: $white-300;

      //&:before {
      //  content: '';
      //  position: absolute;
      //  left: 50%;
      //  top: 50%;
      //  width: 60px;
      //  height: 60px;
      //  z-index: -1;
      //  background: rgba($black, .2);
      //  transform: translate(-50%, -50%);
      //}
    }

    &.be-header-profile-title {
      .be-header__title {
        color: $white;
      }
      .be-header-row__right {
        .be-btn-favorite {
          i {
            color: $white;
          }
        }
      }
    }
  }
  &.be-header-profile-title {
    .be-header__title {
      transition: .2s;
      transform: scale(.8);
      opacity: 0;

      &.be-header__title-show {
        opacity: 1;
        transform: scale(1);
      }
    }
    .be-header-row__right {
      .be-btn-favorite {
        transition: .2s;
        transform: scale(.8);
        opacity: 0;
        visibility: hidden;

        &.be-btn-favorite-show {
          opacity: 1;
          transform: scale(1);
          visibility: visible;
        }
      }
    }
  }
}
</style>
