<template>
  <main-wrapper is-center-content>
    <be-placeholder-content
        :icon="NotFound"
        :title="$t('b2c_page_not_found')"
    />
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BePlaceholderContent from "@/components/common/BePlaceholderContent";
import NotFound from "../../assets/img/icons/icon-offline.svg"

export default {
  name: "NotFound",
  components: {BePlaceholderContent, MainWrapper},
  data() {
    return {
      NotFound
    }
  }
}
</script>

<style scoped>

</style>
