<template>
  <div class="be-services-filters-control">
    <div class="row">
      <div class="col-6">
        <be-button v-ripple @click="$emit('reset')" is-outline is-full>{{ $t('b2c_reset') }}</be-button>
      </div>
      <div class="col-6">
        <be-button v-ripple @click="$emit('apply')" is-full variant="primary">{{ $t('b2c_applied') }}</be-button>
      </div>
    </div>
  </div>
</template>

<script>

import BeButton from "@/components/common/BeButton";
export default {
  name: "BeServicesFiltersControl",
  components: {BeButton}
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

  .be-services-filters-control {
    margin-top: -16px;
    margin-left: -15px;
    margin-right: -15px;
    background: $white;
    padding: 5px 15px 12px;
    z-index: 55;
    position: sticky;
    top: 56px;
  }
</style>
