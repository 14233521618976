<template>
  <div class="be-service-details">
    <div class="be-service-details__header">
      <h4 class="title">{{ data.title }}</h4>
      <div v-if="!data.isPackage" class="be-service-details__header-info">
        <span class="time">{{ data.info }}</span>
        <span class="price">{{ data.variants[0].price_formatted }}</span>
      </div>
      <div v-else class="be-service-details__header-info">
        <span class="time">{{ data.duration }}</span>
        <span class="price">{{ data.price }}</span>
      </div>
    </div>
    <div class="be-service-details__body">
      <div v-if="data.image" class="img-wrapper">
        <img :src="data.image" :alt="data.title">
      </div>
      <div v-if="data.services && data.services.length > 0" class="mb-4">
        <h3 class="general">{{ $t('b2c_services')}}</h3>
        <be-card-list-basket
            v-for="item in data.services"
            :key="item.id"
            :title="item.title"
            :price="item.variants[0].custom_price | priceFormat(priceData)"
            :old-price="item.variants[0].price_formatted"
            :duration="item.variants[0].custom_duration | time"
            hide-remove-button
        />
      </div>
      <div v-if="data.description">
        <h3 class="general">{{ !data.isPackage ? $t('b2c_about_this_service') : $t('b2c_about_this_package')}}</h3>
        <div v-html="data.description" class="description"/>
      </div>
    </div>
    <div class="be-service-details__footer">
      <div v-if="isShowVariants" class="be-service-details__footer-variants">
        <div v-for="variant in data.variants" :key="variant.id" class="be-service-details__footer-item">
          <div class="be-service-details__footer-item__info">
            <div class="title">{{variant.caption}}</div>
            <div class="info">{{ variant.duration }} {{ $t('minute_short') }} | {{data.variants[0].price_formatted}}</div>
          </div>
          <div class="be-service-details__footer-item__control">
            <be-button
                @click="!variant.selected ?
                  selectService({
                    service_id: data.id,
                    variant_id: variant.id
                  }) :
                  removeService(variant.id)"
                variant="primary"
                :is-selected="variant.selected"
                icon-right="icon-be-arrow-line-right">{{ $t('b2c_select') }}
            </be-button>
          </div>
        </div>
      </div>
      <div v-else class="be-service-details__footer-item">
        <template v-if="!data.isPackage">
          <div class="be-service-details__footer-item__info">
            <div class="label">
              {{ data.variants.length }}
              {{ data.variants.length > 1 ? $t("b2c_services") : $t("b2c_service") }}
            </div>
            <div class="price">{{ data.variants[0].price_formatted }}</div>
          </div>
          <div class="be-service-details__footer-item__control">
            <be-button
                @click="data.variants.length > 1 ? isShowVariants = true : !data.variants[0].selected ?
                  selectService({
                    service_id: data.id,
                    variant_id: data.variants[0].id
                  }) :
                  removeService(data.variants[0].id)"
                variant="primary"
                :is-selected="data.variants.length === 1 && data.variants[0].selected"
                icon-right="icon-be-arrow-line-right">{{ $t('b2c_select') }}
            </be-button>
          </div>
        </template>
        <template v-else>
          <div class="be-service-details__footer-item__info">
            <div class="label">
              {{ data.services.length }}
              {{ data.services.length > 1 ? $t("b2c_services") : $t("b2c_service") }}
            </div>
            <div class="price">{{ data.price }}</div>
          </div>
          <div class="be-service-details__footer-item__control">
            <be-button
                @click="!data.selectedPackage ?
                  selectPackage({
                    package_id: data.id,
                    services: data.services
                  }) :
                  removePackage(data.id)"
                variant="primary"
                :is-selected="data.selectedPackage"
                icon-right="icon-be-arrow-line-right">{{ $t('b2c_select') }}
            </be-button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import BeButton from "@/components/common/BeButton";
import BeCardListBasket from "@/components/cards/BeCardListBasket";
import {priceFormatter, timeFormatter} from "@/utils/common";

export default {
  name: "BeContentServiceDetails",
  components: {BeCardListBasket, BeButton},
  props: {
    data: {
      type: Object
    }
  },
  filters: {
    priceFormat(amount, formatData) {
      return priceFormatter(amount, formatData)
    },
    time(time) {
      return timeFormatter(time)
    }
  },
  data() {
    return {
      isShowVariants: false
    }
  },
  computed: {
    priceData() {
      return this.$store.getters.priceData
    }
  },
  methods: {
    selectService(payload) {
      this.$store.commit('selectService', payload)
      this.$emit('hide-details')
    },
    removeService(variantId) {
      this.$store.commit('removeService', variantId)
      this.$emit('hide-details')
    },
    selectPackage(payload) {
      this.$store.commit('selectPackage', payload)
      this.$emit('hide-details')
    },
    removePackage(packageId) {
      this.$store.commit('removePackage', {id: packageId})
      this.$emit('hide-details')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/global/variables";

.be-service-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $white;
  flex: 1;

  &__header {
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px;
    border-bottom: 1px solid $border-color;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 8px;
    }

    .time, .price {
      font-size: 14px;
      font-weight: normal;
      display: inline-block;
      color: $text-color;
    }

    .time {
      color: $text-color-light;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__body {
    flex: 1;
    padding: 15px 0;

    .img-wrapper {
      margin-bottom: 20px;

      img {
        overflow: hidden;
        border-radius: 12px;
        border: 1px solid $border-color;
        display: block;
        max-width: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .general {
      font-size: 18px;
      font-weight: 500;
      color: $text-color;
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      color: $text-color;
      margin-bottom: 12px;
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    background: $white;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.12);
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: -15px;
      margin-right: -15px;
      padding: 15px;
      border-bottom: 1px solid $border-color;
      &:last-child {
        border: none;
      }
      &__info {
        .label {
          font-size: 14px;
          font-weight: normal;
          color: $text-color;
          margin-bottom: 3px;
        }
        .title {
          font-size: 16px;
          font-weight: normal;
          color: $text-color;
          margin-bottom: 3px;
        }
        .info {
          font-size: 14px;
          color: $text-color-light;
          font-weight: normal;
        }
        .price {
          font-size: 16px;
          font-weight: 600;
          color: $text-color;
        }
      }
    }
  }
}

</style>
