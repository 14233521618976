import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from "axios";
import {langSuffix} from "@/utils/common";

Vue.use(VueI18n)

const API_URL = process.env.VUE_APP_API_URL

const locale = localStorage.getItem("lang_type")
const language = JSON.parse(localStorage.getItem("lang"))
const messages = {
  [locale]: language
}

export const i18n = new VueI18n({locale, messages})

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  const timestamp = new Date().getTime() / 1000;
  const expired_date = timestamp + 86400;

  const localeUpdated = localStorage.getItem("lang_type");
  const languageUpdated = JSON.parse(localStorage.getItem("lang"));
  const languageDate = Number(localStorage.getItem("lang_date"));


  if(languageUpdated && languageDate && timestamp < languageDate) {
    if (localeUpdated === lang) {
      setI18nLanguage(lang)

      return Promise.resolve()
    }
  }

  return axios.get(`${API_URL}/get-translations/${langSuffix(lang)}`)
    .then(response => {
      let msgs = JSON.parse(response.data.translations)

      i18n.setLocaleMessage(lang, msgs);
      localStorage.setItem('lang_type', lang);
      localStorage.setItem('lang', JSON.stringify(msgs));
      localStorage.setItem('lang_date', expired_date.toString());
      return setI18nLanguage(lang)
    }).catch(e => {
      console.log(e)
    });
}
