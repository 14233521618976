<template>
  <be-button
      :variant="'google'"
      :is-full="true"
      :is-loading="isLoading"
      @click="logIn"
  >
    <slot/>
  </be-button>
</template>

<script>
import BeButton from "@/components/common/BeButton";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import auth from "@/api/auth";
import {http, httpBooking} from "@/axios";
import user from "@/api/user";

export default {
  name: "BeGoogleAuthButton",
  components: {BeButton},
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    device() {
      return this.$store.getters.device
    },
    fcmToken() {
      return this.$store.getters.fcmToken
    }
  },
  methods: {
    redirect(route) {
      this.$router.push({name: route})
    },
    loader(value) {
      this.isLoading = value
    },
    async logIn() {
      await this.loader(true);
      try {
        const response = await GoogleAuth.signIn();
        await this.googleAuth(response.authentication.idToken)
      } catch (e) {
        await this.loader(false);
        console.log(e)
      }
    },
    async googleAuth(token) {
      try {
        const response = await auth.googleAuth({
          token_id: token,
          app_details: {
            fcm_token: this.fcmToken,
            platform: this.device ? this.device.platform : '',
            model: this.device ? this.device.model : ''
          }
        });

        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem(
            "expires_in",
            Math.round(+new Date() / 1000 + response.data.expires_in)
        );
        http.defaults.headers.common = {
          ...http.defaults.headers.common,
          Authorization: `Bearer ${response.data.access_token}`
        };
        httpBooking.defaults.headers.common = {
          ...httpBooking.defaults.headers.common,
          Authorization: `Bearer ${response.data.access_token}`
        };

        await this.$store.commit('authorizeUser');
        await this.userProfile();
        await this.$store.dispatch('fetchNotifications');
        await this.loader(false);

        const followingPath = this.$store.getters.followingPath;

        if(followingPath) {
          await this.$router.push(followingPath);
        } else {
          await this.redirect('Venues');
        }

      } catch (e) {
        await this.loader(false);
        console.log(e);

        if(e.response.status === 500) {
          alert(`Error, something went wrong :( Status: ${e.response.status}`);
        }
      }
    },
    async userProfile() {
      const response = await user.profile();
      this.$store.commit('setUserProfile', response.data);
    }
  }
}
</script>

<style scoped>

</style>
