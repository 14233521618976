<template>
  <div :class="`${themes[type]} text-${position}`" class="be-alert">
    <div class="be-alert__inner">
      <h5 v-if="title" class="be-alert__title">{{title}}</h5>
      <p v-if="description" class="be-alert__description">{{description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeAlert",
  props: {
    type: {
      type: String
    },
    position: {
      type: String,
      default: 'center',
      validate(value) {
        return ['left', 'right', 'center'].indexOf(value) > -1
      }
    },
    title: {
      type: String
    },
    description: {
      type: String
    }
  },
  data() {
    return {
      themes: {
        'warning': 'be-alert--warning',
        'error': 'be-alert--error',
        'success': 'be-alert--success',
        'info': 'be-alert--success',
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-alert {
  padding: 10px 15px;
  border-radius: $border-radius-primary;
  background: $white-100;

  .be-alert__title {
    color: $text-color;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
  }
  .be-alert__description {
    color: $text-color-light;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    padding-top: 4px;
    margin: 0;
  }

  &.be-alert--error {
    background: rgba($error-color, .15);

    .be-alert__title {
      color: $error-color;
    }
  }
  &.be-alert--warning {
    background: rgba($warning-color, .15);

    .be-alert__title {
      color: $warning-color;
    }
    .be-alert__description {
      color: $warning-color;
    }
  }
  &.be-alert--success {
    background: rgba($primary-color, .15);

    .be-alert__title {
      color: $primary-color;
    }
  }
}

</style>
