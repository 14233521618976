import {http} from "@/axios";

const GET_NOTIFICATIONS = '/notifications';
const UPDATE_NOTIFICATION = '/notifications/read';
const CLEAR_ALL = '/notifications/clear-all';

export default {
  notifications: () => http.get(GET_NOTIFICATIONS),
  updateDetails: (id) => new Promise((resolve, reject) => {
    http.post(`${UPDATE_NOTIFICATION}/${id}`)
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
  clearAll: () => new Promise((resolve, reject) => {
    http.post(CLEAR_ALL)
      .then(response => resolve(response))
      .catch(e => reject(e))
  }),
};
