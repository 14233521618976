<template>
  <main-wrapper hide-footer>
    <template #header>
      <be-header
          :title="$t('b2c_select_slot')"
          :back-to-route="installedEmployee ? `VenueEmployee` : `VenueStaff`"
          :back-to-route-param="installedEmployee ? {employee: installedEmployee.id} : null"
      />
    </template>

    <div class="be-venue-slots">
      <div class="be-venue-slots__header">
        <be-horizontal-calendar
          :selected-date="slots.selectedDate"
          :date="slots.selectedDate"
          :min-date="slots.minDate"
          @change="setSlots"
          @click-date="$bvModal.show(idCalendarModal)"
        />
      </div>
      <div class="be-venue-slots__body">
        <div class="text-right">
          <be-link
              @click="$router.push(`/venues/${venueId}/services`)"
              icon-left="icon-be-plus-round"
              variant="primary">{{ $t('b2c_add_another_service') }}</be-link>
        </div>
        <div v-if="isLoading" class="text-center pt-5">
          <b-spinner class="be-spinner"/>
        </div>
        <be-venue-slots
          v-else
          :slots="slots"
          :venue-name="venueTitle"
          :has-employees="isEmployeesMore"
          @select="preSaveAppointment"
          @next="setSlots"
        />
      </div>
    </div>

    <be-modal
      :id="idCalendarModal"
      :title="calendarModalTitle"
    >
      <b-calendar
          v-model="slots.selectedDate"
          :locale="locale"
          :min="slots.dateToday"
          :start-weekday="1"
          class="be-calendar"
          @selected="dateSelected"
      >
        <template #nav-next-month>
          <i class="icon-be-arrow-line-right"></i>
        </template>
        <template #nav-prev-month>
          <i class="icon-be-arrow-line-left"></i>
        </template>
      </b-calendar>
    </be-modal>
    <be-modal-prompts
      :id="idModalNotAvailableSlots"
    >
      <be-prompt
        :icon="IconError"
        :title="errorPreSave"
      />
      <div class="text-center">
        <be-button @click="$bvModal.hide(idModalNotAvailableSlots)" variant="primary">{{$t('b2c_ok')}}</be-button>
      </div>
    </be-modal-prompts>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeHeader from "@/components/BeHeader";
import BeLink from "@/components/common/BeLink";
import venue from "@/api/venue";
import BeVenueSlots from "@/views/Venues/_id/Slots/BeVenueSlots";
import BeHorizontalCalendar from "@/components/common/BeHorizontalCalendar";
import BeModalPrompts from "@/components/BeModals/BeModalPrompts";
import BePrompt from "@/components/common/BePrompt";
import IconError from "../../../../assets/img/icons/icon-error.svg"
import BeButton from "@/components/common/BeButton";
import BeModal from "@/components/BeModals/BeModal";

export default {
  name: "VenueSlots",
  components: {
    BeModal,
    BeButton, BePrompt, BeModalPrompts, BeHorizontalCalendar, BeVenueSlots, BeLink, MainWrapper, BeHeader},
  data() {
    return {
      IconError,
      errorPreSave: '',
      isLoading: false,
      idCalendarModal: 'modal-calendar',
      calendarModalTitle: 'Select Date',
      idModalNotAvailableSlots: 'modal-not-available-slots',
      slots: {
        time: {
          morning: [],
          afternoon: [],
          evening: []
        },
        error: {
          availableSlots: '',
          nextAvailableSlots: ''
        },
        dateToday: '',
        selectedDate: '',
        minDate: '',
        nextAvailableDate: '',
        nextMonthDay: 0,
        nextMonth: '',
      }
    }
  },
  computed: {
    venueId() {
      return this.$store.getters.venueId
    },
    isAuth() {
      return this.$store.getters.isAuth
    },
    venueTitle() {
      const name = this.$store.getters.venue ? this.$store.getters.venue.venueInformation.title : ''
      return name
    },
    basket() {
      return this.$store.getters.basket
    },
    installedEmployee() {
      return this.$store.getters.installedEmployee
    },
    isEmployeesMore() {
      return this.$store.getters.basket.some(i => i.employees.length > 1)
    },
    locale() {
      return this.$store.getters.appLanguage
    }
  },
  mounted() {
    this.calendarModalTitle = this.$t('b2c_select_date')
    this.slots.dateToday = this.$moment().format('YYYY-MM-DD')
    this.slots.selectedDate = this.$moment(this.slots.dateToday).format('YYYY-MM-DD')
    this.slots.minDate = this.$moment().add('days', -1).format('YYYY-MM-DD')

    this.setNextMonth(this.slots.selectedDate)

    if(this.basket.length === 0) {
      this.$router.push(`/venues/${this.$route.params.id}`)
      return;
    }

    this.initialSlots({
      id: this.venueId,
      date: this.$moment(this.slots.selectedDate).format('YYYY-MM-DD'),
      nextDate: this.$moment(this.slots.selectedDate).add(1, 'day').format('YYYY-MM-DD'),
      services: this.basket.map(i => {
        return {
          package_id: i.package_id,
          service_id: i.service_id,
          variant_id: i.variant_id,
          employee_id: i.employee_id,
        }
      })
    })
  },
  methods: {
    async initialSlots(payload) {
      this.isLoading = true
      this.cleanSlots()
      await this.availableSlots({
        id: payload.id,
        date: payload.date,
        services: payload.services
      })
      await this.nextAvailableSlots({
        id: payload.id,
        date: payload.nextDate,
        services: payload.services
      })
    },
    async availableSlots(payload) {
      try {
        const response = await venue.availableSlots(payload)
        this.setAvailableSlots(response.data)
        this.isLoading = false
      } catch (e) {
        if(e.response.status === 404) {
          this.slots.error.availableSlots = e.response.data.message
        }
        this.isLoading = false
        this.slots.isLoading = true
      }
    },
    async nextAvailableSlots(payload) {
      try {
        const response = await venue.nextAvailableSlots(payload)
        this.slots.nextAvailableDate = response.data.date
        this.slots.isLoading = false
      } catch (e) {
        this.slots.isLoading = false
        if(e.response.status === 404) {
          this.slots.error.nextAvailableSlots = e.response.data.message
        }
      }
    },

    async preSaveAppointment(payload) {
      this.isLoading = true
      this.$store.commit('setTimeToBasket', payload)

      try {
        const {data} = await venue.preSaveAppointment(payload)
        await this.$store.commit("startTimer", true);

        console.log("Start", this.$moment().format("h:mm:ss"));

        if(data.success === 'true') {
          await this.$store.commit('setVenueDeposit', {deposit: data.deposit});
          await this.$store.commit('setSavedBasket', data.services);

          if(this.isAuth) {
            await this.$router.push(`/venues/${this.$route.params.id}/basket`);
          } else {
            this.$store.commit('setFollowingPath', `/venues/${this.$route.params.id}/basket`);
            await this.$router.push(`/authorization`);
          }

        }
      } catch (e) {
        if(e.response.status === 400) {
          this.errorPreSave = this.$t('b2c_slot_are_not_able_to_book');
          this.$bvModal.show(this.idModalNotAvailableSlots);
        }
        console.log(e)
      } finally {
        this.isLoading = false;
      }
    },

    setSlots(date) {
      this.slots.selectedDate = date
      this.initialSlots({
        id: this.venueId,
        date: date,
        nextDate: this.$moment(date).add(1, 'day').format('YYYY-MM-DD'),
        services: this.basket.map(i => {
          return {
            package_id: i.package_id,
            service_id: i.service_id,
            variant_id: i.variant_id,
            employee_id: i.employee_id,
          }
        })
      })
    },

    setNextMonth(today) {
      this.slots.nextMonthDay = this.$moment(today).add(1, 'month').format('DD')
      this.slots.nextMonth = this.$moment(today).add(1, 'month').format('MMM')
    },
    setAvailableSlots(payload) {
      const keys = Object.keys(payload);

      keys.forEach(item => {
        if (item.split(":")[0] < 12) {
          this.slots.time.morning.push({ name: item, value: [...payload[item]] });
        }
      });
      keys.forEach(item => {
        if (item.split(":")[0] >= 12 && item.split(":")[0] < 17) {
          this.slots.time.afternoon.push({ name: item, value: [...payload[item]] });
        }
      });
      keys.forEach(item => {
        if (item.split(":")[0] >= 17) {
          this.slots.time.evening.push({ name: item, value: [...payload[item]] });
        }
      });
    },
    cleanSlots() {
      this.slots.error.availableSlots = ''
      this.slots.error.nextAvailableSlots = ''
      this.slots.nextAvailableDate = ''
      this.slots.time.morning = []
      this.slots.time.afternoon = []
      this.slots.time.evening = []
    },

    dateSelected(date) {
      this.$bvModal.hide(this.idCalendarModal)
      this.setSlots(this.$moment(date).format("YYYY-MM-DD"))
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-slots {

}

</style>
