<template>
  <div class="be-venue-payment-method">
    <div class="be-venue-payment-method__header">
      <h5 class="be-venue-payment-method__header-title">{{$t('b2c_payment_method')}}</h5>

      <div class="d-flex align-items-center">
        <b-spinner v-if="isLoading" small class="be-spinner"/>
        <be-link
            v-if="!paymentMethod"
            @click="$emit('create')"
            class="ms-3"
            variant="primary">{{$t('b2c_add_card')}}</be-link>
      </div>
    </div>
    <div v-if="paymentMethod" @click="$emit('create')" class="be-venue-payment-method__data">

      <be-payment-card
        :brand="paymentMethod.brand"
        :number="paymentMethod.last4"
      />
      <i class="icon-be-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import BeLink from "@/components/common/BeLink.vue";
import {BSpinner} from "bootstrap-vue";
import BePaymentCard from "@/components/cards/BePaymentCard.vue";

export default {
  name: 'BePaymentMethod',
  components: {BePaymentCard, BeLink, BSpinner},
  props: {
    isLoading: {
      type: Boolean
    }
  },
  computed: {
    paymentMethod() {
      return this.$store.getters.getPaymentMethod;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-payment-method {
  border-bottom: 1px solid $white-300;
  margin-left: -16px;
  margin-right: -16px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 16px;
    &-title {
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
      margin: 0;
    }
  }
  &__data {
    padding: 15px 16px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .3s;
    cursor: pointer;
    &:hover {
      background: $white-100;
    }
  }
}
</style>
