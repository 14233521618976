<template>
  <main-wrapper>
    <template #header>
      <be-header
          :title="$t('b2c_sign_up')"
          :back-to-route="'SignUp'"
      />
    </template>
    <be-basket-info v-if="isBasket" is-basket is-timer/>
    <form @submit.prevent="createUser">
      <div class="be-auth-form">
        <be-input
            type="text"
            name="first-name"
            :required="true"
            :label="$t('b2c_first_name')"
            :value="form.firstName"
            @input="(value) => form.firstName = value"
            :error="errors.firstName"
            @validate="validate('firstName')"
        />
        <be-input
            type="text"
            name="last-name"
            :required="true"
            :label="$t('b2c_last_name')"
            :value="form.lastName"
            @input="(value) => form.lastName = value"
            :error="errors.lastName"
            @validate="validate('lastName')"
        />
        <be-input
            type="email"
            name="email"
            :required="true"
            :label="$t('b2c_your_email')"
            :value="form.email"
            @input="(value) => form.email = value"
            :error="errors.email"
            @validate="validate('email')"
        />
        <be-input-phone
            :label="$t('b2c_phone')"
            :required="true"
            :value="form.phone"
            :error="errors.phone || errors.phoneIsNotValid"
            @input="handlerPhone"
            @validate="validate('phone')"
        />
        <be-input
            :type="!isShowPassword ? 'password' : 'text'"
            name="password"
            :required="true"
            :password-switcher="true"
            :label="$t('b2c_password')"
            :value="form.password"
            @input="(value) => form.password = value"
            :error="errors.password"
            @validate="validate('password')"
            @switch="() => isShowPassword = !isShowPassword"
        />

        <div class="be-form-group">
          <be-checkbox
              :id="'legal-age'"
              :checked="form.age"
              :text="$t('b2c_confirm_you_are_over_the_legal_age')"
              :error="errors.age.length > 0"
              @input="handlerAge"
          />
        </div>

        <div class="pb-4">
          <be-link @click="$bvModal.show(idPrivacyModal)" class="fz-14" variant="primary">{{ $t('b2c_privacy_policy') }}</be-link>
        </div>

        <div class="be-form-group">
          <be-checkbox
              :id="'agree-email'"
              :checked="form.isEmail"
              :text="$t('b2c_agree_to_be_contacted_by_email')"
              @input="handlerIsEmail"
          />
        </div>
        <div class="be-form-group">
          <be-checkbox
              :id="'agree-sms'"
              :checked="form.isSms"
              :text="$t('b2c_agree_to_be_contacted_by_sms')"
              @input="handlerIsSms"
          />
        </div>
        <div class="be-form-group pb-3">
          <be-checkbox
              :id="'agree-medical-related-data'"
              :checked="form.isMedicalData"
              :text="$t('b2c_agree_to_medical_related_data')"
              @input="handlerIsMedicalData"
          />
        </div>

        <div class="be-form-group-btn mt-3">
          <be-button
              :type="'submit'"
              :is-full="true"
              variant="primary"
              :is-loading="isLoading"
          >
            {{ $t('b2c_create_account') }}
          </be-button>
        </div>
      </div>
    </form>
  </main-wrapper>
</template>

<script>
import BeButton from "../../components/common/BeButton";
import BeInput from "../../components/common/BeInput";
import MainWrapper from "@/components/common/MainWrapper";
import BeInputPhone from "@/components/common/BeInputPhone";
import {boolean, string, object} from "yup";
import BeCheckbox from "@/components/common/BeCheckbox";
import auth from "@/api/auth";
import BeHeader from "@/components/BeHeader";
import {http, httpBooking} from "@/axios";
import {mapMutations} from "vuex";
import BeLink from "@/components/common/BeLink";
import BeBasketInfo from "@/components/common/BeBasketInfo";

export default {
  name: "AuthorizationView",
  components: {BeBasketInfo, BeCheckbox, BeInputPhone, MainWrapper, BeInput, BeButton, BeHeader, BeLink},
  data() {
    return {
      isLoading: false,
      isShowPassword: false,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        phoneIsNotValid: true,
        age: false,
        isSms: false,
        isEmail: false,
        isMedicalData: false,
        password: ''
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        phoneIsNotValid: '',
        age: '',
        password: ''
      }
    }
  },
  computed: {
    isBasket() {
      return this.$store.getters.savedBasket.length > 0
    },
    idPrivacyModal() {
      return this.$store.getters.privacyPolicy.idModal
    },
    device() {
      return this.$store.getters.device
    },
    fcmToken() {
      return this.$store.getters.fcmToken
    },
    RegistrationFormSchema() {
      const params = {
        firstName: string().required(this.$t("b2c_this_field_is_required")),
        lastName: string().required(this.$t("b2c_this_field_is_required")),
        email: string()
            .email(this.$t("b2c_email_must_be_valid"))
            .required(this.$t("b2c_this_field_is_required")),
        password: string()
            .required(this.$t("b2c_this_field_is_required"))
            .min(
                8,
                this.$t("b2c_password_must_be_at_least_characters_long", { num: 8 })
            ),
        phone: string().required(this.$t("b2c_this_field_is_required")),
        phoneIsNotValid: boolean().oneOf([false], this.$t("b2c_phone_is_not_valid")),
        age: boolean()
            .oneOf([true], this.$t("b2c_this_field_is_required"))
            .required()
      };

      return object().shape(params);
    }
  },
  methods: {
    ...mapMutations({
      authorizeUser: 'authorizeUser'
    }),
    redirect(route) {
      this.$router.push({name: route})
    },
    validate(field) {
      this.RegistrationFormSchema.validateAt(field, this.form)
          .then(() => {
            this.errors[field] = "";
          })
          .catch((err) => {
            this.errors[err.path] = err.message;
          });
    },
    handlerPhone({obj, num}) {
      if (obj.valid) {
        this.form.phone = obj.number
      }

      if (String(num).length > 4) {
        this.form.phoneIsNotValid = !obj.valid;
        this.validate("phoneIsNotValid");
      } else {
        this.form.phoneIsNotValid = obj.valid;
        this.validate("phoneIsNotValid");
      }
    },
    handlerAge(value) {
      this.form.age = value
      this.errors.age = ''
    },
    handlerIsEmail(value) {
      this.form.isEmail = value
    },
    handlerIsSms(value) {
      this.form.isSms = value
    },
    handlerIsMedicalData(value) {
      this.form.isMedicalData = value
    },

    async registration() {
      this.isLoading = true
      try {
        const data = {
          first_name: this.form.firstName,
          last_name: this.form.lastName,
          phone: this.form.phone,
          email: this.form.email,
          password: this.form.password,
          age: this.form.age,
          cust_market_emails: this.form.isEmail,
          cust_market_sms: this.form.isSms,
          cust_medical_consent: this.form.isMedicalData,
          app_details: {
            fcm_token: this.fcmToken,
            platform: this.device ? this.device.platform : '',
            model: this.device ? this.device.model : ''
          }
        }
        const response = await auth.registration(data)

        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem(
            "expires_in",
            Math.round(+new Date() / 1000 + response.data.expires_in)
        );
        http.defaults.headers.common = {
          ...http.defaults.headers.common,
          Authorization: `Bearer ${response.data.access_token}`,
        };
        httpBooking.defaults.headers.common = {
          ...httpBooking.defaults.headers.common,
          Authorization: `Bearer ${response.data.access_token}`
        };

        this.isLoading = false
        await this.authorizeUser();
        this.$store.dispatch('fetchNotifications');

        const followingPath = this.$store.getters.followingPath

        if(followingPath) {
          await this.$router.push(followingPath)
        } else {
          await this.redirect('Venues');
        }

      } catch (e) {
        this.isLoading = false

        if(e.response.status === 422) {
          this.errors.email = e.response.data.email[0]
        }
      }
    },
    createUser() {
      this.RegistrationFormSchema.validate(this.form, {abortEarly: false})
          .then(async () => {
            await this.registration();
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.errors = {...this.errors, [error.path]: error.message};
            });
          });
    }
  }
}
</script>
