<template>
  <main-wrapper transparent-footer>
    <template>
      <div class="be-user-profile">
        <div v-if="user" class="be-user-profile__info">
          <div class="be-user-profile__info-avatar">
            <span>{{user.first_name[0]}}{{user.last_name[0]}}</span>
          </div>
          <div class="be-user-profile__info-name">
            <span>{{user.first_name}} {{user.last_name}}</span>
          </div>
        </div>

        <div class="btn-notifications">
          <be-notifications-widget/>
        </div>

        <ul class="be-user-profile__menu">
          <li v-ripple class="be-user-profile__menu-item">
            <router-link to="/venues">
              <span>
                <i class="icon-be-create-appointment"></i>
                {{ $t('b2c_make_appointment') }}
              </span>
              <i class="icon-be-arrow-right"/>
            </router-link>
          </li>
          <li v-ripple class="be-user-profile__menu-item">
            <router-link to="/profile/appointments">
              <span>
                <i class="icon-be-search-appointment"></i>
                {{ $t('b2c_your_appointments') }}
              </span>
              <i class="icon-be-arrow-right"/>
            </router-link>
          </li>
          <li v-ripple class="be-user-profile__menu-item">
            <router-link to="/profile/settings">
              <span>
                <i class="icon-be-user-edit"></i>
                {{ $t('b2c_your_details') }}
              </span>
              <i class="icon-be-arrow-right"/>
            </router-link>
          </li>
          <li v-ripple class="be-user-profile__menu-item">
            <router-link to="/profile/favourites">
              <span>
                <i class="icon-be-heart2"></i>
                {{ $t('b2c_favourites') }}
              </span>
              <i class="icon-be-arrow-right"/>
            </router-link>
          </li>
          <li v-ripple class="be-user-profile__menu-item">
            <router-link to="/profile/data-preferences">
              <span>
                <i class="icon-be-setting4"></i>
                {{ $t('b2c_data_preferences') }}
              </span>
              <i class="icon-be-arrow-right"/>
            </router-link>
          </li>
          <li v-ripple class="be-user-profile__menu-item">
            <span @click="() => $bvModal.show(idModalPrompts)">
              <span>
                <i class="icon-be-logout"></i>
                {{ $t('b2c_logout') }}
              </span>
              <i class="icon-be-arrow-right"/>
            </span>
          </li>
        </ul>
      </div>

      <be-modal-prompts :id="idModalPrompts">
        <be-prompt
            :icon="iconLogout"
            :sub-title="$t('b2c_confirm_logout')"
        />
        <template #buttons>
          <be-button :variant="'error'" @click="() => $bvModal.hide(idModalPrompts)">{{$t('b2c_no')}}</be-button>
          <be-button :is-loading="isLoading" variant="primary" @click="logout">{{$t('b2c_yes')}}</be-button>
        </template>
      </be-modal-prompts>
    </template>
    <template #footer>
      <div class="text-center">
        <div class="d-flex justify-content-center pb-2">
          <be-language-button/>
        </div>
        <template v-if="app && app.version && app.build">
          <span class="app-version-label">{{$t('b2c_app_version')}}: {{app.version}} ({{app.build}})</span>
        </template>
      </div>
    </template>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import auth from "@/api/auth";
import BeButton from "@/components/common/BeButton";
import BePrompt from "@/components/common/BePrompt";
import BeModalPrompts from "@/components/BeModals/BeModalPrompts";
import iconLogout from "../../assets/img/icons/icon-logout.svg";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import BeLanguageButton from "@/components/common/BeLanguageButton";
import EventBus from "@/utils/event-bus";
import BeNotificationsWidget from "@/widgets/BeNotificationsWidget.vue";
import {App} from "@capacitor/app";

export default {
  name: "ProfileView",
  components: {BeNotificationsWidget, BeLanguageButton, MainWrapper, BeButton, BePrompt, BeModalPrompts},
  data() {
    return {
      iconLogout,
      idModalPrompts: 'confirm-logout',
      isLoading: false,
      app: null
    }
  },
  computed: {
    platform() {
      return this.$store.getters.device.platform
    },
    user() {
      return this.$store.getters.userProfile
    },
    fcmToken() {
      return this.$store.getters.fcmToken
    }
  },
  mounted() {
    this.getAppData()
    const self = this;
    EventBus.$on('repeatRequest', function (payload) {
      if(payload.isRepeatRequest) {
        self.logout();
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    loader(value) {
      this.isLoading = value
    },
    async getAppData() {
      if(this.platform !== 'web') {
        this.app = await App.getInfo()
      }
    },
    async logout() {
      await this.loader(true);
      await auth.logout(this.fcmToken);
      await GoogleAuth.signOut();
      await this.$store.dispatch('logout')
      await this.loader(false);
      await this.$router.push({name: 'Venues'})
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";
@import "../../assets/scss/global/mixins";

.app-version-label {
  display: inline-block;
  font-size: 11px;
  color: $text-color-light;
  margin: 20px 0;
}

.be-user-profile {
  position: relative;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 30px;

    &-avatar {
      width: 92px;
      height: 92px;
      border-radius: 50%;
      background: $primary-color;
      color: $white;
      font-size: 24px;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 14px;
      line-height: 92px;
    }
    &-name {
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
    }
  }
  &__menu {
    margin-left: -15px;
    margin-right: -15px;
    padding: 0;
    list-style: none;
    &-item {
      padding: 0;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border: none;
      }

      a, span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        font-size: 16px;
        font-weight: normal;
        color: $text-color;
        padding: 15px 17px;

        span {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0;

          i {
            font-size: 22px;
            margin-right: 13px;
            color: $text-color-light;
          }
        }
      }
    }
  }

  .btn-notifications  {
    position: absolute;
    right: 0;
    top: 0;
  }

  .be-user-profile-placeholder__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 30px;
    &-avatar {
      width: 92px;
      height: 92px;
      border-radius: 50%;
      background: $placeholder-background;
      margin-bottom: 14px;
      //@include placeholder-radius-slide-shadow();
    }
    &-name {
      display: flex;
      align-items: center;
      span {
        @include placeholder-text(190px);
        @include placeholder-slide-shadow();
        height: 24px;
      }
    }
  }
}

</style>
