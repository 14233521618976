<template>
  <main-wrapper is-center-content>
    <div class="be-venue-timeout">
      <div class="be-venue-timeout__header">
        <h3 class="name">{{salon}}</h3>
        <div class="icon-time">
          <img :src="IconTimer" alt="Timer Belliata">
        </div>
      </div>
      <div class="be-venue-timeout__body">
        <p>{{ $t('b2c_you_did_not_complete_your_booking') }}</p>
        <p>{{ $t('b2c_do_not_worry_you_can_try_again') }}</p>
      </div>
      <div class="be-venue-timeout__footer">
        <be-button
            @click="$router.push(`/venues/${$route.params.id}/services`)"
            is-full
            variant="primary">{{ $t('b2c_try_again') }}</be-button>
      </div>
    </div>
  </main-wrapper>
</template>

<script>
import MainWrapper from "@/components/common/MainWrapper";
import BeButton from "@/components/common/BeButton";
import IconTimer from "../../../../assets/img/icons/icon-hourglass.svg"
export default {
  name: "VenueTimeout",
  components: {BeButton, MainWrapper},
  data() {
    return {
      IconTimer
    }
  },
  computed: {
    salon() {
      return this.$store.getters.venue ? this.$store.getters.venue.venueInformation.title : ''
    },
  },
  mounted() {
    this.$store.commit('cleanBasket', true)
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-timeout {
  text-align: center;

  &__header {
    padding-bottom: 30px;
    .name {
      font-size: 24px;
      font-weight: 600;
      color: $text-color-dark;
      margin-bottom: 28px;
    }
    .icon-time {
      display: inline-block;
      img {
        display: inline-block;
        height: 45px;
        width: auto;
        object-fit: contain;
      }
    }
  }
  &__body {
    padding-bottom: 8px;

    p {
      font-size: 16px;
      font-weight: normal;
      color: $text-color;
      margin-bottom: 12px;
    }
  }
}

</style>
