<template>
  <main-wrapper hide-footer :is-center-content="!hasNotifications">
    <template #header>
      <be-header :title="$t('b2c_notifications')" :is-loading="isLoading">
        <template v-if="notifications.length > 1" #tools>
          <be-link variant="primary" @click="clearAll" class="ml-4">{{$t('b2c_clear_all')}}</be-link>
        </template>
      </be-header>
    </template>
    <template>
      <be-placeholder-content
          v-if="!hasNotifications"
          :icon="PlaceholderIcon"
          :title="$t('b2c_has_not_notifications_title')"
          :description="$t('b2c_has_not_notifications_description')"
      />
      <div class="">
        <template v-for="el in notifications">
          <be-card-notification
              :key="el.id"
              v-bind="el"
              class="mb-3"
              @click="select(el.id, el.appointment_id, el.type, el.link)"
          />
        </template>
      </div>
    </template>
  </main-wrapper>
</template>

<script>
import BeCardNotification from "@/components/cards/BeCardNotification.vue";
import BePlaceholderContent from "@/components/common/BePlaceholderContent.vue";
import {openBrowser} from "../../utils/common";
import BeLink from "../../components/common/BeLink.vue";
import notifications from "../../api/notifications";
import PlaceholderIcon from "@/assets/img/icons/icon-message-notifiaction.svg"
import BeHeader from "@/components/BeHeader";
import MainWrapper from "@/components/common/MainWrapper";

export default {
  name: "NotificationView",
  components: {BeLink, BePlaceholderContent, BeCardNotification, MainWrapper, BeHeader},
  data() {
    return {
      PlaceholderIcon,
      isLoading: false,
    }
  },
  computed: {
    notifications() {
      return this.$store.getters.notifications;
    },
    hasNotifications() {
      return this.$store.getters.hasNotifications;
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    toggleLoader(value) {
      this.isLoading = value
    },

    select(id, appointmentId, type, link) {
      if(type === 'new_review' && link) {
        openBrowser(link)
      } else {
        this.$router.push({
          path: '/profile/appointments',
          query: { type: 'appointment', id: appointmentId }
        });
      }

      this.$store.dispatch('readNotification', id)
    },
    async init() {
      try {
        await this.$store.dispatch("fetchNotifications");
      } catch (e) {
        console.log(e)
      }
    },
    async clearAll() {
      this.toggleLoader(true)
      try {
        await notifications.clearAll()
        await this.init()
      } catch (e) {
        console.log(e)
      } finally {
        this.toggleLoader(false)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";
.ml-4 {
  margin-left: 7px;
}
</style>
