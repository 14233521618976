<template>
  <div>
    <div v-ripple class="be-card-list-service">
      <div class="be-card-list-service__inner">
        <span v-if="isSelected" class="be-card-list-service__btn btn-selected">
          <i class="icon-be-check"></i>
        </span>
        <span v-else class="be-card-list-service__btn btn-add"/>

        <div class="be-card-list-service__info">
          <div class="d-flex justify-content-between">
            <h4 class="be-card-list-service__info-title">{{ title }}</h4>
            <span class="be-card-list-service__info-price">{{ totalPrice }}</span>
          </div>
          <div class="be-card-list-service__info-additional">
            <span class="time">{{ totalDuration }} {{ $t("b2c_minute_short") }}</span> | <span
              class="group-name">{{services.length}} {{services.length > 1 ? $t('services') : $t('services')}}</span>
          </div>
          <be-link
              @click="$emit('show-details', id)"
              class="fz-14 show-details"
              variant="info"
          >{{ $t('b2c_show_details') }}
          </be-link>
        </div>
        <span
            @click="!isSelected ?
            selectPackage({
              package_id: id,
              services: services
            }) :
            removePackage(id)"
            class="be-card-list-service__toggle"/>
      </div>
    </div>
  </div>
</template>

<script>
import BeLink from "@/components/common/BeLink";

export default {
  name: "BeCardListPackage",
  components: {BeLink},
  props: {
    id: {
      type: [Number, String]
    },
    title: {
      type: String
    },
    totalPrice: {
      type: [Number, String]
    },
    totalDuration: {
      type: [Number, String]
    },
    services: {
      type: Array,
      default: () => []
    },
    isSelected: {
      type: Boolean
    },

    employees: {
      type: Array
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    selectPackage(payload) {
      this.$store.commit('selectPackage', payload)
    },
    removePackage(packageId) {
      this.$store.commit('removePackage', {id: packageId})
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-card-list-service {
  margin-left: -15px;
  margin-right: -15px;

  &__inner {
    padding: 16px 15px 16px 63px;
    border-bottom: 1px solid $border-color;
    position: relative;
    cursor: pointer;
    transition: .2s;

    &:hover {
      background: $white-100;
    }
  }

  &__toggle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .show-details {
    position: relative;
    z-index: 10;
  }

  &__btn {
    position: absolute;
    left: 15px;
    top: 16px;

    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid $text-color;
    cursor: pointer;

    &.btn-add {
      &:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 2px;
        background: $text-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        content: '';
        position: absolute;
        width: 15px;
        height: 2px;
        background: $text-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        transition: .3s;
      }
    }

    &.btn-selected {
      background: $primary-color;
      border-color: $primary-color;
      color: $white;

      i {
        position: absolute;
        font-size: 22px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__info {
    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.3;
      color: $text-color;
      margin-bottom: 5px;
    }

    &-price {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: $text-color;
      padding-left: 10px;
      white-space: nowrap;
    }

    &-additional {
      padding-bottom: 3px;
      font-size: 14px;
      line-height: 1;
      font-weight: normal;
      color: $text-color-light;
    }
  }

  &__collapse-body {
    padding: 0 15px;
    border-bottom: 1px solid $border-color;
  }

}

</style>
