import {authToken} from "@/utils/common";

export default function auth ({ next, store }){
    if(!store.getters.isAuth) {
        return next({
            name: 'Login'
        })
    } else if(!authToken()) {
        return next({
            name: 'Login'
        })
    }
    return next()
}
