<template>
  <div class="be-venue-staff-package">
    <div class="be-venue-staff-package__header">
      <span class="be-venue-staff-package__header-title">{{ title }}</span>
      <span @click="$emit('remove')" class="be-venue-staff-package__header-remove">
              <i class="icon-be-close"/>
            </span>
    </div>
    <div class="be-venue-staff-package__body">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeWrapperEmployeePackage",
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/global/variables";

.be-venue-staff-package {
  padding: 10px 10px 5px;
  border-radius: 6px;
  border: 1px dashed $border-color;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;

    &-title {
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
    }
    &-remove {
      i {
        display: inline-block;
        cursor: pointer;
        font-size: 24px;
        color: $text-color-dark;
        transition: .3s;

        &:hover {
          transform: rotate(180deg);
          color: $red;
        }
      }
    }
  }
}

</style>
