<template>
  <div class="be-card-unknown-employee">
    <div class="be-card-unknown-employee__inner">
      <div class="d-flex justify-content-between">
        <div class="be-card-unknown-employee__info">
          <p class="service">{{serviceTitle}}</p>
          <span class="group">{{groupTitle}}</span>
        </div>
        <span v-if="!hideRemoveBtn" @click="$emit('remove')" class="be-card-unknown-employee__remove">
          <i class="icon-be-close"/>
        </span>
      </div>
      <div @click="$emit('change')" class="be-card-unknown-employee__select">
        <span v-if="selected" class="be-card-unknown-employee__select-title">{{selected}}</span>
        <span v-else class="be-card-unknown-employee__select-placeholder">Choose Staff</span>
        <i class="icon-be-arrow-right"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeCardUnknownEmployee",
  props: {
    id: {
      type: [String, Number]
    },
    serviceTitle: {
      type: String
    },
    groupTitle: {
      type: String
    },
    selected: {
      type: String
    },
    hideRemoveBtn: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-card-unknown-employee {
  &__inner {
    padding: 14px 0;
    border-bottom: 1px solid $border-color;
  }

  &:last-child {
    .be-card-unknown-employee__inner {
      border: none;
    }
  }

  &__info {
    padding-bottom: 4px;
    .service {
      font-size: 16px;
      font-weight: 500;
      color: $text-color;
      margin: 0;
    }
    .group {
      font-size: 14px;
      color: $text-color-light;
      font-weight: normal;
    }
  }
  &__remove {
    i {
      display: inline-block;
      cursor: pointer;
      font-size: 24px;
      color: $text-color-dark;
      transition: .3s;

      &:hover {
        transform: rotate(180deg);
        color: $red;
      }
    }
  }
  &__select {
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 4px;
    padding: 10px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s;

    &:hover {
      box-shadow: 0 0 9px rgba($text-color, .2);
    }

    &-placeholder {
      font-size: 15px;
      color: $text-color-light;
      font-weight: normal;
    }
    &-title {
      font-size: 15px;
      color: $text-color;
      font-weight: 500;
    }
  }
}

</style>
