<template>
  <div class="be-venue-header">
    <div class="be-venue-header__heading">
      <h1>{{data.venueInformation.title}}</h1>
      <span
          @click="!data.isFavorite ? $emit('to-favorite', data.id) : $emit('from-favorite', data.id)"
          :class="data.isFavorite ? 'active' : ''"
          class="be-btn-favorite"
      >
              <i class="icon-be-heart"/>
              <i class="icon-be-heart-fill"/>
            </span>
    </div>
    <a href="#addressVenue" class="be-venue-header__address">
      <address>{{data.venueInformation.address}}</address>
      <i class="icon-be-arrow-right"/>
    </a>
    <div
        @click="redirect('reviews')"
        class="be-venue-header__reviews">
      <div class="d-flex align-items-center">
        <be-star-rating :rating="parseInt(data.averageRating)"/>
        <div class="be-venue__info-reviews__text">
          {{data.averageRating}} ({{reviewLength > 1 ? `${reviewLength} ${$t('b2c_reviews')}` : `${reviewLength} ${$t('b2c_review')}`}})
        </div>
      </div>
      <i class="icon-be-arrow-right"/>
    </div>
    <div class="be-venue-header__schedule">
      <div class="be-schedule">
        <div v-b-toggle.some-schedule class="be-schedule__btn">
                <span class="be-schedule__btn-title">
                  <i class="icon-be-clock"/>
                  {{data.openNow.status > 0 ? $t('b2c_open_now') : $t('b2c_closed')}}
                </span>
          <div class="be-schedule__btn-tools">
                  <span v-if="data.openNow.status > 0" class="time">
                    {{data.openNow.start}} - {{data.openNow.end}}
                  </span>
            <i class="icon-be-arrow-right"/>
          </div>
        </div>
        <b-collapse id="some-schedule">
          <ul class="be-schedule__table">
            <li
                v-for="(item, index) in week"
                :key="item + index"
                :class="data.workingHours[index].status > 0 ? 'open' : ''"
                class="be-schedule__table-item">
              <span class="day">{{data.workingHours[index].weekday}}</span>

              <span v-if="data.workingHours[index].status > 0" class="time">
                      {{data.workingHours[index].start}} - {{data.workingHours[index].end}}
                    </span>
              <span v-else class="closed">{{ $t('b2c_closed') }}</span>
            </li>
          </ul>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import BeStarRating from "@/components/common/BeStarRating";

export default {
  name: "BeVenueHeader",
  components: {BeStarRating},
  props: {
    data: {
      type: Object
    },
    reviewLength: {
      type: Number
    }
  },
  computed: {
    week() {
      return [this.$t('b2c_mon'), this.$t('b2c_Tue'), this.$t('b2c_Wed'), this.$t('b2c_Thu'), this.$t('b2c_Fri'), this.$t('b2c_Sat'), this.$t('b2c_Sun')]
    }
  },
  methods: {
    redirect(path) {
      this.$router.push(`${this.$route.path}/${path}`)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/global/variables";

.be-venue-header {
  padding-bottom: 12px;
  &__heading {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    h1 {
      font-size: 24px;
      font-weight: bold;
      color: $text-color-dark;
      margin: 0;
      padding-right: 15px;
    }
    .be-btn-favorite {
      width: 28px;
      min-width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 1px;
      cursor: pointer;
      transition: .2s;
      i {
        font-size: 28px;
        color: $text-color-dark;
        transition: .2s;

        &.icon-be-heart-fill {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(.2);
          opacity: 0;
          z-index: 5;
        }
      }

      &:hover{
        i {
          text-shadow: 0 0 3px rgba($text-color-dark, .7);
        }
      }

      &.active {
        i {
          &.icon-be-heart-fill {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;

            animation: heart .4s;

            @keyframes heart {
              60% {
                transform: translate(-50%, -50%) scale(1.4);
              }
              100% {
                transform: translate(-50%, -50%) scale(1);
              }
            }
          }
        }
      }
    }
  }
  &__address {
    display: flex;
    align-content: center;
    justify-content: space-between;
    text-decoration: none;
    font-size: 16px;
    color: $text-color-light;
    padding: 10px 0;
    cursor: pointer;
    address{
      margin: 0;
      padding-right: 15px;
    }
    i {
      color: $text-color;
      font-size: 20px;
      margin-right: 5px;
    }
  }
  &__reviews {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    cursor: pointer;

    &__flex {
      display: flex;
      align-items: center;
    }

    i {
      color: $text-color;
      font-size: 20px;
      margin-right: 5px;
    }

    .be-star-rating {
      margin-right: 10px;
    }
    &__text {
      font-size: 16px;
      font-weight: 500;
      color: $text-color-light;
    }
  }
  &__schedule {
    padding-top: 10px;
    .be-schedule {
      &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: $text-color;
        outline: none!important;

        &-title {
          display: flex;
          align-items: center;
          i {
            font-size: 22px;
            position: relative;
            top: -1px;
            margin-right: 10px;
          }
        }
        &-tools {
          display: flex;
          align-items: center;
          padding-right: 6px;

          .icon-be-arrow-right {
            margin-left: 20px;
            display: inline-block;
            transition: .3s;
            font-size: 20px;
            transform: rotate(-90deg);
          }
        }

        &.collapsed {
          .be-schedule__btn-tools {
            .icon-be-arrow-right {
              transform: rotate(90deg);
            }
          }
        }
      }
      &__table {
        list-style: none;
        padding: 10px 0 0;
        margin: 0;
        li {
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 32px;
          padding-right: 37px;
          position: relative;
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $text-color;

          &:before {
            content: '';
            position: absolute;
            right: 8px;
            top: 50%;
            margin-top: -4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $border-color;
          }

          &.open {
            &:before {
              background: $primary-color;
            }
          }
        }
      }
    }
  }
}

</style>
