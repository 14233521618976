<template>
  <div class="be-venue-reviews">
    <h4 class="be-h4 fw-bold">{{ $t('b2c_reviews') }}</h4>
    <div class="d-flex">
      <div class="be-venue-reviews__total">
        <div class="be-venue-reviews__total-rating">
          <i :class="!Number(data.main) ? 'gray-star' : ''" class="icon-be-star-full"/>
          <span class="rating">{{data.main}}</span>
        </div>
        <div class="be-venue-reviews__total-counter">
          <span>
            {{data.length > 1 ? `${data.length} ${$t('b2c_reviews_lowercase')}` : `${data.length} ${$t('b2c_review_lowercase')}`}}
          </span>
        </div>
      </div>
    </div>
    <div class="be-venue-reviews__list">
      <div class="be-venue-reviews__list-item">
        <span class="label">{{ $t('b2c_atmosphere') }}:</span>
        <be-star-rating :rating="data.atmosphere"/>
      </div>
      <div class="be-venue-reviews__list-item">
        <span class="label">{{ $t('b2c_staff') }}:</span>
        <be-star-rating :rating="data.stylist"/>
      </div>
      <div class="be-venue-reviews__list-item">
        <span class="label">{{ $t('b2c_cleanliness') }}:</span>
        <be-star-rating :rating="data.cleanliness"/>
      </div>
      <div class="be-venue-reviews__list-item">
        <span class="label">{{ $t('b2c_value') }}:</span>
        <be-star-rating :rating="data.value"/>
      </div>
    </div>
    <be-link v-if="data.length > 0" :to="`${$route.path}/reviews`" variant="primary">{{ $t('b2c_read_reviews') }}</be-link>
  </div>
</template>

<script>
import BeStarRating from "@/components/common/BeStarRating";
import BeLink from "@/components/common/BeLink";
export default {
  name: "BeVenueReviews",
  components: {BeLink, BeStarRating},
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/global/variables";

.be-venue-reviews {
  padding-bottom: 24px;
  &__total {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    &-rating {
      display: flex;
      align-items: center;

      i {
        display: inline-block;
        color: $gold;
        font-size: 30px;
        margin-right: 10px;
        margin-left: -8px;
        position: relative;
        top: -1px;

        &.gray-star {
          color: $border-color;
        }
      }

      .rating {
        font-size: 32px;
        line-height: 1;
        font-weight: 500;
        color: $text-color-dark;
        display: inline-block;
      }
    }
    &-counter {
      font-size: 14px;
      color: $text-color-light;
      font-weight: normal;
    }
  }
  &__list {
    padding-bottom: 8px;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .label {
        font-size: 16px;
        font-weight: 500;
        color: $text-color;
      }
    }
  }
}

</style>
