<template>
  <div class="be-card-appointment" :class="{'be-card-appointment_active': isActive}">
    <div
        v-for="packageItem in packages"
        :key="packageItem.id"
        class="be-card-appointment-package">
      <div class="be-card-appointment-package__header">
        <span class="be-card-appointment-package__header-title">{{packageItem.title}}</span>
        <div class="be-card-appointment-package__header-info">
          <span class="duration">{{packageItem.total_duration | time}}</span>
          <span class="price">{{packageItem.total_price | priceFormat(priceData)}}</span>
        </div>
      </div>
      <div class="be-card-appointment-package__body">
        <div
            v-for="(item, index) in packageItem.services"
            :key="`${item.appt_group_id}${item.variant_id}`"
            class="be-card-appointment__inner">
          <div v-if="index === 0" class="be-card-appointment__salon">
            <span class="salon">{{item.venue.title}}</span> <address> | {{item.venue.address}}</address>
          </div>
          <div class="be-card-appointment__service">
            <div class="d-flex justify-content-between">
              <span class="date">{{item.date}}</span>
              <div>
                <span class="price">{{item.price_formatted}}</span>
              </div>
            </div>
            <p class="title">{{item.service_title}}</p>
            <span class="title-group">{{item.group_title}}</span>
            <span class="time">{{item.duration_formatted}} | {{item.employee}}</span>

            <span v-if="type === 'future' && !item.cancel_possibility" class="status">{{item.status}}</span>
          </div>
          <div v-if="type === 'past' && index === appointments.length - 1" class="be-card-appointment__control">
            <be-button
                v-if="isReBook"
                @click="handlerReBook({
              appointments,
              venue_id: item.venue.id
            })"
                :is-loading="isLoading"
                variant="primary"
                is-outline>
              {{ $t('b2c_rebook') }}
            </be-button>
          </div>
          <div
              v-else-if="type === 'future' && index === appointments.length - 1 && item.cancel_possibility"
              class="be-card-appointment__control"
          >
            <be-button
                @click="$emit('cancel', {venueId: item.venue.id, id: item.appt_group_id})"
                variant="error"
                is-outline
            >{{$t('b2c_cancel_appointment')}}</be-button>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="(item, index) in appointments.filter(i => !i.package_id)"
        :key="`${item.appt_group_id}${item.variant_id}`"
        class="be-card-appointment__inner">
      <div v-if="index === 0" class="be-card-appointment__salon">
        <span class="salon">{{item.venue.title}}</span> <address> | {{item.venue.address}}</address>
      </div>
      <div class="be-card-appointment__service">
        <div class="d-flex justify-content-between">
          <span class="date">{{item.date}}</span>
          <div>
            <span class="price">{{item.price_formatted}}</span>
          </div>
        </div>
        <p class="title">{{item.service_title}}</p>
        <span class="title-group">{{item.group_title}}</span>
        <span class="time">{{item.duration_formatted}} | {{item.employee}}</span>
        <span v-if="type === 'future' && !item.cancel_possibility" class="status">{{item.status}}</span>
      </div>
      <div v-if="type === 'past' && index === appointments.filter(i => !i.package_id).length - 1" class="be-card-appointment__control">
        <be-button
            v-if="isReBook"
            @click="handlerReBook({
              appointments,
              venue_id: item.venue.id
            })"
            :is-loading="isLoading"
            variant="primary"
            is-outline>
          {{ $t('b2c_rebook') }}
        </be-button>
      </div>
      <div
          v-else-if="type === 'future' && index === appointments.filter(i => !i.package_id).length - 1 && item.cancel_possibility"
          class="be-card-appointment__control"
      >
        <be-button
            @click="$emit('cancel', {venueId: item.venue.id, id: item.appt_group_id})"
            variant="error"
            is-outline
        >{{$t('b2c_cancel_appointment')}}</be-button>
      </div>
    </div>

    <div v-if="deposit" class="be-card-appointment__payment">
      <div v-if="paymentDetails" class="be-card-appointment__payment-title">{{$t('b2c_transaction_information')}}</div>
      <be-payment-card
          v-if="paymentDetails"
          class="mb-2"
          :brand="paymentDetails.brand"
          :number="paymentDetails.last4"
      />

      <div v-if="deposit" class="d-flex align-items-center justify-content-between">
        <span class="status">{{$t('b2c_deposit')}}</span>
        <span class="price">{{deposit}}</span>
      </div>
      <div v-if="refund" class="d-flex align-items-center justify-content-between pt-1">
        <span class="status">{{$t('b2c_refund')}}</span>
        <span class="price"><span class="text-success">{{refund}}</span></span>
      </div>
      <div v-if="retained" class="d-flex align-items-center justify-content-between pt-1">
        <span class="status">{{$t('b2c_retained')}}</span>
        <span class="price"><span class="text-danger">{{retained}}</span></span>
      </div>

      <div v-if="receiptLink" class="pt-2">
        <a :href="receiptLink" class="be-link be-link--info">{{$t('b2c_payment_receipt')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import BeButton from "@/components/common/BeButton";
import {priceFormatter, timeFormatter} from "@/utils/common";
import BePaymentCard from "@/components/cards/BePaymentCard.vue";
export default {
  name: "BeCardAppointment",
  components: {BePaymentCard, BeButton},
  props: {
    packages: Array,
    appointments: Array,
    paymentDetails: Object,
    deposit: String,
    refund: String,
    retained: String,
    receiptLink: String,
    type: String,
    isReBook: Boolean,
    isActive: Boolean
  },
  filters: {
    priceFormat(amount, formatData) {
      return priceFormatter(amount, formatData)
    },
    time(time) {
      return timeFormatter(time)
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    basket() {
      return this.$store.getters.basket
    },
    priceData() {
      return this.$store.getters.priceData
    },
    isArchivePackage() {
      return this.$store.getters.isArchivePackage
    }
  },
  methods: {
    loader(value) {
      this.isLoading = value
    },
    goToStaff(appointments) {
      let packages = [...new Set(appointments.map(i => i.package_id))].filter(i => i)

      packages.forEach(packageId => {
        this.$store.commit('selectPackage', {
          package_id: packageId
        })
      })

      appointments.forEach(service => {
        if(!service.package_id) {
          this.$store.commit('selectService', {
            variant_id: service.variant_id,
            service_id: service.service_id,
          })
        }
      })
    },
    redirectToStaff(id) {
      let basket = this.basket

      let flag = basket.every(i => i.employees.length <= 1 && i.employees.length > 0)

      basket = basket.map(item => {
        if (item.employees.length > 1) {
          return item
        }
        return {
          ...item,
          employee_id: item.employees[0].id,
          employee_name: `${item.employees[0].first_name} ${item.employees[0].last_name}`,
          employee_position: item.employees[0].position
        }
      })

      this.$store.commit('updateBasket', basket);

      if (flag) {
        this.$router.push(`/venues/${id}/slots`)
      } else {
        this.$router.push(`/venues/${id}/staff`)
      }
    },

    async handlerReBook(payload) {
      await this.initializeVenue(payload.venue_id)
      await this.goToStaff(payload.appointments)
      if(this.isArchivePackage) {
        this.$emit('show-message', payload.venue_id)
      } else {
        await this.redirectToStaff(payload.venue_id)
      }
    },

    async initializeVenue(id) {
      await this.loader(true)
      await this.$store.dispatch('getVenueProfile', id)
      await this.$store.dispatch('getVenueInfo', id)
      await this.$store.dispatch('getVenueServices', id)
      await this.$store.dispatch('getVenueEmployees', id)
      await this.loader(false);
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-card-appointment-package {
  padding: 10px;
  border-radius: 4px;
  border: 1px dashed $border-color;
  margin-bottom: 15px;

  &_active {
    border: 1px dashed $primary-color;
    background: rgba($primary-color, .08);
  }

  &:last-child {
    margin: 0;
  }
  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 5px;

    &-title {
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
    }
    &-info {
      display: flex;
      align-items: center;

      .duration {
        display: inline-block;
        font-weight: normal;
        font-size: 13px;
        color: $text-color-light;
      }
      .price {
        display: inline-block;
        font-size: 16px;
        color: $text-color;
        margin-left: 10px;
      }
    }
  }
}

.be-card-appointment {
  padding: 12px;
  border-radius: 4px;
  border: 1px dashed $border-color;
  margin-bottom: 15px;

  &_active {
    border: 1px dashed $primary-color;
    background: rgba($primary-color, .08);
  }

  &__inner {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px dashed $border-color;

    &:last-child {
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  &__salon {
    font-size: 14px;
    line-height: 1.4;
    font-weight: normal;
    padding-bottom: 8px;
    .salon {
      color: $text-color;
      display: inline;
    }
    address {
      display: inline;
      color: $text-color-light;
    }
  }
  &__service {
    .date {
      font-size: 14px;
      color: $text-color-light;
      display: inline-block;
    }
    .price {
      font-size: 14px;
      display: inline-block;
      color: $text-color;
    }
    .title {
      font-size: 16px;
      font-weight: 500;
      color: $text-color;
      margin-bottom: 0;
    }
    .title-group {
      font-size: 12px;
      color: $text-color-light;
      display: inline-block;
    }
    .time {
      display: block;
      font-size: 15px;
      color: $text-color;
      font-weight: normal;
    }
    .status {
      font-size: 12px;
      color: $text-color-light;
      display: block;
      padding-top: 6px;
      font-weight: 600;
    }
  }
  &__control {
    padding-top: 12px;
  }
  &__payment {
    border-radius: $border-radius-primary;
    background: rgba($white-100, .6);
    padding: 8px;
    margin-top: 10px;
    font-size: 14px;

    &-title {
      font-weight: 500;
      margin-bottom: 7px;
      color: $text-color-dark;
    }

    .status {
      font-size: 12px;
      color: $text-color-light;
      display: block;
      font-weight: 600;
    }

    .price {
      font-size: 14px;
      display: inline-block;
      color: $text-color;
    }
  }
}

</style>
