<template>
  <div>
    <div v-if="slots.time.morning.length > 0 || slots.time.afternoon.length > 0 || slots.time.evening.length > 0">
      <div v-if="slots.time && slots.time.morning && slots.time.morning.length > 0" class="be-venue-slots__list">
        <h5 class="label">{{ $t('b2c_morning') }}</h5>
        <div class="row">
          <div
              v-for="item in slots.time.morning"
              :key="item.name"
              class="col-3">
                <span v-ripple @click="selectSlot(item.value)" class="be-venue-slots__list-item">
                  {{item.name}}
                </span>
          </div>
        </div>
      </div>
      <div v-if="slots.time && slots.time.afternoon && slots.time.afternoon.length > 0" class="be-venue-slots__list">
        <h5 class="label">{{ $t('b2c_afternoon') }}</h5>
        <div class="row">
          <div
              v-for="item in slots.time.afternoon"
              :key="item.name"
              class="col-3">
                <span v-ripple @click="selectSlot(item.value)" class="be-venue-slots__list-item">
                  {{item.name}}
                </span>
          </div>
        </div>
      </div>
      <div v-if="slots.time && slots.time.evening && slots.time.evening.length > 0" class="be-venue-slots__list">
        <h5 class="label">{{ $t('b2c_evening') }}</h5>
        <div class="row">
          <div
              v-for="item in slots.time.evening"
              :key="item.name"
              class="col-3">
                <span v-ripple @click="selectSlot(item.value)" class="be-venue-slots__list-item">
                  {{item.name}}
                </span>
          </div>
        </div>
      </div>
    </div>
    <be-placeholder-content
        v-else
        :icon="placeholder.icon"
        :title-dark="placeholder.title"
        :description="placeholder.description"
    >
      <be-link
          v-if="placeholder.isOtherStaff"
          variant="primary"
          class="mt-3"
          :to="`/venues/${$route.params.id}/staff`">
        {{ $t("b2c_try_with_other_staff") }}
      </be-link>
      <be-button
          v-if="placeholder.isNextDate"
          class="mt-3"
          variant="primary"
          @click="$emit('next', slots.nextAvailableDate)"
      >
        {{ placeholder.buttonText }}
      </be-button>
    </be-placeholder-content>
  </div>
</template>

<script>
import BePlaceholderContent from "@/components/common/BePlaceholderContent";
import Closed from "../../../../assets/img/icons/icon-closed.svg"
import Calendar from "../../../../assets/img/icons/icon-calendar-date.svg"
import BeLink from "@/components/common/BeLink";
import BeButton from "@/components/common/BeButton";
export default {
  name: "BeVenueSlots",
  components: {BeButton, BeLink, BePlaceholderContent},
  props: {
    slots: {
      type: Object
    },
    venueName: {
      type: String
    },
    hasEmployees: {
      type: Boolean
    }
  },
  data() {
    return {
      Closed,
      Calendar,
      placeholder: {
        icon: Calendar,
        title: '',
        description: '',
        isOtherStaff: false,
        isNextDate: false,
        isClosed: false,
        buttonText: ''
      }
    }
  },
  filters: {
    convertButtonDate(date, week, toMonth) {
      const weekday = week(
          Number(this.$moment(date).format("d")) === 0 ? 7 : this.$moment(date).format("d")
      );
      const day = this.$moment(date).format("DD");
      const month = toMonth(this.$moment(date).format("M"));

      return `${weekday} ${day} ${month}`;
    },
  },
  watch: {
    slots: {
      deep: true,
      handler: function(newSlots) {
        this.validateSlots(newSlots)
      }
    }
  },
  mounted() {
    this.validateSlots(this.slots)
  },
  methods: {
    validateSlots(slots) {
      if(slots.error.availableSlots === 'Not available slots') {
        this.placeholder.icon = this.Calendar
        this.placeholder.title = this.$t("b2c_no_slots_available")

        if(slots.isLoading) {
          this.placeholder.isNextDate = false
          this.placeholder.isOtherStaff = this.hasEmployees
          this.placeholder.description = `${this.$t("b2c_please_wait_searching_slots")} <span class="be-spinner-text spinner-border spinner-border-sm"></span>`
        } else {
          if(slots.error.nextAvailableSlots && slots.error.nextAvailableSlots === 'Not available slots') {
            this.placeholder.description = this.$t("b2c_unfortunately_no_free_time_until", {
              day: slots.nextMonthDay,
              month: slots.nextMonth
            })
            this.placeholder.isNextDate = false
            this.placeholder.isOtherStaff = this.hasEmployees
            this.placeholder.isClosed = false
          } else {
            this.placeholder.description = `${this.$t("b2c_but_you_can_book_for")} ${this.convertDescriptionDate(slots.nextAvailableDate)}`
            this.placeholder.buttonText = `${this.$t("b2c_go_to")} ${this.convertButtonDate(slots.nextAvailableDate)}`
            this.placeholder.isNextDate = true
            this.placeholder.isOtherStaff = false
            this.placeholder.isClosed = false
          }
        }
      } else if(slots.error.availableSlots === 'Non-working day') {
        this.placeholder.icon = this.Closed
        this.placeholder.title = `${this.venueName} <br> ${this.$t("b2c_is_closed_day")}`

        if(slots.isLoading) {
          this.placeholder.isNextDate = false
          this.placeholder.isOtherStaff = false
          this.placeholder.description = `${this.$t("b2c_please_wait_searching_slots")} <span class="be-spinner-text spinner-border spinner-border-sm"></span>`
        } else {
          if(slots.nextAvailableDate) {
            this.placeholder.description = `${this.$t("b2c_but_you_can_book_for")} ${this.convertDescriptionDate(slots.nextAvailableDate)}`
            this.placeholder.buttonText = `${this.$t("b2c_go_to")} ${this.convertButtonDate(slots.nextAvailableDate)}`
            this.placeholder.isOtherStaff = false
            this.placeholder.isNextDate = true
            this.placeholder.isClosed = false

          } else if(slots.error.nextAvailableSlots === 'Not available slots') {
            this.placeholder.description = this.$t("b2c_unfortunately_no_free_time_until", {
              day: slots.nextMonthDay,
              month: slots.nextMonth
            })
            this.placeholder.isOtherStaff = false
            this.placeholder.isClosed = false
            this.placeholder.isNextDate = false
          }
        }
      }
    },

    // Select Slot
    selectSlot(value) {
      this.$emit('select', {
        id: this.$route.params.id,
        date: this.slots.selectedDate,
        services: [...value]
      })
    },

    // Convert dates strings
    convertDescriptionDate(date) {
      const month = this.getShortMonth(this.$moment(date).format("M"))

      if (this.$moment().add(1, "days").format("YYYY-MM-DD") === date) {
        return `${this.$t("b2c_tomorrow")}, ${this.$moment(date).format("DD")} ${month}`;
      }
      return `${this.$moment(date).format("DD")} ${month}`;
    },
    convertButtonDate(date) {
      const weekday = this.getWeekday(
          Number(this.$moment(date).format("d")) === 0 ? 7 : this.$moment(date).format("d")
      );
      const day = this.$moment(date).format("DD");
      const month = this.getShortMonth(this.$moment(date).format("M"));

      return `${weekday} ${day} ${month}`;
    },

    // Return strings
    getWeekday(num) {
      return this.$t(`b2c_day${num}`);
    },
    getShortMonth(num) {
      return this.$t(`b2c_month_short${num}`);
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/global/variables";

.be-venue-slots {
  &__list {
    margin-bottom: 6px;
    .label {
      font-size: 13px;
      font-weight: normal;
      text-transform: uppercase;
      color: $text-color;
      margin-bottom: 8px;
    }
    .row {
      margin-left: -6px;
      margin-right: -6px;

      .col-3 {
        padding: 0 6px 12px;
      }
    }

    &-item {
      display: inline-block;
      width: 100%;
      text-align: center;
      cursor: pointer;
      border: 1px solid $text-color;
      padding: 5px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: normal;
      color: $text-color;
      transition: .2s;

      &:hover {
        background: $primary-color;
        border-color: $primary-color;
        color: $white;
        box-shadow: 0 3px 5px rgba($text-color, .3);
      }
    }
  }
}

</style>
