<template>
  <be-button @click="$router.push({name: 'Notifications'})" round flat :badge="hasNotifications" variant="secondary">
    <i class="icon-be-notification"></i>
  </be-button>
</template>

<script>
import BeButton from "@/components/common/BeButton.vue";

export default {
  name: "BeNotificationsWidget",
  components: {BeButton},
  computed: {
    hasNotifications() {
      return this.$store.getters.hasNotifications
    }
  }
}
</script>

<style scoped lang="scss">

</style>
