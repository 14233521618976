<template>
    <button v-ripple @click="$bvModal.show('language-modal')" class="be-btn-languages">
        <img :src="lang.icon" :alt="lang.title" class="be-btn-languages__icon">
        <span class="be-btn-languages__text">{{lang.title}}</span>
    </button>
</template>

<script>
import en from "../../assets/img/lang/en.svg"
import de from "../../assets/img/lang/de.svg"
import es from "../../assets/img/lang/es.svg"
import fr from "../../assets/img/lang/fr.svg"
import pl from "../../assets/img/lang/pl.svg"
import it from "../../assets/img/lang/it.svg"

const LANGUAGES = {
  'en': {
    icon: en,
    title: 'English'
  },
  'de': {
    icon: de,
    title: 'Deutsch'
  },
  'fr': {
    icon: fr,
    title: 'Français'
  },
  'pl': {
    icon: pl,
    title: 'Polski'
  },
  'it': {
    icon: it,
    title: 'Italiano'
  },
  'es': {
    icon: es,
    title: 'Español'
  }
}

export default {
  name: "BeLanguageButton",
  computed: {
    lang() {
      return LANGUAGES[this.$store.getters.appLanguage] ? LANGUAGES[this.$store.getters.appLanguage] : LANGUAGES['en']
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/global/variables";

.be-btn-languages {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  background: none;
  padding: 7px 15px;
  border: none;
  border-radius: 3px;
  transition: .2s;

  &:hover {
    background: #f1f1f1;
  }

  &__icon {
    width: 24px;
    height: 24px;
    border: 1px solid $border-color;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    object-fit: cover;
    margin-right: 16px;
  }
  &__text {
    font-size: 16px;
    color: $text-color;
    font-weight: normal;
  }
}

</style>
