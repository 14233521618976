<template>
  <form @submit.prevent="() => $emit('submit')">
    <be-input
        type="password"
        name="password"
        :label="$t('b2c_password')"
        :required="true"
        :value="data.form.password"
        @input="(value) => $emit('input-password', value)"
        :error="data.errors.password"
        @validate="() => $emit('validate-password')"
    />
    <be-input
        :type="isShowPassword ? 'text' : 'password'"
        name="new-password"
        :label="$t('b2c_new_password')"
        :required="true"
        :password-switcher="true"
        :value="data.form.newPassword"
        @input="(value) => $emit('input-new-password', value)"
        :error="data.errors.newPassword"
        @switch="() => isShowPassword = !isShowPassword"
        @validate="() => $emit('validate-new-password')"
    />
    <be-input
        :type="isShowPassword ? 'text' : 'password'"
        name="confirm-password"
        :label="$t('b2c_confirm_password')"
        :required="true"
        :value="data.form.confirmPassword"
        @input="(value) => $emit('input-confirm-password', value)"
        :error="data.errors.confirmPassword"
        @validate="() => $emit('validate-confirm-password')"
    />

    <div class="be-form-group-btn pt-2">
      <be-button
          :type="'submit'"
          :is-full="true"
          :is-outline="true"
          variant="primary"
          :is-loading="data.isLoading"
      >
        {{$t('b2c_send_instructions')}}
      </be-button>
    </div>
  </form>
</template>

<script>

import BeInput from "@/components/common/BeInput";
import BeButton from "@/components/common/BeButton";

export default {
  name: "ProfileSettingsFormPassword",
  components: {BeInput, BeButton},
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      isShowPassword: false,
    }
  }
}
</script>

<style scoped>

</style>
